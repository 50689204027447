import { Fragment, useEffect, useState } from 'react';
import { getAllCities, getAllCountries, getStatesByCountryId, updateCity } from '../../../services/getLocationList';
import useLoader from '../../../utils/useLoader';
import { validateLocationData } from '../../../utils/utils';
import Button from '../../atoms/buttons/button';
import MainSelect from '../../atoms/main-select/MainSelect';
import Select from '../../atoms/select/select';
import Text from '../../atoms/text/text';
import { Alert } from '../../molecules/alert/alert';
import InputSearchSet from '../../molecules/input-search-set/InputSearchSet';
import TablePagination from '../../molecules/table-pagination/TablePagination';
import Table from '../../molecules/table/Table';
import './manageCities.scss';
import { useTranslation } from "react-i18next";


function ManageCities({props}) {
    let keyword, pageNo, pageSize, sortBy, ascSort;
    
    const [loader, showLoader, hideLoader] = useLoader();

    const [tableData, setTableData] = useState([]);

    const [searchItem, setSearchItem] = useState("");
    const [requestsPerPage, setRequestsPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [edit, setEdit] = useState(false);
    const [paises, setPaises] = useState([]);
    const [estados, setEstados] = useState([]);

    const [orderBy, setOrderBy] = useState({
        nameColumn: "nombre",
        orderAsc: true
    });

    const selectValues = [
        {value: 10, label: "10"},
        {value: 20, label: "20"},
        {value: 50, label: "50"}
    ];

    const initialData = {
        paisId: "",
        estadoId: "",
        ciudad: ""
    }

    const [t] = useTranslation("global");
    const [selected, setSelected] = useState(initialData);

    const headingColumns = [
        {key: 'pais', val: t('administrator.manageCities.country'), width: 3},
        {key: 'departamento', val: t('administrator.manageCities.state'), width: 4},
        {key: 'nombre', val: t('administrator.manageCities.city'), width: 3},
    ];

    const validate = async () => {
        // ENVIAR DATOS AL BACK
        const dataSend = {
            id: selected.ciudadId ? selected.ciudadId : 0,
            name: selected.ciudad,
            state_id: parseInt(selected.estadoId)
        }
        try {
            validateLocationData('ciudad', selected, () => {
                showLoader();
                updateCity(dataSend).then(response => {
                    hideLoader();
                    switch (response.data.status) {
                        case 200:
                            Alert("success", t('alerts.success'), response.data.userMessage);
                            setSelected(initialData);
                            setEdit(false);
                            reloadTable();
                            break;
                        default:
                            Alert("error", t('alerts.oops'), response.data.userMessage);
                            break;
                    }
                }).catch(err => {
                    hideLoader();
                    throw ({ type: "error", msg: t('alerts.oopsWrong'), details: "" });
                })
            })
        } catch (e) {
            Alert(e.type, e.msg, e.details);
            return false;
          }
    }

    const handleOnChangeSearch = (event) => {
        setSearchItem(event.target.value);
    }

    const handleOnclickDataHeading = (nameTableColumn) => {
        let orderByVal = nameTableColumn === orderBy.nameColumn ? !orderBy.orderAsc : true;
        setOrderBy({
            nameColumn: nameTableColumn,
            orderAsc: orderByVal
        });
    }

    const handleOnItemClickListener = async (row) => {
        setEdit(true);

        const pais = paises.filter(country => row.pais === country.name);
        
        showLoader();
        await getStatesByCountryId(pais[0].id).then(res => {
            const estado = res.filter(state => row.departamento === state.name);
            setSelected({
                ciudadId: row.ciudadId,
                paisId: pais[0]?.id,
                estadoId: estado[0]?.id,
                ciudad: row.nombre
            })
            hideLoader();
        });
        window.scrollTo(0 , 0);
    }

    const handleOnchange = (requestPerPage) => {
        setRequestsPerPage(requestPerPage.target.value);
        setCurrentPage(1);
    }

    const handleOnclick = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) setCurrentPage(newPage);
    }

    const reloadTable = () => {
        const filterData = `?keyword=${searchItem}&pageNo=${currentPage - 1}&pageSize=${requestsPerPage}&sortBy=${orderBy.nameColumn}&ascSort=${orderBy.orderAsc}`;
        showLoader();
        getAllCities(filterData).then(response => {
            setTotalPages(response.totalPages);
            setTableData(response.resultList.slice());
            hideLoader();
        }).catch(error => {
            hideLoader();
            setTableData([]);
            Alert("error", t('alerts.oops'), error);
        });
    }

    const separatePastParams = (url) => {
        const search = url.split("=");
        keyword = (search[1].split("&"))[0];
        pageNo = (search[2].split("&"))[0];
        pageSize = (search[3].split("&"))[0];
        sortBy = (search[4].split("&"))[0];
        ascSort = search[5];
        setRequestsPerPage(pageSize);
        setSearchItem(keyword);
        setCurrentPage(parseInt(pageNo));
        setOrderBy({
            nameColumn: sortBy.toString(),
            orderAsc: ascSort
        });
    }

    useEffect(() => {
        showLoader();
        const dataSend = `?keyword=${searchItem}&pageNo=${currentPage - 1}&pageSize=${requestsPerPage}&sortBy=${orderBy.nameColumn}&ascSort=${orderBy.orderAsc}`;
        if (window.location.search=="") {
            getAllCities(dataSend).then(response => {
                setTotalPages(response.totalPages);
                setTableData(response.resultList.slice());
                hideLoader();
            }).catch(error => {
                hideLoader();
                setTableData([]);
                Alert("error", t('alerts.oops'), error);
            })
        } else {
            separatePastParams(window.location.search);
        }
    }, [])

    useEffect(() => {
        reloadTable();
    }, [currentPage, requestsPerPage, orderBy, searchItem])
    

    const actualizarData = (fragment, event) => {
        if (fragment === 'paisId') {
            setSelected({...selected, [fragment]: event.target.value, estadoId: ''})
        } else {
            setSelected({...selected, [fragment]: event.target.value})
        }
    }

    const limpiar = () => {
        setEdit(false);
        setSelected(initialData);
        setEstados([]);
    }

    const obtenerEstados = (countryCode) => {
        showLoader();
        getStatesByCountryId(countryCode).then(res => {
            setEstados(res);
            hideLoader();
        })
    }

    const actualizarLocation = (location, event) => {
        if (location === "pais") {
            if (event.target.value !== 0 || event.target.value !== '0') {
                const aux = paises.filter(row => {
                    return row.id === parseInt(event.target.value);
                })[0];
                actualizarData('paisId', event); 
                obtenerEstados(aux.id);
            }
        }
    }

    useEffect(() => {
        getAllCountries().then(res => {
            setPaises(res);
        }).catch(error => {
            console.log("Error", error);
        })
    }, []);

    useEffect(() => {
        if (selected.paisId !== "") {
            obtenerEstados(
                paises.find((pais) => pais.id === parseInt(selected.paisId))?.id
            );
        }
    }, [paises, selected.paisId])

    return (
        <Fragment>
            <InputSearchSet 
                type="text"
                placeholder={t('transversal.search')}
                value={searchItem}
                onChange={handleOnChangeSearch}
            />
            <div className="form__content edit__location">
                <div className="information">
                    <div>
                        <h3>{`${edit ? t('transversal.edit') :  t('transversal.create') } ${t('administrator.manageCities.city')}`}</h3>
                    </div>
                    <hr/>
                    <div className="form__input">
                        <Select 
                            onChange={event => actualizarLocation('pais', event)}
                            className="input__select"
                            label={t('administrator.manageCities.country')}
                            data={paises}
                            name="pais"
                            value={selected.paisId}
                            placeholder={t('transversal.selectOption')}
                        />
                        <Select 
                            onChange={event => actualizarData('estadoId', event)}
                            className="input__select"
                            label={t('administrator.manageCities.state')}
                            data={estados}
                            name="estado"
                            value={selected.estadoId}
                            disabled={selected.paisId ? false : true}
                            placeholder={t('transversal.selectOption')}
                        />
                        <Text 
                            className="input__edit"
                            onChange={event => actualizarData('ciudad', event)}
                            value={selected.ciudad}
                            placeholder={t('administrator.manageCities.city')}
                            type="text"
                            disabled={selected.estadoId ? false : true}
                            name="ciudad"
                        />
                    </div>
                    <div className="form__button">
                        <Button name={t('transversal.clean')} onClick={limpiar} />
                        <Button primary name={edit ? t('transversal.edit') : t('transversal.create')} onClick={validate} />
                    </div>
                </div>
            </div>
            <Table 
                tableData={tableData}
                headingColums={headingColumns}
                title={null}
                breakOn='medium'
                orderBy={handleOnclickDataHeading}
                orderColumnName={orderBy.nameColumn}
                orderColumnDirection={orderBy.orderAsc}
                onItemClickListener={handleOnItemClickListener}
            />
            <div className="o-footer__table">
                <MainSelect 
                    options={selectValues}
                    name={`invoicetable`}
                    value={requestsPerPage}
                    onChange={handleOnchange}
                />
                <TablePagination 
                    paginate={(event, newPage) => handleOnclick(newPage)}
                    pageAmount={totalPages}
                    currentPage={currentPage}
                />
            </div>
            {loader}
        </Fragment>
    )
}

export default ManageCities;