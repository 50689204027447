import { React, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CreateBrandForm from "../../organism/create-brand-form/createBrandForm";
import useLoader from '../../../utils/useLoader';
import Aside from "../../templates/aside/aside";
import { BrandContext } from "../../../provider/brand";
import Wrapper from "../../templates/wrapper/Wrapper";
import HeaderInvoiceRequest from "../../organism/header-invoice-request/HeaderInvoiceRequest";
import RoundButton from "../../atoms/round-button/roundButton";
import Back from "../../../images/ico-back.svg";
import "./createBrand.scss";
import { useTranslation } from "react-i18next";

const CreateBrand = (props) => {
  const params = useParams();
  const [t] = useTranslation("global");
  const [loader, showLoader, hideLoader] = useLoader();
  const { dataBrand, setDataBrand } = useContext(BrandContext);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (params.id) {
      setIsEditing(true);
      //getData();
    } else {
      setIsEditing(false);
      setDataBrand({
        type: 'RESET',
        payload: 'Voy resetear la marca desde la página crear marca'
      });
    }
    return () => { };
  }, [params.id]);

  /*
  const getData = async () => {
    const data = { clienteId: params.id };
    showLoader();
    const user = await getClientByID(data);
    hideLoader();
    setDataClient({
      type: 'AGREGAR',
      payload: {...user}
    });
  };
  */
  const TITULO_CREAR_MARCA = t('administrator.createBrand.title');
  const TITULO_EDITAR_MARCA = t('administrator.createBrand.edit');
  const handleBack = () => {
    window.history.back();
  }
  const [blur, setBlur] = useState(false);
  return (
    <Wrapper aside={<Aside blur={blur} setBlur={setBlur} />} blur={blur} >
      <div className="BrandHeader">
        <HeaderInvoiceRequest title={isEditing ? TITULO_EDITAR_MARCA : TITULO_CREAR_MARCA} />
        <div className="BrandHeaderButtonContainer">
          <RoundButton icon={Back} onClick={handleBack} />
        </div>
      </div>

      <CreateBrandForm />
      {loader}
    </Wrapper>
  );
}

export default CreateBrand;