import { useState, useContext } from 'react';

import Images from '../../atoms/images/images';
import FooterAside from '../../molecules/footer_aside/footer_aside';

import numero from '../../../images/logoSeiko2.svg';

import NavBar from '../../../images/menu.svg';
import Close from '../../../images/ico-close.svg';

import SubMenu from '../../organism/submenu/submenu';
import { AppContextUser } from '../../../provider/userInSession';


import './aside.scss';
import ExpandMore from '../../../images/expand-more.svg'
import ExpandLess from '../../../images/expand-less.svg'
import { useHistory } from 'react-router';
import { useTranslation } from "react-i18next";


const Aside = ({blur, setBlur}) => {

    const [display, setDisplay] = useState(false);
    const [t] = useTranslation("global");
    const SidebarData = [
        {
          title: t('administrator.title'),    
          path: '#',
          iconClosed: ExpandMore,
          iconOpened: ExpandLess,
          user: 'Administrador',      
          subNav: [
            {
              title: t('administrator.brands.title'),
              path: '/marcas',        
            },
            {
              title: t('administrator.createBrand.title'),
              path: '/marcas/crear',        
            },
            {
              title: t('administrator.users.title'),
              path: '/usuarios',        
            },
            {
              title: t('administrator.manageCities.title'),
              path: '/ciudades',        
            },
            {
              title: t('administrator.manageStates.title'),
              path: '/estados',        
            },
            {
              title: t('administrator.manageCountries.title'),
              path: '/paises',        
            },
          ]
        },
        {
          title: t('customers.customers.title'),    
          path: '#',
          iconClosed: ExpandMore,
          iconOpened: ExpandLess,
          user: '',
          subNav: [
            {
              title: t('customers.customers.title'),
              path: '/clientes',        
            },
            {
              title: t('customers.createCustomer.title'),
              path: '/cliente',        
            }
          ]
        },
        {
          title: t('invoices.title'),
          path: '#',    
          iconClosed: ExpandMore,
          iconOpened: ExpandLess,
          user: '',
          subNav: [
            {
              title: t('invoices.createInvoice.title'),
              path: '/factura',        
            },
            {
              title: t('invoices.pendingInvoice.title'),
              path: '/facturas-pendientes',        
            },
            {
              title: t('invoices.historicInvoice.title'),
              path: '/historico-de-facturas',        
            }
          ]
        },
        {
          title: t('policy.title'),    
          path: '#',
          iconClosed: ExpandMore,
          iconOpened: ExpandLess,
          user: '',
          subNav: [
            {
              title: t('policy.requestPolicy.title'),
              path: '/poliza',        
            },
            {
              title: t('policy.pendingPolicy.title'),
              path: '/polizas-pendientes',        
            },
            {
              title: t('policy.historicPolicy.title'),
              path: '/historico-de-polizas',        
            }
          ]
        }
      ];
    const handleTogle = () => {
        setDisplay(!display);
        setBlur(!blur)
    };
    const { dataUser } = useContext(AppContextUser);
    const [activeLink, setActiveLink] = useState('');
    const history = useHistory();

    const goToHome = () => {
        history.push("/");
    }

    return (
        <div className="aside">
            <div onClick={handleTogle}  className={`content-button ${display ? 'content-button-open' : ''}`}>
                <button>
                    <Images Imagen={display ? Close : NavBar} />
                </button>
            </div>
            <nav>
                <div className="content-logo-aside">
                    <div className="content-image">
                        <a onClick={goToHome}><Images Imagen={numero}/></a>
                    </div>
                </div>    
                <div className="items">
                    {SidebarData.map((item, index) => {
                        if(item.user===dataUser.rol || item.user===''){
                            return <SubMenu item={item} key={index} activeLink={activeLink} setActiveLink={setActiveLink} />;
                        }else{ return null}
                    })}            
                </div>

                <div className="content-footer-aside">
                    <FooterAside />
                </div>
            </nav>
        </div>
    );
}
export default Aside;
