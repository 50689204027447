import React from 'react';
import Proptypes from "prop-types";
import './MainSelect.scss';
import { useTranslation } from "react-i18next";

const MainSelect = ({options, onChange, ...props}) => {
    const [t] = useTranslation("global");
    return (
        <div className={"mainSelectContainer"}>
            <span>{t('components.table.show')}</span>
            <div className={"selectPaginationContainer"}>
                <select
                    className="select-pagination"
                    value={props.value}
                    onChange={onChange}
                    name={props.name}
                    disabled={props.disabled ? props.disabled : false}
                    required={props.required ? props.required : false}>
                    {options.map((option, index) => (
                        <option key={index} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
            <span>{t('components.table.resultsPerPage')}</span>
        </div>
    );
}

MainSelect.propTypes = {
    label: Proptypes.string,
    name: Proptypes.string,
    onChange: Proptypes.func,
};

export default MainSelect;
