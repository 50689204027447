import { API_BASE_URL, API_PARAMETERS_CONTEXT } from "../utils/constans";
import clienteAxios from "../api/axios";

const getUserById = async (userId) => {
  let user;
  let url = `${API_BASE_URL}${API_PARAMETERS_CONTEXT}usuarios/${userId}`
  await clienteAxios(url, null, null, "GET")
    .then((response) => {
      user = response.data.datas;
    })
    .catch((err) => {
      console.log(err);
    });
  return user;
};

const updateUser = async (data) => {
  return await clienteAxios(
    `${API_BASE_URL}${API_PARAMETERS_CONTEXT}usuarios/update`,
    data,
    null,
    "POST"
  );
};

const getUsers = async (data) => {
  let result = [];
  let url = `${API_BASE_URL}${API_PARAMETERS_CONTEXT}usuarios/all`;
  await clienteAxios(url, data, null, "POST")
    .then((respuesta) => {
      result = respuesta.data.datas.data;
      savePaginationTokenUsers(respuesta.data.datas.paginationToken)
    })
    .catch((err) => console.log(err));
  return result;
};

const savePaginationTokenUsers = (paginationToken) => {
  localStorage.setItem(
    "paginationTokenUsers",
    paginationToken
  );
}

const getPaginationTokenUsers = () => {
  return localStorage.getItem("paginationTokenUsers");
}

const removePaginationTokenUsers = () => {
  localStorage.removeItem("paginationTokenUsers");
}

export const getSubsidiaryByUser = async (userId) => {
  let filiales;
  const url = `${API_BASE_URL}${API_PARAMETERS_CONTEXT}usuarios/filiales-by-user/${userId}`
  await clienteAxios(url, null, null, "GET")
    .then((response) => {
      filiales = response.data;
    })
    .catch((err) => {
      console.log(err);
    });

  return filiales;
}

export { getUsers, updateUser, getUserById, getPaginationTokenUsers, removePaginationTokenUsers };
