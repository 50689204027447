import Button from '../../atoms/buttons/button';
import { useEffect, useState, useContext } from "react";
import NotificationsIcon from '../../../images/notifications.svg';
import Close from '../../../images/ico-close-white.svg';
import Novelty from '../../molecules/novelty/novelty';
import { AppContextUser } from "../../../provider/userInSession";
import './notifications.scss';
import { useCookies } from 'react-cookie';
import { getNovedades } from '../../../services/parametersService';
import { useTranslation } from "react-i18next"

const Notifications = ({display, setDisplay, ...otherProps}) => {
    const { dataUser } = useContext(AppContextUser);
    const [cookies, setCookie] = useCookies(['novedad']);
    const [novedades, setNovedades] = useState();
    const [creacion, setCreacion] = useState("");

    let hoy = new Date();
    let limitDate;

    async function updateNovedades() {
        await getNovedades().then(response => {
            setNovedades(response.datas);
        }).catch(error => {
            setNovedades([]);
        })
    }

    useEffect(() => {
        
        if(dataUser?.userName){
            updateNovedades()
        }
        
        // if (!novedades) {
            // updateNovedades()
        // }

        if (!cookies.novedad) {
            setCookie('novedad', { value: true, fecha: creacion }, { path: '/' });
        }

        // if(cookies.novedad && cookies.novedad.value) {
        //     setDisplay(true);
        // }
    }, []);
    
    useEffect(() => {
        if (novedades && novedades[0]) {
            setCreacion(new Date(novedades[0].fechaCreacion));
            limitDate = new Date(novedades[0].fechaCreacion);
            limitDate.setDate(limitDate.getDate() + 15);
        }

        if(limitDate < hoy){
            setCookie('novedad', { value: true, fecha: cookies.novedad.fecha }, { path: '/' });
        }

    }, [novedades])
    
    const handleToggle = () => {
        if(cookies.novedad.value){
            setCookie('novedad', { value: false, fecha: cookies.novedad.fecha }, { path: '/' });
        }
        setDisplay(!display);
    }

    const [t] = useTranslation("global");

    return (
        <div className="notifications">
            <div className={display? 'showNotifications': ''}>
                <Button onClick={handleToggle} 
                        icon={NotificationsIcon} 
                        name={t('notifications.title')}
                        borderless 
                        reverseIcon
                        noHover
                />
            </div>
            <aside className="notificationsAside">
                <div className="closeNotifications" onClick={handleToggle}>
                    <Button borderless noHover icon={Close} name={t('transversal.close')} onClick={handleToggle}/>
                </div>
                <div className="notificationsContent">
                    {novedades ? novedades.map((novedad) => {
                        return <Novelty data={novedad}/>
                    }) : null}
                </div>
            </aside> 
        </div>
    )
}

export default Notifications;