import { Fragment, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { BrandContext } from "../../../provider/brand";
import { ClientContext } from "../../../provider/cliente";
import { getClientByName } from "../../../services/clientService";
import {
  getBrandFullinformation,
  saveNewBrand,
} from "../../../services/listarMarcasService";
import { getFiliales } from "../../../services/parametersService";
import useLoader from "../../../utils/useLoader";
import { validateBrandField } from "../../../utils/utils";
import Button from "../../atoms/buttons/button";
import CheckboxSwitch from "../../atoms/checkbox-switch/CheckboxSwitch";
import Text from "../../atoms/text/text";
import { Alert } from "../../molecules/alert/alert";
import "../../templates/informacion/generalInformation.scss";
import FinderSelector from "../finder-selector/finderSelector";
import "./brandForm.scss";
import { useTranslation } from "react-i18next";

const BrandForm = ({ isFromClient }) => {
  const [t] = useTranslation("global");
  let timer;
  const { dataBrand, setDataBrand } = useContext(BrandContext);
  const { dataClient, setDataClient } = useContext(ClientContext);
  const [dataListClient, setDataListClient] = useState([]);
  const [dataFiliales, setDataFiliales] = useState([]);
  const [dataButtons, setDataButtons] = useState([]);
  const [dataButtonsFiliales, setDataButtonsFiliales] = useState([]);
  const [loader, showLoader, hideLoader] = useLoader();
  const idBrandParam = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const history = useHistory();
  

  useEffect(() => {
    let dataToAsign;
    async function findByid(id) {
      showLoader();
      await getBrandFullinformation(id)
        .then((response) => {
          hideLoader();
          dataToAsign = response;
          setDataButtons(dataToAsign.clients);
          setDataButtonsFiliales(dataToAsign.filiales);
          setDataBrand({
            type: "AGREGAR",
            payload: dataToAsign,
          });
        })
        .catch((error) => {
          console.log(error);
          hideLoader();
        });
    }
    if (idBrandParam.id != null && !isFromClient) {
      setIsEditing(true);
      findByid(idBrandParam.id);
    }
  }, []);

  useEffect(() => {
    async function queryFiliales() {
      await getFiliales()
        .then((response) => {
          setDataFiliales(response);
        })
        .catch((error) => {
          setDataFiliales([]);
        });
    }
    queryFiliales();

    // setDataClient({
    //   type: "RESET",
    //   payload: "Voy resetear la info de cliente",
    // });
  }, []);

  const actualizarDatos = (e) => {
    //console.log("estoy actualizando ", dataClient);
    setDataClient({
      type: "AGREGAR",
      payload: { ...dataClient, [e.target.name]: e.target.value },
    });
  };

  const resetDataClient = () => {
    setDataClient({
      type: "RESET",
    });
  };

  const getClienteByName = (e) => {
    if (dataClient.clienteId && e.type === "change") {
      resetDataClient();
    } else {
      clearTimeout(timer);
      actualizarDatos(e);
      timer = setTimeout(async () => {
        if (dataClient.razonSocialRut.length > 2) {
          const responseData = await getClientByName(dataClient);
          setDataListClient(responseData);
          if (responseData?.length === 1) {
            var existButton = dataButtons.find(
              (db) => db.itemId === responseData[0].clienteId
            );
            if (!existButton) {
              dataButtons.push({
                itemId: responseData[0].clienteId,
                itemName: responseData[0].razonSocialRut,
              });
              setDataClient({
                type: "AGREGAR",
                payload: responseData[0],
              });
              actualizarArreglos("clients", dataButtons);
            }
            resetDataClient();
          }
        }
      }, 500);
    }
  };

  const removeItemPill = (itemId) => {
    var newDataButtons = dataButtons.filter((x) => {
      return x.itemId != itemId;
    });
    setDataButtons(newDataButtons);
    actualizarArreglos("clients", newDataButtons);
  };

  const removeItemPillFilial = (itemId) => {
    var newDataButtons = dataButtonsFiliales.filter((x) => {
      return x.itemId != itemId;
    });
    setDataButtonsFiliales(newDataButtons);
    actualizarArreglos("filiales", newDataButtons);
  };

  const handleOnchange = (e) => {
    setDataBrand({
      type: "AGREGAR",
      payload: {
        ...dataBrand,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      },
    });
  };

  const getFilialName = (id) => {
    switch (id) {
      case "1": return "Pragma - Colombia";
      case "2": return "Pirani - Panamá";
      case "3": return "Pirani - Colombia";
      default: break;
    }
  };

  const handleOnChangeFilial = (event) => {
    let filialesBrand = [...dataButtonsFiliales];
    if (
      !dataButtonsFiliales.find((item) => item.itemId == event.target.value)
    ) {
      filialesBrand.push({
        itemId: event.target.value,
        itemName: getFilialName(event.target.value),
      });
      setDataButtonsFiliales([
        ...dataButtonsFiliales,
        {
          itemId: event.target.value,
          itemName: getFilialName(event.target.value),
        },
      ]);
    }
    actualizarArreglos("filiales", filialesBrand);
  };

  const actualizarArreglos = (name, array) => {
    setDataBrand({
      type: "AGREGAR",
      payload: { ...dataBrand, [name]: array },
    });
  };

  const handleSubmit = () => {
    try {
      validateBrandField(dataBrand, () => {
        showLoader();
        saveNewBrand(dataBrand)
          .then((response) => {
            hideLoader();
            switch (response.data.status) {
              case 200:
                history.push("/marcas");
                Alert("success", t('alerts.success'), response.data.userMessage);
                break;
              default:
                Alert("error", t('alerts.oops'), response.data.userMessage);
                break;
            }
          })
          .catch((err) => {
            hideLoader();
            Alert("error", t('alerts.oops'), err.message);
          });
        hideLoader();
      });
    } catch (e) {
      Alert(e.type, e.msg, e.details);
    }
  };

  return (
    <Fragment>
      <div className="form__content">
        <div className="HeaderBody">
          {isEditing ? <h3>{t('administrator.createBrand.editing')}</h3> : <h3>{t('administrator.createBrand.title')}</h3>}
        </div>
        <hr />
        <div className="form__content__formBody">
          <div className="">
            <Text
              type="text"
              name="name"
              placeholder={t('administrator.createBrand.name')}
              required
              readOnly=""
              value={dataBrand.name}
              onChange={handleOnchange}
            />
          </div>
          <div className="">
            {!isFromClient && (
              <Text
                Type="number"
                name="costCenter"
                placeholder={t('administrator.createBrand.costCenter')}
                required
                readOnly=""
                value={dataBrand.costCenter}
                onChange={handleOnchange}
                minLength={3}
              />
            )}
          </div>
          <div className="form__content__formBody__formChecked">
            {!isFromClient && (
              <CheckboxSwitch
                checked={dataBrand.active}
                onChange={handleOnchange}
                name="active"
                disabledText={t('transversal.inactive')}
                enabledText={t('transversal.active')}
              />
            )}
          </div>
        </div>
        <div className="form__content__formBody__client">
          {!isFromClient && (
            <FinderSelector
              title={t('administrator.createBrand.customers')}
              placeholder={t('administrator.createBrand.selectCustomer')}
              dataSelect={dataListClient}
              searchName={"razonSocialRut"}
              onChange={getClienteByName}
              onKeyUp={(actualizarDatos, getClienteByName)}
              dataButtons={dataButtons}
              removeItemPill={removeItemPill}
              value={dataClient.razonSocialRut}
            />
          )}
          <FinderSelector
            title={t('administrator.createBrand.subsidiary')}
            placeholder={t('transversal.selectOption')}
            dataSelect={dataFiliales}
            searchName={"filiales"}
            onChange={(e) => handleOnChangeFilial(e)}
            dataButtons={dataButtonsFiliales}
            removeItemPill={removeItemPillFilial}
            labelSelect={t('administrator.createBrand.subsidiary')}
            isSelect
          />
        </div>
      </div>
      <div className="form__content__formButton">
        <Button onClick={handleSubmit} primary>
        {t('administrator.createBrand.saveBrand')}
        </Button>
      </div>
      {loader}
    </Fragment>
  );
};

export default BrandForm;
