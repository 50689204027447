import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import useLoader from "../../../utils/useLoader";
import {
  getUsers,
  getPaginationTokenUsers,
  removePaginationTokenUsers,
} from "../../../services/userService";
import { Fragment } from "react";
import Toolkit from "../../atoms/toolkit/Toolkit";
import InputSearchSet from "../../molecules/input-search-set/InputSearchSet";
import MainSelect from "../../atoms/main-select/MainSelect";
import { Alert } from "../../molecules/alert/alert";
import "./usersTable.scss";
import TableInfinite from "../../organism/table-infinite/table-infinite";
import { useTranslation } from "react-i18next";

function UsersTable() {

  const [t] = useTranslation("global");
  const history = useHistory();
  const [loader, showLoader, hideLoader] = useLoader();
  const [dataUsers, setDataUsers] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [requestsPerPage, setRequestsPerPage] = useState(20);

  const headingUsersColumns = [
    { key: "nombre", val: t('administrator.users.name'), width: 3 },
    { key: "apellido", val: t('administrator.users.lastname'), width: 2 },
    { key: "email", val: t('administrator.users.email'), width: 2 },
  ];

  const selectValues = [
    { value: 20, label: "20" },
    { value: 50, label: "50" },
  ];

  const handleOnChangeSearch = (event) => {
    setDataUsers([]);
    setSearchItem(event.target.value);
  };

  const handleOnItemClickListener = (row) => {
    history.push("/usuario/" + row.userId);
  };

  const handleOnRequestPerPage = (requestPerPage) => {
    setRequestsPerPage(requestPerPage.target.value);
  };

  const reloadTable = () => {
    const filterData = {
      pageSize: requestsPerPage,
      paginationToken: getPaginationTokenUsers()
        ? getPaginationTokenUsers()
        : null,
      userEmail: searchItem,
    };
    showLoader();
    getUsers(filterData)
      .then((response) => {
        dataUsers.push(...response);
        setDataUsers(dataUsers);
        hideLoader();
      })
      .catch((error) => {
        hideLoader();
        setDataUsers([]);
        Alert("error", t('alerts.oops'), error);
      });
  };

  useEffect(() => {
    removePaginationTokenUsers();
    reloadTable();
  }, [requestsPerPage, searchItem]);

  return (
    <Fragment>
      <Toolkit>
        <InputSearchSet
          type="text"
          placeholder={t('administrator.users.searchEmail')}
          value={searchItem}
          onChange={handleOnChangeSearch}
        />
      </Toolkit>

      <TableInfinite
        tableData={dataUsers}
        headingColums={headingUsersColumns}
        breakOn='medium'
        onItemClickListener={handleOnItemClickListener}
        reloadTable={reloadTable}
      />

      <div className="o-footer__table">
        <MainSelect
          options={selectValues}
          name={`invoicetable`}
          value={requestsPerPage}
          onChange={handleOnRequestPerPage}
        />
      </div>
      {loader}
    </Fragment>
  );
}

export default UsersTable;
