import React, { Fragment, useState, useContext, useEffect } from "react";
import Loader from "../../atoms/loader/loader";
import BrandForm from "../../molecules/brand-form/brandForm";
import "../../templates/informacion/generalInformation.scss";
import "./createBrandForm.scss"

const CreateBrandForm = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const handleSubmit = (data) => {

    }

    useEffect(() => {

    })


    return( 
        <div > 
            <Loader isLoading={isLoading}></Loader>
            <form>
                <BrandForm />
            </form> 
        </div>
    );
}

export default CreateBrandForm;