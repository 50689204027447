import React from "react";
import "./attach-icon.scss";

function AttachIcon({ classColor = "attach-icon-enable", ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <rect className="attach-icon-rect" width="25" height="25" />
      <path
        className={classColor}
        d="M11.847,11.5V2.313A1.313,1.313,0,0,0,10.534,1H6.753a1.969,1.969,0,0,0-1.392.578L2.578,4.361A1.969,1.969,0,0,0,2,5.753V11.5a1.313,1.313,0,0,0,1.313,1.313h7.221A1.313,1.313,0,0,0,11.847,11.5ZM3.313,6.251H6.6A.656.656,0,0,0,7.251,5.6V2.313h3.282V11.5H3.313Zm9.847-2.626v9.19a1.313,1.313,0,0,1-1.313,1.313H4.626a1.313,1.313,0,0,0,1.313,1.313h5.908a2.626,2.626,0,0,0,2.626-2.626V4.939A1.313,1.313,0,0,0,13.159,3.626Z"
        transform="translate(4.363 4.578)"
      />
    </svg>
  );
}

export default AttachIcon;
