import React, { useState } from 'react';
import HeaderInvoiceRequest from '../../organism/header-invoice-request/HeaderInvoiceRequest';
import ManageStates from '../../organism/manage-states/manageStates';
import Aside from '../../templates/aside/aside';
import Wrapper from '../../templates/wrapper/Wrapper';
import { useTranslation } from "react-i18next";

function States({props}) {
    const [t] = useTranslation("global");
    const [blur, setBlur] = useState(false);
    const title = t('administrator.manageStates.title');

    return (
        <Wrapper aside={ <Aside blur={blur} setBlur={setBlur} /> } blur={blur} >
            <HeaderInvoiceRequest title={title} />
            <ManageStates />
        </Wrapper>
    )
}

export default States;