import React, { useEffect } from "react";
import Select from "../../atoms/select/select";
import "./approveClient.scss";
import Text from "../../atoms/text/text";
import { ClientContext } from "../../../provider/cliente";
import getEconomyActivities from "../../../services/getEconomyActivities";
import getHeadquarters from '../../../services/getHeadquarters';
import CheckboxSwitch from "../../atoms/checkbox-switch/CheckboxSwitch";
import TextSearchBlockEconomicActivity from "../../molecules/text-search-block-economic-activity/textSearchBlockEconomicActivity";
import { useTranslation } from "react-i18next";

let timer;

function ApproveClient(params) {
  const { dataClient, setDataClient } = React.useContext(ClientContext);
  const [economicActivities, setEconomicActivities] = React.useState([]);
  const [actividades, setActividades] = React.useState([]);
  let [headquarters, setHeadquarters] = React.useState([]);
  const [t] = useTranslation("global");

  function updateData(e) {
    setDataClient({
      type: 'AGREGAR',
      payload: { ...dataClient, [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value }
    });
  }

  const consultaActividadEconomica = (event) => {
    updateData(event);
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (dataClient.actividadEconomicaId.length > 2) {
        let filter;
        if (!isNaN(event.target.value)) {
          filter = economicActivities.filter(activity => {
            return activity.name.includes(event.target.value);
          })
        } else {
          const value = event.target.value.toLowerCase();
          filter = economicActivities.filter(activity => {
            return activity.name.toLowerCase().includes(value);
          })
        }
        setActividades(filter);
        if (filter?.length === 1) {
          setDataClient({
            type: 'AGREGAR',
            payload: { ...dataClient, actividadEconomicaId: parseInt(filter[0].code) }
          })
        }
      }

    }, 500)
  }

  const getActivityById = (code) => {
    if (typeof code === 'number') {
      const result = economicActivities.filter(activity => activity.code === code);
      if (result.length === 1) {
        return result[0].name;
      }
    } else {
      return code;
    }
  }

  useEffect(() => {
    getHeadquarters().then((response) => {
      setHeadquarters(response.data);
    });
    getEconomyActivities().then((res) => {
      let result = [];
      res.data.map((activity, index) => {
        result[index] = {code: activity.code, name: `${activity.code} - ${activity.name}`}
      })

      setEconomicActivities(result);
      setActividades(result);
    });

    return () => { };
  }, []);

  return (
    <div className="approve_fields">
      <div className="information">
        <div>
          <h3>{t('customers.createCustomer.approveCustomer.title')}</h3>
        </div>
        <hr />
        <div className="input__group d-flex-row">
          <div className="select-economy d-flex-row">
            <div className="input__select input__1">
              <TextSearchBlockEconomicActivity 
                onChange={consultaActividadEconomica}
                name="actividadEconomicaId"
                onKeyUp={consultaActividadEconomica}
                data={actividades}
                placeholder={t('customers.createCustomer.approveCustomer.activity')}
                value={dataClient.actividadEconomicaId? getActivityById(dataClient.actividadEconomicaId) : undefined}
                disabled={false}
                autocomplete="off"
              />
            </div>
            <Select
              onChange={updateData}
              className="input__select input__2"
              data={headquarters}
              label={t('customers.createCustomer.approveCustomer.billingVenue')}
              name="sedeId"
              value={dataClient.sedeId}
              placeholder={t('transversal.selectOption')}
            />
            <Text
                className="input__2"
                value={dataClient.iva? dataClient.iva : ""}
                placeholder={t('customers.createCustomer.approveCustomer.vat')}
                Type="number"
                name="iva"
                onChange={updateData}
              />
            
          </div>
          <div className="rte-group d-flex-row">
              <Text
                value={dataClient.reteIva? dataClient.reteIva: ""}
                placeholder={t('customers.createCustomer.approveCustomer.rteVat')}
                Type="number"
                name="reteIva"
                onChange={updateData}
              />
              <Text
                value={dataClient.reteFuente? dataClient.reteFuente : "" }
                placeholder={t('customers.createCustomer.approveCustomer.rteAtSource')}
                Type="number"
                name="reteFuente"
                onChange={updateData}
              />
              <Text
                value={dataClient.reteIca? dataClient.reteIca : ""}
                placeholder={t('customers.createCustomer.approveCustomer.ica')}
                Type="number"
                name="reteIca"
                onChange={updateData}
              />
            </div>
          <div className="checkbox__group d-flex-row">
              
            <div className = "form__content__formBody__formChecked checkbox">
              <CheckboxSwitch
                checked={dataClient.autoRenta? dataClient.autoRenta : false}
                onChange={updateData}
                label={t('customers.createCustomer.approveCustomer.autoRent') + "0.8%"}
                name="autoRenta"
                disabledText={t('transversal.inactive')}
                enabledText={t('customers.createCustomer.approveCustomer.autoRent')}
              />
            </div>
            <div className = "form__content__formBody__formChecked checkbox">
              <CheckboxSwitch
                checked={dataClient.agenteRetenedor}
                onChange={updateData}
                label={t('customers.createCustomer.approveCustomer.withholdingAgent')}
                name="agenteRetenedor"
                disabledText={t('transversal.inactive')}
                enabledText={t('customers.createCustomer.approveCustomer.withholdingAgent')}
              />
            </div>
            <div className = "form__content__formBody__formChecked">
              <CheckboxSwitch
                checked={dataClient.activo}
                onChange={updateData}
                label={t('customers.createCustomer.approveCustomer.activeCustomer')}
                name="activo"
                disabledText={t('transversal.inactive')}
                enabledText={t('customers.createCustomer.approveCustomer.activeCustomer')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

{/* <Text
className="input__20 margin__bottom"
onChange={actualizarData}
value={dataClient.codigoPostal}
readonly=""
placeholder="Código Postal"
Type="number"
name="codigoPostal"
onKeyDown={(e) => isNumberKey(e)}
/> */}

export default ApproveClient;
