import React from 'react';
import "./textSearchEconomicActivity.scss";

const TextSearchEconomicActivity = ({
    name,
    value,
    onKeyUp,
    onChange,
    placeholder,
    data,
    disabled,
    autocomplete,
    ...otherProps
}) => {
    return (
        <div className="input-text input-text--text">
            <label className="input-text__body">
                <input 
                    className="input-text__input input-text__input--image"
                    type="search" 
                    id={name}
                    list="listaActividades"
                    value={value? value: ""}
                    onKeyUp={onKeyUp}
                    onChange={onChange}
                    placeholder=" "
                    name={name}
                    required
                    disabled={disabled}
                    autoComplete={autocomplete}
                />
                <span className="input-text__placeholder">{placeholder}</span>
                <datalist id="listaActividades">
                    {!!data &&
                        data.map(item => (
                            <option
                                value={item.name}
                                key={item.code}    
                            >
                                {item.name}
                            </option>
                        ))}
                </datalist>
            </label>
        </div>
    )
}

export default TextSearchEconomicActivity;