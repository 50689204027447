import moment from "moment";
import 'moment-timezone';
import React, { useEffect, useContext, useState, useRef } from "react";
import { useParams, useHistory } from 'react-router-dom';
import { Alert, ConfirmationAlert } from "../../molecules/alert/alert";
import { getInvoiceById, createInvoice, saveInvoiceRequest, updateInvoiceData } from '../../../services/facturaService';
import { validateInvoiceField, toBase64, formatValToCurrency, formatDate, getStatusName, validateInputDecimals } from '../../../utils/utils';
import { STATUS_ID, FILES_CATEGORIES } from '../../../utils/constans';
import { AppContextUser } from '../../../provider/userInSession';
import { FacturaContext } from '../../../provider/factura';
import { ClientContext } from '../../../provider/cliente';
import { ROLES } from '../../../config/roles';
import InvoiceCheck from '../../organism/invoice-check/invoiceCheck';
import Aside from '../../templates/aside/aside';
import Conceptos from '../../templates/conceptos/conceptos';
import GeneralInformation from '../../templates/informacion/generalInformation';
import Observaciones from '../../templates/observaciones/observaciones';
import Wrapper from '../../templates/wrapper/Wrapper';
import useLoader from '../../../utils/useLoader';
import './crearFactura.scss';
import { useTranslation } from "react-i18next";

const useMountEffect = effect => useEffect(effect, []);

function CrearFactura(Props) {
  const limitFiles = 5;
  const SCROLLIDOBSERVATIONS = 4;
  const [t] = useTranslation('global');
  const TEXT = `${t('invoices.createInvoice.invoiceClarification')}.`;
  const TEXT_STRONG = `${t('invoices.createInvoice.brandClarification')}.`;
  const { idFacturas } = useParams();
  const history = useHistory();

  const { dataClient, setDataClient } = useContext(ClientContext);
  const { dataUser } = useContext(AppContextUser);
  const [dataFactura, setDataFactura] = useContext(FacturaContext);
  const [loader, showLoader, hideLoader] = useLoader();
  const [buttons, setButtons] = useState([]);
  const [buttonState, setButtonState] = useState(false);
  const [showHideComponents, setShowHideComponents] = useState(false);
  const [fieldTitle, setFieldTitle] = useState('Cambiar estado');
  const [isDuplicate, setIsDuplicate] = useState(false);
  const observationRef = useRef(null);
  const executeScroll = () => observationRef.current.scrollIntoView();
  const [brandControl, setBrandControl] = useState(true);
  const changeClientState = useState(false);
  
  let inicialConsecutivo;
  let date = new Date();
  const stringDate = JSON.stringify(date);
  let currentYear = stringDate.substring(1, 5);
  let currentMonth = stringDate.substring(6, 8);
  let currentDay = stringDate.substring(9, 11);
  let fullDeadlineDateMin = currentYear + '-' + currentMonth + '-' + currentDay;
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const stringLastDay = JSON.stringify(lastDay)
  let numberOfLastDay = stringLastDay.substring(9, 11)
  let fullDeadlineDateMax = currentYear + '-' + currentMonth + '-' + numberOfLastDay;

  const setConsecutive = (sedeId) => {
    switch (sedeId) {
      case 2:
        inicialConsecutivo = 'BE';
        break;
      case 3:
        inicialConsecutivo = 'CE';
        break;
      case null:
        inicialConsecutivo = 'NN';
        break;
      default:
        inicialConsecutivo = 'ME';
        break;
    }
  }

  /* if (dataFactura.trm === null) {
    dataFactura.trm = 0;
    setDataFactura({
      type: 'AGREGAR',
      payload: { ...dataFactura }
    });
  } */

  const updateDate = e => {
    if (e.type) {
      dataFactura.fechaLimiteEntrega = e.target.value;
      setDataFactura({
        type: 'AGREGAR',
        payload: { fechaLimiteEntrega: e.target.value }
      });
    } else {
      switch (e.tag) {
        case 'fechaSolicitud':
          dataFactura.fechaSolicitud = e.value;
          break;
        case 'fechaLimiteEntrega':
          dataFactura.fechaLimiteEntrega = e.value;
          break;
        default:
          console.error("No se puede manejar este tipo de fecha", e);
          break;
      }
      setDataFactura({
        type: 'AGREGAR',
        payload: { ...dataFactura }
      });
    }

    setInvoiceInformation([
      {
        tag: `${t('invoices.createInvoice.applicationDate')}: `,
        value: formatDate(dataFactura.fechaSolicitud, "DD/MM/AAAA"),
        type: 'orange'
      },
      {
        tag: `${t('invoices.createInvoice.deliveryDeadline')}: `,
        value: formatDate(dataFactura.fechaLimiteEntrega, "DATE"),
        type: "date",
        callback: updateDate,
        min: fullDeadlineDateMin,
        max: fullDeadlineDateMax
      }
    ])
  }

  const checkUpdateDates = (dateToFormat, dateType) => {
    let splitDate = dateToFormat.split('-');
    let facDay = splitDate[2].substring(0, 2);
    let facMonth = splitDate[1];
    let facYear = splitDate[0];
    if (facYear < currentYear || facMonth < currentMonth || facDay < currentDay) {
      updateDate({ tag: dateType, value: fullDeadlineDateMin })
      return fullDeadlineDateMin;
    } else {
      return facYear + '-' + facMonth + '-' + facDay;
    }
  }

  const INITIAL_INFORMATION = [
    {
      tag: `${t('invoices.createInvoice.applicationDate')}: `,
      value: formatDate(dataFactura.fechaSolicitud, "DD/MM/AAAA"),
      type: 'orange'
    },
    {
      tag: `${t('invoices.createInvoice.deliveryDeadline')}: `,
      value: formatDate(dataFactura.fechaLimiteEntrega, "DATE"),
      type: "date", callback: updateDate,
      min: fullDeadlineDateMin,
      max: fullDeadlineDateMax
    }
  ];
  const [invoiceInformation, setInvoiceInformation] = useState(INITIAL_INFORMATION);
  React.useEffect(() => {
    const _invoiceInformation = [...invoiceInformation];
    if (_invoiceInformation[2]) {
      _invoiceInformation[0].tag = `${t('invoices.createInvoice.requestedBy')}:`;
      _invoiceInformation[1].tag = `${t('invoices.createInvoice.state')}:`;
      _invoiceInformation[2].tag = `${t('invoices.createInvoice.noRequest')}:`;
      _invoiceInformation[3].tag = `${t('invoices.createInvoice.applicationDate')}: `;
      _invoiceInformation[4].tag = `${t('invoices.createInvoice.deliveryDeadline')}: `;
    } else {
      _invoiceInformation[0].tag = `${t('invoices.createInvoice.applicationDate')}: `;
      _invoiceInformation[1].tag = `${t('invoices.createInvoice.deliveryDeadline')}: `;
    }
    setInvoiceInformation(_invoiceInformation);
    setButtons(buttons.map(button => ({...button, name: t(button.t)})));
  }, [t]);

  const resetApprovingFieldsOnInvoice = () => {
    dataFactura.files = dataFactura.files.filter(
      (item) => item?.categoria !== FILES_CATEGORIES.INVOICE
    );
    dataFactura.trm = 0;
    dataFactura.consecutivo = null;
    setDataFactura({
      type: 'AGREGAR',
      payload: {
        ...dataFactura,
      }
    });
  }

  const actualizarDataFactura = e => {
    setDataFactura({
      type: 'AGREGAR',
      payload: { ...dataFactura, [e.target.name]: e.target.value }
    });
  }

  const showInvoiceCheckComponent = (event) => { setShowHideComponents(true); }

  const updateInvoiceTrm = (trm) => {
    dataFactura.trm = validateInputDecimals(trm);
    setDataFactura({
      type: 'AGREGAR',
      payload: { ...dataFactura }
    });
  }

  const actualizarObservaciones = (e) => {
    dataFactura.observacionesEstado = e.target.value;
    setDataFactura({
      type: 'AGREGAR',
      payload: { ...dataFactura }
    })
  };

  const showHideComponent = (component) => {
    switch (component) {
      case "Button":
        if (!idFacturas) return true;
        if (dataFactura.estadoId === STATUS_ID.GUARDADA) return true;
        if (!idFacturas || isDuplicate) return true;
        return false;
      case "InvoiceCheck":
        if (!idFacturas || isDuplicate) return false;
        if ((dataUser.rol === ROLES.SOLICITANTE || dataUser.rol === ROLES.SUPERVISOR) && (dataFactura.estadoId === STATUS_ID.APROBADA || dataFactura.estadoId === STATUS_ID.ANULADA)) return true;
        if ((dataUser.rol === ROLES.SOLICITANTE || dataUser.rol === ROLES.SUPERVISOR) && dataFactura.estadoId !== STATUS_ID.DEVUELTA) return false;
        if (dataFactura.estadoId === STATUS_ID.GUARDADA) return false;
        return true;
      default:
        console.warn("No existe ese componente");
    }
  }

  const setFileOnProvider = (files, key, fileCategory) => {
    let filesFiltered = dataFactura.files.filter(file => file.categoria !== fileCategory);
    if (!(dataFactura.estadoId === STATUS_ID.ANULADA || dataFactura.estadoId === STATUS_ID.APROBADA)) {
      if (files.length) {
        files.map(file => {
          if (!file.archivoId)
            toBase64(file, fileCategory, (otherFile) => {
              dataFactura.files.push({
                categoria: fileCategory,
                nombreArchivo: file.name,
                file: otherFile.file
              })
              setDataFactura({
                type: 'AGREGAR',
                payload: { ...dataFactura }
              });
            })
        });
      } else {
        dataFactura.files = filesFiltered.concat(files);
        setDataFactura({
          type: 'AGREGAR',
          payload: { ...dataFactura }
        });
      }
    }
  }

  //Estas son las peticiones de cambio de estado
  const reviewInvoice = async () => {
    const updateFactura = await {
      ...dataFactura,
      estadoId: STATUS_ID.REVISADA
    };
    resetApprovingFieldsOnInvoice();
    callUpdateInvoice(updateFactura);
  };

  const returnInvoice = async () => {
    if (!dataFactura.observacionesEstado) {
      Alert("error", t('alerts.alert'), `${t('alerts.invoiceReturn')}.`);
    } else {
      resetApprovingFieldsOnInvoice();
      const updateFactura = await {
        ...dataFactura,
        estadoId: STATUS_ID.DEVUELTA,
      };
      callUpdateInvoice(updateFactura);
    }
  };

  const approveInvoice = async () => {
    if (!dataFactura.consecutivo) {
      Alert("error", t('alerts.alert'), t('alerts.consecutiveInvoice'));
    } else if (dataFactura.files.length === 0) {
      Alert("error", t('alerts.alert'), t('alerts.billSale'));
    } else {
      const updateFactura = await {
        ...dataFactura,
        estadoId: STATUS_ID.APROBADA,
        fechaFacturacion: moment().tz('America/Bogota').format('YYYY-MM-DD'),
        // observacionesEstado: observacionesEstado,
      };
      callUpdateInvoice(updateFactura);
    }
  };

  const annulInvoice = async () => {
    if (!dataFactura.observacionesEstado) {
      Alert("error", t('alerts.alert'), `${t('alerts.invoiceAnnulment')}.`);
    } else {
      ConfirmationAlert(
        "warning",
        t('alerts.confirmInvoiceAnnulment'),
        "",
        t('transversal.yes'),
        t('transversal.no'),
        async () => {
          const updateFactura = await {
            ...dataFactura,
            estadoId: STATUS_ID.ANULADA,
            // observacionesEstado: observacionesEstado,
          };
          callUpdateInvoice(updateFactura);
        }
      );
    }
  };

  const returnToReviwedState = () => {
    const updateFactura = {
      ...dataFactura,
      estadoId: STATUS_ID.REVISADA,
    };

    callUpdateInvoice(updateFactura);
  };

  const updateInvoice = async () => {
    const updateFactura = await {
      ...dataFactura,
      estadoId: STATUS_ID.PENDIENTE,
      observacionesEstado: ""
    };
    try {
      validateInvoiceField(updateFactura, () => {
        callUpdateInvoice(updateFactura);
      });
    } catch (e) {
      Alert(e.type, e.msg, e.details);
    }
  };

  const cancelInvoice = async () => {
    if (dataFactura.estadoId === STATUS_ID.DEVUELTA) {
      ConfirmationAlert(
        "warning",
        t('alerts.confirmInvoiceCancellation'),
        "",
        t('transversal.yes'),
        t('transversal.no'),
        async () => {
          const updateFactura = await {
            ...dataFactura,
            estadoId: STATUS_ID.CANCELADA,
          };
          callUpdateInvoice(updateFactura);
        }
      );
    }
  }

  const callUpdateInvoice = (invoiceData) => {
    setButtonState(true);
    showLoader();
    updateInvoiceData(invoiceData)
      .then((res) => {
        hideLoader();
        if (res.data.status == 200) {
          Alert("success", t('alerts.success'), t('alerts.invoiceUpdatedCorrectly'));
          history.push("/facturas-pendientes");
        } else {
          setButtonState(false);
          Alert("error", t('alerts.oops'), res.data.userMessage);
        }
      }).catch(err => {
        setButtonState(false);
        hideLoader();
        Alert("error", t('alerts.alert'), err);
      })
  }

  //Estas son las funciones de crear y guardar solicitud
  const requestInvoice = async () => {
    if (dataUser.rol === "Supervisor") {
      dataFactura.usuarioId = dataUser.userId;
      dataFactura.usuarioEmail = dataUser.userName.replace(" ", ".");
    }
    let invoiceToCreate = await {
      ...dataFactura,
      estadoId: STATUS_ID.PENDIENTE
    };
    try {
      validateInvoiceField(dataFactura, () => {
        showLoader();
        setButtonState(true);
        createInvoice(invoiceToCreate)
          .then(response => {
            hideLoader();
            switch (response.data.status) {
              case 200:
                history.push("/factura");
                Alert("success", t('alerts.success'), response.data.userMessage)
                break;
              default:
                Alert("error", t('alerts.oops'), response.data.userMessage);
                setButtonState(false);
                break;
            }
          })
          .catch(err => {
            hideLoader();
            setButtonState(false);
            Alert("error", t('alerts.oops'), err.message);
          });
      });
    } catch (e) {
      Alert(e.type, e.msg, e.details);
    }
  }

  const saveInvoice = async () => {
    let mensaje;
    if (dataFactura.clienteId === -1) {
      mensaje = `${t('alerts.customerRequired')}.`
    }
    if (dataFactura.filialId === 0) {
      mensaje = `${t('alerts.subsidiaryRequired')}.`
    }
    if (dataFactura.plazoPago === " ") {
      mensaje = `${t('alerts.paymentTermRequired')}.`
    }

    if (mensaje) {
      Alert("error", t('alerts.oops'), mensaje);
    } else {
      showLoader();
      setButtonState(true);
      let invoiceToSave = await {
        ...dataFactura,
      };

      if (dataFactura.estadoId === STATUS_ID.PENDIENTE) {
        invoiceToSave = {
          ...dataFactura,
          estadoId: STATUS_ID.GUARDADA
        }
      }
      saveInvoiceRequest(invoiceToSave).then(response => {
        hideLoader();
        switch (response.data.status) {
          case 200:
            history.push("/factura");
            Alert("success", t('alerts.invoiceSaved'), response.data.userMessage);
            break;
          default:
            Alert("error", t('alerts.oops'), response.data.userMessage);
            setButtonState(false);
            break;
        }
      }).catch(error => {
        hideLoader();
        setButtonState(false);
        Alert("error", t('alerts.oops'), error.message);
        // throw ({ type: "error", msg: "Ups, algo ha salido mal", details: "" });
      });
    }
  }

  //Necesitamos estos estados iniciales para mostrar los botones de la solicitud
  const INITIAL_BUTTONS = [
    { t: 'transversal.save', name: t('transversal.save'), primary: false, onClick: saveInvoice, disabled: buttonState },
    { t: 'invoices.createInvoice.requestInvoice', name: t('invoices.createInvoice.requestInvoice'), primary: true, onClick: requestInvoice, disabled: buttonState }
  ];

  //Esta es la función para consultar una solicitud de factura
  const getData = async () => {
    if (idFacturas) {
      const data = { facSolicitudId: idFacturas };
      showLoader();
      await getInvoiceById(data).then(res => {
        hideLoader();
        if (res.data.status === 200) {
          setDataFactura({
            type: 'AGREGAR',
            payload: res.data.data.factura
          });
          const invoiceDataToShow = [{ tag: `${t('invoices.createInvoice.requestedBy')}:`, value: res.data.data.factura.usuarioEmail },
            { tag: `${t('invoices.createInvoice.state')}:`, value: getStatusName(res.data.data.factura.estadoId) },
            { tag: `${t('invoices.createInvoice.noRequest')}:`, value: res.data.data.factura.facSolicitudId },
            { tag: `${t('invoices.createInvoice.applicationDate')}:`, value: formatDate(res.data.data.factura.fechaSolicitud, 'DD/MM/AAAA') },
            { tag: `${t('invoices.createInvoice.deliveryDeadline')}:`, value: formatDate(res.data.data.factura.fechaLimiteEntrega, 'DD/MM/AAAA'), callback: updateDate, type: "date" },
          ];
          //SCROLLIDOBSERVATIONS references the id state to scroll to observations

          setInvoiceInformation(invoiceDataToShow);
          setDataClient({
            type: 'AGREGAR',
            payload: res.data.data.cliente
          });
          setConsecutive(dataClient.sedeId);
        } else {
          Alert("error", t('alerts.alert'), res.data.userMessage);
          res.data.status === 403 ? history.push("/") : history.push("/factura");
        }
      }).catch(error => {
        hideLoader();
        console.error("Ha ocurrido un error", error);
      });
    } else {
      setDataFactura({ type: 'RESET' });
      setDataClient({
        type: 'RESET',
        payload: 'Voy resetear el cliente'
      });
    }
  };

  if (!dataFactura.facSolicitudId) {
    dataClient.usuarioId = dataUser.userId;
    dataFactura.usuarioId = dataUser.userId;
  }

  const handleDuplicate = () => {
    window.history.replaceState(null, "Crear factura", "/");
    delete dataFactura.facSolicitudId;
    delete dataFactura.fechaFacturacion;
    delete dataFactura.consecutivo;
    delete dataFactura.trm
    dataFactura.files = [];
    dataFactura.estadoId = 1;
    dataFactura.fechaLimiteEntrega = checkUpdateDates(dataFactura.fechaLimiteEntrega, "fechaLimiteEntrega");
    dataFactura.fechaSolicitud = checkUpdateDates(dataFactura.fechaSolicitud, "fechaSolicitud");
    setDataFactura({
      type: 'AGREGAR',
      payload: { ...dataFactura }
    });
    setIsDuplicate(true);
    setInvoiceInformation([
      { tag: `${t('invoices.createInvoice.applicationDate')}: `, value: formatDate(dataFactura.fechaSolicitud, "DD/MM/AAAA"), type: 'orange' },
      { tag: `${t('invoices.createInvoice.deliveryDeadline')}: `, value: formatDate(dataFactura.fechaLimiteEntrega, "DATE"), type: "date", callback: updateDate, min: fullDeadlineDateMin, max: fullDeadlineDateMax }
    ]);
    setButtons(INITIAL_BUTTONS);
  }

  function focusObservations() {
    executeScroll();
  }

  //Estos son los useEffects
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (dataFactura.estadoId === STATUS_ID.APROBADA || dataFactura.estadoId === STATUS_ID.ANULADA) {
      setShowHideComponents(true);
    }
    if (dataFactura.facSolicitudId) {
      switch (dataFactura.estadoId) {
        case STATUS_ID.ANULADA:
          setFieldTitle(t('invoices.createInvoice.annulatedInvoice'));
          setShowHideComponents(true);
          setButtons([]);
          break;
        case STATUS_ID.APROBADA:
          if (dataUser.rol === ROLES.SOLICITANTE || dataUser.rol === ROLES.SUPERVISOR) {
            setFieldTitle(t('invoices.createInvoice.approvedInvoice'));
            setShowHideComponents(true);
            setButtons([]);
            break;
          } else {
            setFieldTitle(t('invoices.createInvoice.approvedInvoice'));
            setShowHideComponents(true);
            setButtons([
              { t:'transversal.annular', name: t('transversal.annular'), primary: true, onClick: annulInvoice, disabled: buttonState },
              { t:'invoices.createInvoice.returnReviewed', name: t('invoices.createInvoice.returnReviewed'), primary: false, onClick: returnToReviwedState, disabled: buttonState }
            ]);
            break;
          }
        case STATUS_ID.CANCELADA:
          setFieldTitle(t('invoices.createInvoice.canceledInvoice'));
          setButtons([]);
          break;
        case STATUS_ID.DEVUELTA:
          setFieldTitle(t('invoices.createInvoice.changeStatus'));
          setButtons([
            { t: 'transversal.cancel', name: t('transversal.cancel'), primary: false, onClick: cancelInvoice, disabled: buttonState },
            { t: 'transversal.save', name: t('transversal.save'), primary: false, onClick: saveInvoice, disabled: buttonState },
            { t: 'transversal.send', name: t('transversal.send'), primary: true, onClick: updateInvoice, disabled: buttonState }
          ]);
          break;
        case STATUS_ID.GUARDADA:
          setButtons(INITIAL_BUTTONS);
          break;
        default:
          setFieldTitle(t('invoices.createInvoice.changeStatus'));
          setButtons([
            { t: 'transversal.review', name: t('transversal.review'), primary: false, onClick: reviewInvoice, disabled: buttonState },
            { t: 'transversal.return', name: t('transversal.return'), primary: false, onClick: returnInvoice, disabled: buttonState },
            { t: 'transversal.approve', name: t('transversal.approve'), primary: true, onClick: showHideComponents ? approveInvoice : showInvoiceCheckComponent, disabled: buttonState }
          ]);
          break;
      }
    } else {
      setButtons(INITIAL_BUTTONS);
    }
    if (dataFactura.estadoId === SCROLLIDOBSERVATIONS) {
      focusObservations();
    }
  }, [dataFactura]);

  useEffect(() => {
    if (dataFactura.filialId && dataClient.clienteId) {
      setBrandControl(false);
    }

    else setBrandControl(true);
  }, [dataClient.clienteId, dataFactura.filialId])

  const [blur, setBlur] = useState(false);

  return (
    <Wrapper aside={<Aside blur={blur} setBlur={setBlur} />} blur={blur}>
      <form>
        <GeneralInformation
          limitFiles={limitFiles}
          context={FacturaContext}
          isDuplicate={isDuplicate}
          handleDuplicate={handleDuplicate}
          dataToShow={invoiceInformation}
          changeClient={changeClientState}
          show={true}
        />

        <Conceptos isDuplicate={isDuplicate} brandDisable={brandControl} changeClient={changeClientState} />

        <Observaciones
          text={TEXT}
          context={FacturaContext}
          textStrong={TEXT_STRONG}
          buttonText="Solicitar factura"
          showButton={showHideComponent("Button")}
          buttons={buttons}
        />

        {showHideComponent("InvoiceCheck") &&
          <InvoiceCheck
            limitFiles={1}
            buttons={buttons}
            headerTitle={fieldTitle}
            showComponent={showHideComponents}
            showTextArea={dataFactura.estadoId === STATUS_ID.DEVUELTA || dataFactura.estadoId === STATUS_ID.CANCELADA || dataFactura.estadoId === STATUS_ID.ANULADA}
            handleFileChange={setFileOnProvider} handleDataChange={actualizarDataFactura}
            disabled={(dataFactura.estadoId === STATUS_ID.ANULADA || dataFactura.estadoId === STATUS_ID.APROBADA)}
            handleUptadeTRM={updateInvoiceTrm} handleObservacionesChange={actualizarObservaciones}
            files={dataFactura.files.filter(file => file.categoria === FILES_CATEGORIES.INVOICE)}
            consecutivo={dataFactura.consecutivo} observacionesEstado={dataFactura.observacionesEstado}
            trm={formatValToCurrency(1, dataFactura.trm ? dataFactura?.trm : 0)}
          />
        }

        {loader}
      </form>
      <div ref={observationRef}></div>
    </Wrapper>
  );
}

export default CrearFactura;
