import { Fragment } from "react";
import TextSearchBlockNombreCliente from "../text-search-block-nombre-cliente/textSearchBlockNombreCliente";
import PillButton from "../../atoms/pill-button/pillButton";
import "./finderSelector.scss";
import Select from "../../atoms/select/select";

const FinderSelector = ({ title, placeholder, dataSelect, onChange, searchName, onKeyUp, removeItemPill, dataButtons, value, isSelect, labelSelect, isDisabled, disabledMessage }) => {

    return (
        <Fragment>
            <div className="HeaderDataRequest">
                <h3>{title}</h3>
            </div>
            {isSelect ? 
                <Fragment>
                    <div className="dataClients__body bodyRequest">
                        <div className="dataClients__body__finder">
                            <Select 
                                onChange={onChange}
                                name={searchName}
                                label={labelSelect}
                                value={value}
                                data={dataSelect}
                                placeholder={placeholder}
                                required={true}
                                disabled={isDisabled}
                                messageDisabled={disabledMessage}
                                />
                        </div>
                        {dataButtons ? 
                            <div className="dataClients__body__selected-container">
                                {dataButtons.map(function (item) {
                                    return (<PillButton title={item.itemName || item.name } onClick={isDisabled ? ()=>{}: removeItemPill} itemId={item.itemId || item.code} key={item.itemId || item.code} disabled={isDisabled}/>);                        
                                })}
                            </div>
                        : null}
                    </div>
                     
                </Fragment>
                    : 
                <Fragment>
                    
                    <div className="dataClients__body bodyRequest">
                        <div className="dataClients__body__finder">
                            <TextSearchBlockNombreCliente
                                onChange={onChange}
                                name={searchName}
                                onKeyUp={onChange}
                                data={dataSelect}
                                value={value}
                                disabled={""}
                                autocomplete="off"
                                placeholder={placeholder}
                            />
                        </div>
                        {dataButtons ?
                            <div className="dataClients__body__selected-container">
                                {dataButtons.map(function (item) {
                                    return (<PillButton title={item.itemName} onClick={isDisabled ? ()=>{}:removeItemPill} itemId={item.itemId} key={item.itemId} disabled={isDisabled}/>);                        
                                })}
                            </div>
                        : null}
                    </div>
                </Fragment>
            }
        </Fragment>
    )
}

export default FinderSelector;