import { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";

import { getClientByID } from "../../../services/clientService";

import { ClientContext } from "../../../provider/cliente";

import HeaderClient from "../../molecules/header-create-client/headerClient";
import Aside from "../../templates/aside/aside";
import FormClient from "../../templates/form-client/formClient";
import RoundButton from "../../atoms/round-button/roundButton";
import Wrapper from "../../templates/wrapper/Wrapper";
import useLoader from "../../../utils/useLoader";
import addUser from "../../../images/addUser.svg";
import Back from "../../../images/ico-back.svg";
import { useTranslation } from "react-i18next";
import "./crearCliente.scss";

function CrearCliente() {
  const params = useParams();
  const { dataClient, setDataClient } = useContext(ClientContext);
  const [loader, showLoader, hideLoader] = useLoader();
  const [t] = useTranslation("global");

  useEffect(() => {
    if (params.id) {
      getData();
    } else {
      setDataClient({
        type: "RESET",
        payload: "Voy resetear el cliente desde la página crear cliente",
      });
    }
    return () => {};
  }, [params.id]);

  const getData = async () => {
    const data = { clienteId: params.id };
    showLoader();
    const user = await getClientByID(data);
    hideLoader();
    setDataClient({
      type: "AGREGAR",
      payload: { ...user },
    });
  };
  const title = dataClient.clienteId ? t('customers.createCustomer.edit') : t('customers.createCustomer.title');
  const [blur, setBlur] = useState(false);

  const goBack = () => window.history.back();

  return (
    <Wrapper aside={<Aside blur={blur} setBlur={setBlur} />} blur={blur}>
      <div className="HeaderButtonsContainer">
        <div className="HeaderInformation__button">
          <RoundButton icon={Back} onClick={goBack} className="BotonFixed" />
        </div>
      </div>
      <HeaderClient title={title} image={addUser} />
      <FormClient />
      {loader}
    </Wrapper>
  );
}
export default CrearCliente;
