import { useEffect, useState, useContext, Fragment } from "react";
import Aside from "../../templates/aside/aside";
import Wrapper from "../../templates/wrapper/Wrapper";
import HeaderInvoiceRequest from "../../organism/header-invoice-request/HeaderInvoiceRequest";
import PolicyTable from "../../templates/policyTable/policyTable";
import { useTranslation } from "react-i18next";

import { AppContextUser } from '../../../provider/userInSession';


const PolicyPendingTable = () => {
    const [t] = useTranslation('global');
    const title = t('policy.pendingPolicy.policyRequests');
    const [estados, setEstados] = useState([]);
    const [estadosPropios, setEstadosPropios] = useState([]);
    const { dataUser, setDataUser } = useContext(AppContextUser);

    useEffect(() => {
        if (dataUser.rol === "Administrador") {
            if (!estados.length)
                setEstados(['PENDIENTE', 'DEVUELTA']);
        } else if (dataUser.rol === "Aprobador") {
            if (!estados.length) {
                setEstados(['PENDIENTE']);
                setEstadosPropios(['DEVUELTA']);
            }
        } else if (dataUser.rol === "Solicitante" || dataUser.rol === "Supervisor") {
            if (!estados.length)
                setEstados(['DEVUELTA', 'PENDIENTE']);
        }
    });
    const [blur, setBlur] = useState(false);
    return (        
        <Fragment>
            <Wrapper aside={<Aside blur={blur} setBlur={setBlur}/>} blur={blur}>
                <HeaderInvoiceRequest title={title}/>
                <PolicyTable estados={estados} estadosPropios={estadosPropios} />
            </Wrapper>
        </Fragment>
    );
}

export default PolicyPendingTable;