import { useTranslation } from 'react-i18next';
import './header.scss'

function Header(props) {
    const [t] = useTranslation('global');
    let title = '';

    switch (true) {
        case props.policy && props.context.estadoId === 1:
            title = t('invoices.createInvoice.approvePolicy');
            break;
        case props.policy && props.context.estadoId === 5:
            title = t('invoices.createInvoice.policy');
            break;
        case props.policy:
            title = t('invoices.createInvoice.requestPolicy');
            break;
        case (props.context.estadoId === 1 && !props.context.facSolicitudId) || props.context.estadoId === 8:
            title = t('invoices.createInvoice.title');
            break;
        default:
            title = t('invoices.createInvoice.invoice');
            break;
    };

    return (
        <div className="Header">
            <div className="Primary">
                <div className="TitleContent">
                    <h1>{title}</h1>
                </div>
            </div>
        </div>
    );
}

export default Header;
