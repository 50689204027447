import React from "react";
import { ClientContext } from "../../../provider/cliente";
import { AppContextUser } from '../../../provider/userInSession';
import Button from "../../atoms/buttons/button";
import { Alert } from "../../molecules/alert/alert";
import { createClient, updateClient } from "../../../services/clientService";
import "./sendClientForm.scss";
import { useParams } from "react-router-dom";
import { validateClientFields, validatePermissionsFilial} from '../../../utils/utils';
import { useTranslation } from "react-i18next";

const SendClientForm = ({ user, setIsLoading }) => {

  const { dataClient, setDataClient } = React.useContext(ClientContext);
  const { dataUser, setDataUser } = React.useContext(AppContextUser);
  const params = useParams();
  const [buttonState, setButtonState] = React.useState(false);

  const [t] = useTranslation("global");

  const validarCreate = () => {
    let permissionsFilial = validatePermissionsFilial(dataUser);
    if(permissionsFilial) {
     deleteAttributes();
    }

    try {
      validateClientFields(dataClient,permissionsFilial ,() => {
        if(user.userId && !(user?.rol === "Solicitante" || user?.rol === "Supervisor") && params.id){
          updateClients();
        } else {
          requestClient();
        }
      });
    } catch (e) {
      Alert(e.type, e.msg, e.details);
    }
  };

  const deleteAttributes = () => {
    delete dataClient.codigoPostal;
    delete dataClient.digitoVerificacion;
  }
  
  const requestClient = async () => {
    setIsLoading(true);
    setButtonState(true);
    
    const data = await {
      ...dataClient,
      numeroDocumento: parseInt(dataClient.numeroDocumento),
      clasificacionTributariaId: parseInt(dataClient.clasificacionTributariaId),
      paisId: parseInt(dataClient.paisId),
      ciudadId: parseInt(dataClient.ciudadId),
      codigoPostal: parseInt(dataClient.codigoPostal),
      plazoPago: parseInt(dataClient.plazoPago),
      digitoVerificacion: parseInt(dataClient.digitoVerificacion),
      actividadEconomicaId: parseInt(dataClient.actividadEconomicaId),
      iva: parseInt(dataClient.iva),
      reteIva: parseInt(dataClient.reteIva),
      reteFuente: parseInt(dataClient.reteFuente),
      reteIca: parseInt(dataClient.reteIca),
      fechaCreacion: (dataClient.clienteId ? dataClient.fechaCreacion : new Date().toISOString()),
      usuarioId: dataUser.userId
    };

    createClient(data)
        .then((res) => {
          if (res.data.status === 200) {
            setIsLoading(false);
            setDataClient({
              type: 'RESET'
            });
            Alert("success", t('alerts.success'), t('alerts.createCustomer'));
          } else {
            setIsLoading(false);
            setButtonState(false);
            Alert("error", t('alerts.alert'), res.data.userMessage);
          }
        })
        .catch((err) => {
          setButtonState(false);
          setIsLoading(false);
          Alert("error", t('alerts.alert'), err);
        });
  }

  const updateClients = async () => {

    setIsLoading(true);
    setButtonState(true);
    
    const data = await {
      ...dataClient,
      numeroDocumento: parseInt(dataClient.numeroDocumento),
      clasificacionTributariaId: parseInt(dataClient.clasificacionTributariaId),
      paisId: parseInt(dataClient.paisId),
      ciudadId: parseInt(dataClient.ciudadId),
      codigoPostal: parseInt(dataClient.codigoPostal),
      plazoPago: parseInt(dataClient.plazoPago),
      digitoVerificacion: parseInt(dataClient.digitoVerificacion),
      actividadEconomicaId: parseInt(dataClient.actividadEconomicaId),
      iva: parseInt(dataClient.iva),
      reteIva: parseInt(dataClient.reteIva),
      reteFuente: parseInt(dataClient.reteFuente),
      reteIca: parseInt(dataClient.reteIca),
      fechaCreacion: (dataClient.clienteId ? dataClient.fechaCreacion : new Date().toISOString()),
      usuarioId: dataUser.userId
    };
    
      updateClient(data).then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          Alert("success", t('alerts.success'), res.data.userMessage);
        } else {
          setButtonState(false);
          setIsLoading(false);
          Alert("error", t('alerts.alert'), res.data.userMessage);
        }
      }).catch((err) => {
        setButtonState(false);
        setIsLoading(false);
        Alert("error", t('alerts.alert'), err.error);
      })
      
  };

  return (
    <>
      <div className="button__container">
        <Button primary name={t('transversal.send')} onClick={validarCreate} disabled={buttonState} />
      </div>
    </>
  );
};

export default SendClientForm;
