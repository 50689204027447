import React from 'react';
import './TablePagination.scss';
import LeftArrow from "../../../images/icon-arrow-left.svg";
import RightArrow from "../../../images/icon-arrow-right.svg";
import { useTranslation } from "react-i18next";

const TablePagination = ({currentPage, pageAmount, paginate}) => {
    const options = [];
    const [t] = useTranslation("global");
    for (let i = 1; i <= pageAmount; i++) options.push(<option key={i} value={i}>{i}</option>);

    return (
        <div className="navigation">
            <span className={`paginationIconContainer ${currentPage === 1 ? "m-hide_element" : ""}`}
                  onClick={(e) => paginate(e, currentPage - 1)}>
                <img className={"paginationIcon"} src={LeftArrow} alt={"Anterior"}/>
            </span>
            <span className={`paginationText`}>{t('components.table.page')}</span>
            <span className={`currentPageContainer`}>
                <select
                    className="select-currentpage"
                    value={currentPage}
                    onChange={(e) => paginate(e, parseInt(e.target.value))}
                    name={"Página actual"}>{options}</select>
            </span>
            <span className={`paginationText`}>{t('components.table.of')}</span>
            <span className={`paginationTotalText`}>{pageAmount}</span>
            <span className={`paginationIconContainer ${currentPage === pageAmount? "m-hide_element" : ""}`}
                  onClick={(e) => paginate(e, currentPage + 1)}>
                <img className={"paginationIcon"} src={RightArrow} alt={"Siguiente"}/>
            </span>
        </div>
    );
};

export default TablePagination;
