import React, { useState, createContext} from "react";
import Loader from '../components/atoms/loader/loader';

const useLoader = () => {
    const [loading, setLoading] = useState(false);

    return [
        loading ? <Loader isLoading={true} /> : null,
        () => {
            setLoading(true)
        },
        () => {
            setLoading(false)
        }
    ];
};

export default useLoader;