import { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { getUserById } from "../../../services/userService";
import { UserContext } from "../../../provider/user";
import UserForm from "../../molecules/user-form/userForm";
import Wrapper from "../../templates/wrapper/Wrapper";
import useLoader from "../../../utils/useLoader";
import Back from "../../../images/ico-back.svg";
import "./editUser.scss";
import Aside from "../../templates/aside/aside";
import HeaderClient from "../../molecules/header-create-client/headerClient";
import RoundButton from "../../atoms/round-button/roundButton";
import { useTranslation } from "react-i18next";

function EditUser() {
  const params = useParams();
  const { dataUser, setDataUser } = useContext(UserContext);
  const [loader, showLoader, hideLoader] = useLoader();
  const [t] = useTranslation('global');

  useEffect(() => {
    if (params.userId) {
      getDataUser(params.userId);
    }
  }, [params.userId]);

  const getDataUser = async (userId) => {
    showLoader();
    const user = await getUserById(userId);
    if (!user.negocios) {
      user.negocios = [];
    }
    if (!user.filiales) {
      user.filiales = [];
    }
    hideLoader();
    setDataUser({
      type: "AGREGAR",
      payload: { ...user },
    });
  };

  const title = t('administrator.users.title');
  const [blur, setBlur] = useState(false);
  const goBack = () => window.history.back();

  return (
    <Wrapper aside={<Aside blur={blur} setBlur={setBlur} />} blur={blur}>
      <div className="HeaderButtonsContainer">
        <div className="HeaderInformation__button">
          <RoundButton icon={Back} onClick={goBack} className="BotonFixed" />
        </div>
      </div>
      <HeaderClient title={title} />
      <UserForm />
      {loader}
    </Wrapper>
  );
}

export default EditUser;
