import React from 'react';
import Proptypes from 'prop-types';
import RadioButton from '../../atoms/radio-button/radioButton';
import './radioButtons.scss';

const RadioButtons = ({ name, data, orientation, onChange, selected, disabled }) => {
    return(
        <div className={orientation}>
            {data.map(({ label, value }) => (
                <RadioButton
                    key={label} 
                    name={name} 
                    value={value}
                    onChange={onChange}  
                    label={label} 
                    selected={selected} 
                    disabled={disabled} />
                ))}
        </div>
    );
};
RadioButtons.propTypes = {
    name: Proptypes.string,
    data: Proptypes.arrayOf(
            Proptypes.shape({
                label: Proptypes.string,
                value: Proptypes.string,
            })
        ),
    orientation: Proptypes.string,
    onChange: Proptypes.func,
    selected: Proptypes.string,
}
export default RadioButtons;