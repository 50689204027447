import {Fragment, useState, useEffect} from 'react';

import Wrapper from '../../templates/wrapper/Wrapper';
import InvoiceTable from '../../organism/invoice-table/InvoiceTable';
import HeaderInvoiceRequest from '../../organism/header-invoice-request/HeaderInvoiceRequest';
import Aside from '../../templates/aside/aside';
import { getFiliales } from "../../../services/parametersService";
import { useTranslation } from 'react-i18next';

import './InvoiceRequest.scss';

const InvoiceRequest = (Props) => {
    const [t] = useTranslation('global');
    const [blur, setBlur] = useState(false);
    const [allFiliales, setAllFiliales] = useState(null);
    
    const title = t('invoices.pendingInvoice.invoiceRequests');
    useEffect(() => { getAllFiliales() }, []);

    const getAllFiliales = async () => {
      await getFiliales()
        .then((filialesResponse) => {
          setAllFiliales(filialesResponse);
        })
        .catch((error) => {
          setAllFiliales([]);
        });
    }

    return (
        <Fragment>
            <Wrapper aside={<Aside blur={blur} setBlur={setBlur}/>} blur={blur}>
                <HeaderInvoiceRequest title={title}/>
                {allFiliales && <InvoiceTable filiales={allFiliales}/>}
            </Wrapper>
        </Fragment>
    );
}

export default InvoiceRequest; 
