import Proptypes from "prop-types";

import Images from "../images/images";

import selectIcon from "../../../images/icon-drop_down.svg";
import selectIconDisabled from "../../../images/icon-drop_down_disabled.svg";
import "./select.scss";
import { useTranslation } from "react-i18next";

const renderData = (data, isBrand) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [t, i18n] = useTranslation("global");

    if (data && data.length > 0) {
      const orderData = data.sort(orderItems);
        return orderData.map((item, i) => (
            <option
              className={"select__option"}
              key={item.code || item.id || item.sedeId || item.roleId}
              value={(item.code || item.id || item.sedeId || item.roleId).toString()}
            >
              {
                i18n.language === 'es'
                  ? item.name || item.nombre
                  : item.englishName || item.name
              }
            </option>
        ));
    } else if (isBrand) {
      return (
        <option key={0} value={0} className={'select__option'}>
          {t('invoices.createInvoice.noBrandOption')}
        </option>
      )
    } else {
      return [];
    }
}

const orderItems = (a,b) =>{
  if (a.name > b.name) return 1; //se sitúa b en un indice menor que a. (b) viene primero.
  
  if (a.name < b.name) return -1; //Se sitúa a en un indice menor que b. Es decir, (a) viene primero.

  return 0; //Se deja a y b sin cambios entre ellos.
}

const Select = ({
  code,
  messageDisabled,
  data,
  label,
  name,
  onChange,
  disabled,
  value,
  isBrand,
  hoverHint,
  required,
  placeholder,
  className
}) => {
  return (
    <div
      /*onClick={() => setIsShown(!isShown)}*/
      className={`select ${required ? `select-disabled` : ``} ${className ? className : ''}`}
    /*onMouseLeave={() => setIsShown(false)}*/
    >
      <span className={`${value && "shrink"} title`}>{label}</span>
      <select
        onChange={onChange}
        name={name}
        disabled={disabled ? disabled : false}
        required={required}
        value={value || 0}
      >
        {/* <option key="" value="" className="title">
          {label}
        </option> */}
          {hoverHint && <option></option>}
          {!hoverHint &&
          <option key="0" value="0" hidden>{disabled ? messageDisabled : placeholder}</option>
          }
        {renderData(data, isBrand)}
      </select>
      {disabled ? <Images Imagen={selectIconDisabled}/> : <Images Imagen={selectIcon}/>}
    </div>
  );
};

Select.propTypes = {
  label: Proptypes.string,
  name: Proptypes.string,
  onChange: Proptypes.func,
  hoverHint: Proptypes.string,
};
export default Select;
