import { useContext, useEffect, useState } from 'react'
import { Fragment } from 'react';
import { useHistory } from 'react-router';
import { AppContextUser } from '../../../provider/userInSession';
import { listClientsRequest } from '../../../services/clientService';
import useLoader from '../../../utils/useLoader';
import MainSelect from '../../atoms/main-select/MainSelect';
import Toolkit from '../../atoms/toolkit/Toolkit';
import { Alert } from '../../molecules/alert/alert';
import InputSearchSet from '../../molecules/input-search-set/InputSearchSet';
import TablePagination from '../../molecules/table-pagination/TablePagination';
import Table from '../../molecules/table/Table';
import { useTranslation } from "react-i18next";
import './clientsTable.scss';

function ClientsTable({props}) {
    let keyword, pageNo, pageSize, columnName, sort;

    const history = useHistory();
    const [loader, showLoader, hideLoader] = useLoader();

    const {dataUser} = useContext(AppContextUser);
    const [dataClients, setDataClients] = useState([]);
    const [searchItem, setSearchItem] = useState("");
    const [requestsPerPage, setRequestsPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [t] = useTranslation("global");

    const [orderBy, setOrderBy] = useState({
        nameColumn: "nombre",
        orderAsc: true
    });

    const headingClientsColumns = [
        {key: 'nombre', val: t('customers.customers.name'), width: 3},
        {key: 'numeroDocumento', val: t('customers.customers.nit'), width: 2},
        {key: 'direccion', val: t('customers.customers.address'), width: 3},
        {key: 'telefono', val: t('customers.customers.phone'), width: 2},
        {key: 'estado', val: t('customers.customers.state'), width: 2}
    ];

    const selectValues = [
        {value: 10, label: "10"},
        {value: 20, label: "20"},
        {value: 50, label: "50"}
    ];

    const handleOnChangeSearch = (event) => {
        setSearchItem(event.target.value);
    }

    const handleOnclickDataHeading = (nameTableColumn) => {
        let orderByVal = nameTableColumn === orderBy.nameColumn ? !orderBy.orderAsc : true;
        setOrderBy({
            nameColumn: nameTableColumn,
            orderAsc: orderByVal
        });
    }

    const handleOnItemClickListener = (row) => {
        const url = `?keyword=${searchItem}&pageNo=${currentPage}&pageSize=${requestsPerPage}&columnName=${orderBy.nameColumn}&sort=${orderBy.orderAsc}`;
        window.history.replaceState(null, null, url);
        separatePastParams(url);
        history.push("/cliente/" + row.clienteId);
    }

    const handleOnchange = (requestPerPage) => {
        setRequestsPerPage(requestPerPage.target.value);
        setCurrentPage(1);
    }

    const handleOnclick = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) setCurrentPage(newPage);
    }

    const reloadTable = () => {
        const filterData = {
            usuarioId: dataUser.userId,
            pageNo: (currentPage - 1),
            pageSize: requestsPerPage,
            keyword: searchItem,
            columnName: orderBy.nameColumn,
            sort: orderBy.orderAsc
        };
        showLoader();
        listClientsRequest(filterData).then(response => {
            setTotalPages(response.totalPages);
            setDataClients(response.resultList.slice());
            hideLoader();
        }).catch(error => {
            hideLoader();
            setDataClients([]);
            Alert("error", t('alerts.oops'), error);
        })
    }

    const separatePastParams = (url) => {
        const search = url.split("=");
        keyword = (search[1].split("&"))[0];
        pageNo = (search[2].split("&"))[0];
        pageSize = (search[3].split("&"))[0];
        columnName = (search[4].split("&"))[0];
        sort = search[5];
        setRequestsPerPage(pageSize);
        setSearchItem(keyword);
        setCurrentPage(parseInt(pageNo));
        setOrderBy({
            nameColumn: columnName.toString(),
            orderAsc: sort === "true"
        });
    }

    useEffect(() => {
        if(!window.location.search==""){
            separatePastParams(window.location.search);
        }
    },[])

    useEffect(() => {
        reloadTable();
    }, [currentPage, requestsPerPage, dataUser, orderBy, searchItem])
    
    return(
        <Fragment>
            <Toolkit>
                <InputSearchSet 
                    type="text"
                    placeholder={t('transversal.search')}
                    value={searchItem}
                    onChange={handleOnChangeSearch}
                />
            </Toolkit>

            <Table
                tableData={dataClients}
                headingColums={headingClientsColumns}
                title={null}
                breakOn='medium'
                orderBy={handleOnclickDataHeading}
                orderColumnName={orderBy.nameColumn}
                orderColumnDirection={orderBy.orderAsc}
                onItemClickListener={handleOnItemClickListener}
            />
            <div className="o-footer__table">
                <MainSelect 
                    options={selectValues}
                    name={`invoicetable`}
                    value={requestsPerPage}
                    onChange={handleOnchange}
                />
                <TablePagination 
                    paginate={(event, newPage) => handleOnclick(newPage)}
                    pageAmount={totalPages}
                    currentPage={currentPage}
                />
            </div>
            {loader}
        </Fragment>
    )
}

export default ClientsTable;