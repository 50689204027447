import "./loader.scss";
const Loader = ({ isLoading }) => {
  return (
    <div className={`content-loader ${isLoading && 'loading'}`}>
      <div className="loader"></div>
    </div>
  );
};

export default Loader;
