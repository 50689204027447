import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { deleteFile } from "../../../services/clientService";

import { ClientContext } from "../../../provider/cliente";
import { FILES_CATEGORIES, LIMIT_SIZE_FILE } from '../../../utils/constans';
import { Alert, ConfirmationAlert } from "../../molecules/alert/alert";
import InputFileBlock from "../../molecules/input-file-block/inputFileBlock";
import Image from '../../../images/client-pic.svg';

import "./clientFiles.scss";
import { useTranslation } from "react-i18next";

function ClientFields() {
  const [t] = useTranslation("global");
  const [fileArray, setFileArray] = useState({
    rut: [],
    camara_comercio: [],
    otros: [],
  });
  const { dataClient, setDataClient } = useContext(ClientContext);
  const params = useParams();
  useEffect(() => {
    if (params.id) {
      const camara_comercio = dataClient.files.filter((file) => {
        return file.categoria === FILES_CATEGORIES.CAMARA_COMERCIO;
      });
      const rut = dataClient.files.filter((file) => {
        return file.categoria === FILES_CATEGORIES.RUT;
      });
      const otros = dataClient.files.filter((file) => {
        return file.categoria === FILES_CATEGORIES.OTROS;
      });

      setFileArray({
        camara_comercio,
        rut,
        otros,
      });
    }
    if (!dataClient.files.length) {
      setFileArray({
        rut: [],
        camara_comercio: [],
        otros: []
      });
    }
  }, [dataClient]);

  const onFileUpload = (e, target) => {
    let archivos = [];
    let files = fileArray[target];
    let sw = true;

    for (const key in e.target.files) {
      if (
        isNaN(parseFloat(e.target.files[key])) &&
        !isFinite(e.target.files[key])
      ) {
        if (
          target !== FILES_CATEGORIES.OTROS &&
          (files.length >= 1 || e.target.files.length > 1)
        ) {
          Alert("error", t('alerts.oneFile'));
          sw = false;
          break;
        } else if (files.length > 0) {
          for (let i = 0; i < files.length; i++) {
            if (files[i].name === e.target.files[key].name) {
              sw = false;
            }
          }
        }
        if (sw && e.target.files[key].size) {

          let nombre = e.target.files[key].name;
          let extencion = nombre.split(".")[nombre.split(".").length - 1];
          let peso = parseInt(e.target.files[key].size);
          if (extencion !== "jpg" && extencion !== "JPG" && extencion !== "jpge" && extencion !== "JPGE" && extencion !== "png" && extencion !== "PNG" && extencion !== "pdf" && extencion !== "PDF") {
            Alert(nombre, t('alerts.typeFileTitle'), t('alerts.typeFileDescription'));
          } else if (peso >= LIMIT_SIZE_FILE) {
            Alert(nombre, t('alerts.sizeFileTitle'), t('alerts.sizeFileDescription'));
          } else {
            archivos.push(e.target.files[key]);
            toBase64(e.target.files[key], target);
          }
        }
      }
    }
    // archivos.pop();

    let arreglo2 = files?.concat(archivos);
    setFileArray({ ...fileArray, [target]: arreglo2 });
  };

  function removeFile(index, target) {
    let fileToRemove = fileArray[target].find((item, i) => i === index);
    ConfirmationAlert(
      "warning",
      t('alerts.confirmDelete'),
      "",
      t('transversal.yes'),
      t('transversal.no'),
      () => {
        if (fileToRemove.archivoId) {
          deleteFile(fileToRemove).then((res) => {
            if (res.data.status == 200) {
              Alert("success", t('alerts.success'), res.data.developerMessage);
            } else {
              Alert("error", t('alerts.alert'), res.data.userMessage);
            }
          });
        }
        dataClient.files = dataClient.files.filter(
          (item) => item?.nombreArchivo !== fileToRemove.name
        );
        setDataClient({
          type: 'AGREGAR',
          payload: { ...dataClient }
        });
        let archivos = fileArray[target].filter((item, i) => i !== index);
        setFileArray({ ...fileArray, [target]: archivos });
      }
    );
  }

  const toBase64 = (file, category) => {
    let array = dataClient["files"];

    if (typeof file === "object") {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const fileData = {
          categoria: category,
          nombreArchivo: file.name,
          file: reader.result.split(",")[1],
        };
        delete fileData.name;
        array.push(fileData)
        setDataClient({
          type: 'ADD-FILES',
          payload: { ...dataClient }
        });
      };
    }
  };

  const insertarArchivos = (e) => {
    let array = dataClient[e.target.name];
    let arreglo = array?.concat(e.target.value);
  };

  const download = (files) => {
    const archivo = files;
    const element = document.createElement("a");
    element.href = !files.urlArchivo
      ? URL.createObjectURL(archivo)
      : files.urlArchivo
    element.download = archivo.nombreArchivo;
    document.body.appendChild(element);
    element.click();
  };

  return (
    <div className="observaciones">
      <div className="container">
        <div>
          <h3>{t('customers.createCustomer.attchedFiles.title')}</h3>
        </div>
        <hr />
        <div className="files">
          <div className="first__file">
            <h3 className="title__h3">{t('customers.createCustomer.attchedFiles.commercialDocument')}</h3>
            <InputFileBlock
              onBlur={insertarArchivos}
              onChange={(e) => onFileUpload(e, FILES_CATEGORIES.CAMARA_COMERCIO)}
              required="required"
              data={fileArray.camara_comercio}
              removeFile={(index) => removeFile(index, FILES_CATEGORIES.CAMARA_COMERCIO)}
              download={download}
              name="filesCamara_Comercio"
              status={"upload"}
              limitFiles={1}
              title={t('components.inputFile.limitFile')}
            />
          </div>
          <div className="second__file">
            <h3 className="title__h3">{t('customers.createCustomer.attchedFiles.taxIdentificationDocument')}</h3>
            <InputFileBlock
              onBlur={insertarArchivos}
              onChange={(e) => onFileUpload(e, FILES_CATEGORIES.RUT)}
              required="required"
              data={fileArray.rut}
              removeFile={(index) => removeFile(index, FILES_CATEGORIES.RUT)}
              download={download}
              name="filesRut"
              status={"upload"}
              limitFiles={1}
              title={t('components.inputFile.limitFile')}
            />
          </div>
          <div className="third__file">
            <h3 className="title__h3">{t('customers.createCustomer.attchedFiles.others')}</h3>
            <InputFileBlock
              onBlur={insertarArchivos}
              onChange={(e) => onFileUpload(e, FILES_CATEGORIES.OTROS)}
              required="required"
              data={fileArray.otros}
              removeFile={(index) => removeFile(index, FILES_CATEGORIES.OTROS)}
              download={download}
              name="filesOtros"
              status={"upload"}
              limitFiles={5}
              title={t('components.inputFile.limitFiles', { limit: 5 })}
            />
          </div>
          <img className="image" src={Image}/>
        </div>
      </div>
    </div>
  );
}

export default ClientFields;
