import { useState } from 'react';
import { CookiesProvider } from 'react-cookie';
import Notifications from '../../organism/notifications/notifications';
import './Wrapper.scss';

const Wrapper =({ aside, children, blur, ...otherAsideProps }) =>{
    const [showNotifications, setShowNotifications] = useState(false);

    return (
        <div className="t-wrapper" {...otherAsideProps}>
            <aside className="t-wrapper__aside">
                {aside}
            </aside>
            <main className={`t-wrapper__children ${blur ? 't-wrapper__children-blur' : ''} ${showNotifications ? 't-wrapper__children-not-scroll' : ''}`}>
                <CookiesProvider>
                    <Notifications display={showNotifications} setDisplay={setShowNotifications}/>
                </CookiesProvider>
                {children}
            </main>
            </div>
    );
}

export default Wrapper;