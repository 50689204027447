import { useContext, useEffect, useState } from "react";
import { ClientContext } from "../../../provider/cliente";
import { getClientsBrands } from "../../../services/clientService";
import PillButton from "../../atoms/pill-button/pillButton";
import { useTranslation } from "react-i18next";
import "./associatedBrands.scss";

function AssociatedBrands() {
  const { dataClient } = useContext(ClientContext);
  const [t] = useTranslation("global");

  const [dataBrands, setDataBrands] = useState([]);
  const [showFormBrands, setShowFormBrands] = useState(false);

  useEffect(() => {
    getClientsBrands(dataClient.clienteId)
      .then((response) => setDataBrands(response))
      .catch((err) => setDataBrands([]));
  }, [dataClient]);

  return (
    <section className="brands_fields">
      <h3>{t('customers.createCustomer.associatedBrands.title')}</h3>
      <hr />
      {/* <div className="button_create_brands">
        <Button
          onClick={() => setShowFormBrands(true)}
          name="Solicitar marca"
          primary={true}
        />
      </div> */}

      {/* {showFormBrands && <BrandForm isFromClient />} */}

      <ul className="associated_brands">
        {dataBrands.map((brand) => {
          return (
            <li key={brand.itemId}>
              <PillButton
                title={brand.itemName}
                itemId={brand.itemId}
                disabled={true}
              />
            </li>
          );
        })}
      </ul>
    </section>
  );
}

export default AssociatedBrands;
