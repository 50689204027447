import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import UserInSessionProvider from "./provider/userInSession";

import App from "./app";

import "./index.scss";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import i18next_es from './i18n/es.json';
import i18next_en from './i18n/en.json';
import Translation from './components/organism/translation-language/translation-language';

i18next.init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem("i18nextLng") || "es",
  fallbackLng: localStorage.getItem("i18nextLng") || "es",
  resources: {
    es: {
      global: i18next_es
    },
    en: {
      global: i18next_en
    }
  }
})

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <Translation />
      <UserInSessionProvider>
        <App />
      </UserInSessionProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
