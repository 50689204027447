import React, { useState } from 'react';

import Aside from '../../templates/aside/aside';
import Wrapper from '../../templates/wrapper/Wrapper';
import HomeTemplate from '../../templates/homeTemplate/homeTemplate';
import client from '../../../images/ico-access-client.svg';
import brand from '../../../images/ico-access-brand.svg';
import invoice from '../../../images/ico-access-invoice.svg';
import policy from '../../../images/ico-access-policy.svg';
import { useTranslation } from "react-i18next"

function Home({props}) {
    const [blur, setBlur] = useState(false);
    const [t] = useTranslation("global");
    const HomeLinks = [
        {
            image: client,
            text: t('home.createCustomer'),
            link: '/cliente',
            users: ['Solicitante', 'Supervisor', 'Administrador']
        },
        {
            image: brand,
            text: t('home.createBrand'),
            link: '/marcas/crear',
            users: ['Administrador']
        },
        {
            image: invoice,
            text: t('home.createInvoice'),
            link: '/factura',
            users: ['Solicitante', 'Supervisor', 'Administrador']
        },
        {
            image: policy,
            text: t('home.createPolicy'),
            link: '/poliza',
            users: ['Solicitante', 'Supervisor', 'Administrador']
        },
        {
            image: invoice,
            text: t('home.pendingInvoices'),
            link: '/facturas-pendientes',
            users: ['Aprobador']
        },
        {
            image: invoice,
            text: t('home.invoiceHistory'),
            link: '/historico-de-facturas',
            users: ['Aprobador']
        },
        {
            image: policy,
            text: t('home.pendingInvoices'),
            link: '/polizas-pendientes',
            users: ['Aprobador']
        },
        {
            image: policy,
            text: t('home.invoiceHistory'),
            link: '/historico-de-polizas',
            users: ['Aprobador']
        }
    ]

    const title = t('home.title');
    const supportEmail = t('home.supportEmail');
    const message = <p>{t('home.help')}<a href={`mailto:${supportEmail}`}>{supportEmail}</a></p>;

    return (
        <Wrapper aside={<Aside blur={blur} setBlur={setBlur} />} blur={blur}>
            <HomeTemplate title={title} links={HomeLinks} message={message} />
        </Wrapper>
    )
}

export default Home;