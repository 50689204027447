import { useState } from 'react';
import HeaderInvoiceRequest from '../../organism/header-invoice-request/HeaderInvoiceRequest';
import ManageCountries from '../../organism/manage-countries/manageCountries';
import Aside from '../../templates/aside/aside';
import Wrapper from '../../templates/wrapper/Wrapper';
import { useTranslation } from "react-i18next";

function Countries({props}) {
    const [t] = useTranslation("global");
    const [blur, setBlur] = useState(false);
    const title = t('administrator.manageCountries.title');

    return (
        <Wrapper aside={ <Aside blur={blur} setBlur={setBlur} /> } blur={blur} >
            <HeaderInvoiceRequest title={title} />
            <ManageCountries />
        </Wrapper>
    )
}

export default Countries;