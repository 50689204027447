import { useContext, useEffect } from "react";
import { isInvoiceRequestDisable } from "../../../utils/utils";
import Button from "../../atoms/buttons/button";
import TextAreaBlock from "../../molecules/text-area-block/textAreaBlock";
import "./observaciones.scss";
import { useTranslation } from "react-i18next";

const Observaciones = (Props) => {
  const [dataFactura, setDataFactura] = useContext(Props.context);
  const [t] = useTranslation('global');

  useEffect(() => {
    if (dataFactura.reset) {
      dataFactura.reset = false;
    }
  }, [dataFactura]);

  const actualizarObservaciones = (e) => {
    dataFactura.observaciones = e.target.value;
    setDataFactura({
      action: "ADD OBSERVACIONES",
      payload: { ...dataFactura },
    });
  };

  return (
    <div className="observaciones">
      <div className="body observacionesContent">
        <div className="headerBody">
          <h3>{t('transversal.observations')}</h3>
        </div>
        {isInvoiceRequestDisable(dataFactura) ? (
          <TextAreaBlock
            maxLength={500}
            text={Props.text}
            textStrong={Props.textStrong}
            value={dataFactura.observaciones}
            disabled="disabled"
          ></TextAreaBlock>
        ) : (
          <TextAreaBlock
            maxLength={500}
            text={Props.text}
            textStrong={Props.textStrong}
            onChange={actualizarObservaciones}
            value={dataFactura.observaciones}
            placeholder={t('transversal.observationsAdd')}
          ></TextAreaBlock>
        )}
      </div>
      {Props.showButton && (
        <div className="button__container">
          {Props.buttons.map((button) => {
            if (!button.hide) {
              return (
                <Button
                  key={button.name}
                  primary={button.primary}
                  name={button.name}
                  onClick={button.onClick}
                  disabled={button.disabled}
                />
              );
            }
          })}
        </div>
      )}
    </div>
  );
};
export default Observaciones;
