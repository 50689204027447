import { useState, useContext, useEffect } from 'react';
import { deleteFile } from '../../../services/facturaService';
import Images from '../../atoms/images/images';
import Text from '../../atoms/text/text';
import { useParams } from "react-router-dom";
import InputFileBlock from '../../molecules/input-file-block/inputFileBlock';
import RadioButtons from '../../molecules/radio-buttons/radioButtons';
import { Alert, ConfirmationAlert } from "../../molecules/alert/alert";
import { FILES_CATEGORIES, LIMIT_SIZE_FILE } from '../../../utils/constans';
import { isExtentionsValid, isInvoiceRequestDisable } from '../../../utils/utils';
import InvoiceImage from '../../../images/imagen-facturas.svg';
import PolicyImage from '../../../images/imagen-polizas.svg';
import './paymentConditions.scss';
import { useTranslation } from 'react-i18next';

const PaymentConditions = (props) => {
    const params = useParams();
    const [t] = useTranslation('global');
    let daysOfPaymentTerm = [
        { label: t('transversal.daysAmount', { amount: 30 }), value: "30" },
        { label: t('transversal.daysAmount', { amount: 60 }), value: "60" },
        { label: t('transversal.cash'), value: "0" }
    ]

    let [fileArray, setFileArray] = useState([]);
    const [estado, setEstado] = useState("disabled");
    const [otherRadioButtonState, setOtherRadioButtonState] = useState(false)
    const [contextData, setContextData] = useContext(props.context);

    const toBase64 = (file) => {
        let array = contextData["files"];
        if (typeof file === "object") {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                const fileData = {
                    categoria: props.policy ? FILES_CATEGORIES.POLICY_REQUEST : FILES_CATEGORIES.INVOICE_REQUEST,
                    nombreArchivo: file.name,
                    file: reader.result.split(",")[1],
                };
                array.push(fileData)
                setContextData({
                    type: 'ADD-FILE',
                    payload: { ...contextData, files: array }
                });
            };
        }
    };

    const onFileUpload = (e) => {
        let archivos = [];
        
        for (const key in e.target.files) {
            if (isNaN(parseFloat(e.target.files[key])) && !isFinite(e.target.files[key])) {
                let sw = true;
                if (fileArray.length > 0) {
                    for (let i = 0; i < fileArray.length; i++) {
                        if (fileArray[i].name === e.target.files[key].name) {
                            sw = false;
                        }
                    }
                }
                if (sw && e.target.files[key].size) {

                    let nombre = e.target.files[key].name;
                    let extencion = nombre.split(".")[nombre.split(".").length - 1];
                    let peso = parseInt(e.target.files[key].size);
                    if (!isExtentionsValid(["jpg", "jpeg", "png", "pdf"], extencion)) {
                        Alert(nombre, t('alerts.fileTypeNotAllowedTitle'), t('alerts.fileTypeNotAllowedDescription'));
                    } else if (peso >= LIMIT_SIZE_FILE) {
                        Alert(nombre, t('alerts.invalidFileWeightTitle'), t('alerts.invalidFileWeightDescription'));
                    } else {
                        archivos.push(e.target.files[key]);
                        toBase64(e.target.files[key]);
                    }
                }
            }

            let arreglo2 = fileArray.concat(archivos);
            setFileArray(arreglo2);
            // contextData.files = fileArray;
            setContextData({
                type: 'ADD-FILE',
                payload: { ...contextData }
            });
        }
    }

    function removeFile(index) {
        let fileToRemove = fileArray.find((item, i) => i === index);
        ConfirmationAlert(
            "warning",
            t('alerts.confirmDelete'),
            "",
            t('transversal.yes'),
            t('transversal.no'),
            () => {
                if (fileToRemove.archivoId) {
                    deleteFile(fileToRemove).then((res) => {
                        if (res.data.status == 200) {
                            Alert("success", t('alerts.success'), res.data.developerMessage);
                        } else {
                            Alert("error", t('alerts.alert'), res.data.userMessage);
                        }
                    });
                }
                let archivos = fileArray.filter((item, i) => i !== index);
                setFileArray(archivos);
                const nameFileToRemove = fileToRemove.name ? fileToRemove.name : fileToRemove.nombreArchivo
                contextData.files = contextData.files.filter(
                    (item) => item?.nombreArchivo !== nameFileToRemove
                );
                setContextData({
                    type: 'AGREGAR',
                    payload: { ...contextData }
                });
            }
        );
    }

    const actualizarDiasPago = e => {
        if (!props.policy) {
            if (e.target.value === "-1") {
                setEstado("");
                setOtherRadioButtonState(true)
                contextData.plazoPago = " "
            } else {
                contextData.plazoPago = parseInt(e.target.value);
                setEstado("disabled");
                setOtherRadioButtonState(false)
            }
            setContextData({
                type: 'ADD-FILE',
                payload: { ...contextData }
            });
        } else {
            console.error("No te va a mostrar nada papu!!!")
        }
    };

    const actualizarDiasPagoText = e => {
        if (!props.policy) {
            contextData.plazoPago = parseInt(e.target.value);
            setContextData({
                type: 'ADD-FILE',
                payload: { ...contextData }
            });
        } else {
            console.error("No papu!! noo hay nada!")
        }
    }

    const download = (files) => {
        const archivo = files;
        const element = document.createElement("a");
        element.href = !files.urlArchivo
            ? URL.createObjectURL(archivo)
            : files.urlArchivo
        element.download = archivo.nombreArchivo;
        document.body.appendChild(element);
        element.click();
    }

    useEffect(() => {
        if (params.idFacturas) {
            setFileArray(contextData?.files.filter(file => file.categoria === FILES_CATEGORIES.INVOICE_REQUEST))
            if (contextData.plazoPago === 0 || contextData.plazoPago === 60 || contextData.plazoPago === 30 || contextData.plazoPago === -1) {
                setEstado("disabled");
                setOtherRadioButtonState(false)
            } else {
                setEstado("");
                setOtherRadioButtonState(true)
            }
        }

        if (params.idPolicy) {
            setFileArray(contextData?.files.filter(file => file.categoria === FILES_CATEGORIES.POLICY_REQUEST));
        }

        if (!contextData.files.length && contextData.reset) {
            setFileArray([]);
        }
    }, [contextData]);

    const getTitleInputFileBlock = () => {
        let message = t('components.inputFile.limitFile');
        if (props.limitFiles > 1) message = t('components.inputFile.limitFiles', { limit: props.limitFiles });
        return  message;
    }

    return (
        <div className="ContentPaymentConditions">
            <div className="HeaderDocumentsClient HeaderDataClient">
                {props.show ? <h3>{t('invoices.createInvoice.attachments')}</h3> : <h3>{t('invoices.createInvoice.attachDocuments')}</h3>}
            </div>
            <div className="filesContainer">
                <div className={props.policy ? `DocumentPolicy ${!isInvoiceRequestDisable(contextData) ? 'create' : ''}` : !isInvoiceRequestDisable(contextData) ? "Documents" : "DocumentsList"}>
                    <InputFileBlock
                        onChange={onFileUpload}
                        required={props.policy && contextData.monedaId === 2 ? "required" : ""}
                        data={fileArray}
                        removeFile={(index) => removeFile(index)}
                        download={download}
                        title={getTitleInputFileBlock()}
                        status={isInvoiceRequestDisable(contextData) ? 'view' : 'upload'}
                        limitFiles={props.limitFiles}
                    />
                </div>
                {!isInvoiceRequestDisable(contextData) ?
                <div className="ImageTelecommuting">
                    <Images className="ImageFacture" Imagen={props.policy ? PolicyImage : InvoiceImage} />
                </div> : ''}
            </div> 
            {!(isInvoiceRequestDisable(contextData) && props.policy) &&
                <div className={props.policy ? "PaymentConditionsPolicy" : "PaymentConditions"}>
                    {!props.policy && !isInvoiceRequestDisable(contextData) &&
                        <div className="OptionsButton">
                            <b>{t('invoices.createInvoice.paymentConditions')}</b>
                            <RadioButtons onChange={actualizarDiasPago} 
                                          name='Payment' 
                                          data={daysOfPaymentTerm} 
                                          orientation="horizontal"
                                          selected={(contextData.facSolicitudId || props.isDuplicate) ? contextData.plazoPago.toString() : ""} 
                                          disabled={isInvoiceRequestDisable(contextData)} />
                            <p className="custom-checkbox">
                                <label className="custom-radio-checkbox">
                                    <input
                                        required
                                        className="custom-radio-checkbox__input"
                                        onChange={actualizarDiasPago}
                                        type="radio"
                                        name="Payment"
                                        value="-1"
                                        selected=""
                                        checked={otherRadioButtonState}
                                        disabled={isInvoiceRequestDisable(contextData)}
                                    />
                                    <span
                                        className="custom-radio-checkbox__show custom-radio-checkbox__show--radio">
                                    </span>
                                    <span className="custom-radio-checkbox__text">{t('transversal.other')}</span>
                                </label>
                            </p>
                            <Text Type="number" id="text-otro" maxLength="3" onChange={actualizarDiasPagoText} placeholder="Días" className={"options-button__otro-dia " + estado} value={contextData.plazoPago} disabled={isInvoiceRequestDisable(contextData)} />
                        </div>}
                        {!props.policy && isInvoiceRequestDisable(contextData) &&
                        <div className="displayConditions">
                            <b className="conditions">{t('invoices.createInvoice.paymentConditions')}:  </b> <span className="selectedDays">{contextData.plazoPago} {t('transversal.days')}</span> 
                            
                        </div>}
                    
                </div>}
        </div>
    )
}

export default PaymentConditions;