import React from 'react';
import './clientHistory.scss';
import { ClientContext } from '../../../provider/cliente';
import { useTranslation } from 'react-i18next';

function ClientHistory({props}) {
    const { dataClient, setDataClient } = React.useContext(ClientContext);
    const [t] = useTranslation("global");

    const redirect = (path) => {
        return `${path}?filtro=${dataClient.numeroDocumento}&page=1&requests=20&orderby=numeroDocumento&order=false`;
    }

    return (
        <div className="history_fields">
            <div className="information">
                <div>
                    <h3>{t('customers.createCustomer.customerHistory.title')}</h3>
                </div>
                <hr />
                <div className="clients-links">
                    <div className="clients-links-col">
                        <a target='_blank' href={redirect('/facturas-pendientes')}>
                            {t('customers.createCustomer.customerHistory.pendingBillingRequests')}
                        </a>
                        <a target='_blank' href={redirect('/historico-de-facturas')}>
                            {t('customers.createCustomer.customerHistory.historicalBillingRequests')}
                        </a>
                    </div>
                    <div className="clients-links-col">
                        <a target='_blank' href={redirect('/polizas-pendientes')}>
                            {t('customers.createCustomer.customerHistory.pendingPolicyApplications')}
                        </a>
                        <a target='_blank' href={redirect('/historico-de-polizas')}>
                            {t('customers.createCustomer.customerHistory.historicalPolicyApplications')}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientHistory;