import { useContext } from 'react';

import { BASE_URL } from '../../../constants/api-constants';
import RequestData from '../../molecules/request-data/requestData';
import Header from '../../molecules/header/header';
import DataClient from '../../organism/data-client/dataClient';
import PaymentConditions from '../../organism/payment-conditions/paymentConditions';
import { ConfirmationAlert } from "../../molecules/alert/alert";
import { STATUS_ID } from '../../../utils/constans';
import './generalInformation.scss';
import Back from '../../../images/ico-back.svg';
import Adjuntar from '../../../images/adjuntar.svg';
import RoundButton from '../../atoms/round-button/roundButton';
import { useTranslation } from 'react-i18next';


function GeneralInformation(Props) {
    const [t] = useTranslation('global');
    const [data] = useContext(Props.context)

    const goBack = () =>{
        ConfirmationAlert(
            "warning",
            t('alerts.comeBack'),
            "",
            t('transversal.yes'),
            t('transversal.no'),
            () => {
                window.history.back();
            }
        );
    }
    
    return (
        <div className="GeneralInformation">
            <div className="HeaderInformation">
                <Header policy={Props.policy} context={data} />
                <div className="HeaderButtonsContainer">
                    <div className="HeaderInformation__button">
                        <RoundButton icon={Back} onClick={goBack} className="BotonFixed" />
                    </div>
                    {!Props.policy && 
                        (data.estadoId === STATUS_ID.PENDIENTE 
                            || data.estadoId === STATUS_ID.APROBADA 
                            || data.estadoId === STATUS_ID.CANCELADA ) && data.facSolicitudId ?  
                    <div className="HeaderInformation__button">
                        <RoundButton icon={Adjuntar} onClick={Props.handleDuplicate} className="BotonFixed" />
                    </div> : null}
                </div>
            </div>
            <div className="Body">
                <div className="HeaderBody">
                    <h3>{t('invoices.createInvoice.generalInformation')}</h3>
                </div>
                <hr />
                <div className="PolicyData">
                    <div className="bodyRequest">
                        <RequestData data={Props.dataToShow} date={Props.dataToShow.length === 2 ? true : false} /> 
                    </div>
                </div>
                <div hidden>{BASE_URL}</div>
                <div className='ContentDataClient'>
                    <DataClient context={Props.context} policy={Props.policy} changeClient={Props.changeClient} />
                </div>
                <div className='Conditions'>
                    <PaymentConditions limitFiles={Props.limitFiles} context={Props.context} policy={Props.policy} show={Props.show} isDuplicate={Props.isDuplicate} />
                </div>
            </div>
        </div>
    );
}

export default GeneralInformation;

