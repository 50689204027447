import { useState, useContext } from "react";
import { AppContextUser } from "../../../provider/userInSession";
import { useTranslation } from "react-i18next"
import { Redirect } from "react-router-dom";
import { Alert } from '../../molecules/alert/alert';
import Amplify, { Auth } from "@aws-amplify/auth";
import awsconfig from "../../../aws-exports";
import Section from "../../atoms/section/section";
import Button from "../../atoms/buttons/button";
import LoginComponentConstants from "../../../constants/login-constants";
import IcoGoogle from "../../../images/ico-google-white.svg";
import "./loginTemplate.scss";

Amplify.configure(awsconfig);

function LoginTemplate(props) {
  const constants = LoginComponentConstants;

  const [redirectLogin, setRedirectLogin] = useState(false);
  const { dataUser } = useContext(AppContextUser);

  const [t] = useTranslation(constants.USE_TRANSLATION_GLOBAL_VALUE);


  if (props.isAuthed === false)
    Alert(constants.ALERT_ERROR_TYPE, t('alerts.unauthorizedUserTitle'), t('alerts.unauthorizedUserDescription'));

  if (redirectLogin) {
    return <Redirect to={constants.BILL_PATH} />;
  }

  return (
    <Section className="login">
      {dataUser.userId && <Redirect to={constants.ROOT_PATH} />}
      <div className="login__wrapper">
        <div className="login__wrapper__particle-first"></div>
        <div className="login__wrapper__particle-second"></div>
        <div className="login__wrapper__particle-third"></div>
      </div>
      <div className="login__logo">
        <div className="login__logo__shadow"></div>
        <span className="login__logo__ball"></span>
        <span className="login__logo__one"></span>
        <span className="login__logo__two"></span>
        <span className="login__logo__three"></span>
      </div>
      <Section className="login__container">
        <div className="login__titles">
          <h1>{t('login.greeting')}</h1>
          <h4>{t('login.appDescription')}</h4>
        </div>

        <Button
          googleSignIn
          className="quaternary login-button"
          name={t('login.buttonGoogle')}
          onClick={() => Auth.federatedSignIn({ provider: constants.AUTHENTICATION_AMPLIFY_PROVIDER })}
        >
          <div>
            <img src={IcoGoogle} alt={constants.GOOGLE_LOGO_ALT} />
          </div>
        </Button>

        <div className="container-lines">
          <div className="container-lines__line"></div>
          <div className="container-lines__line"></div>
        </div>

        <h6 className="login__contact">
          {t('login.loginTroubles')}
          <a className="login__link" href={`${constants.HELP_DESK_URL}`} target="_blank" rel="noopener noreferrer">
            {t('login.contact')}
          </a>
        </h6>
      </Section>
    </Section>
  );
}

export default LoginTemplate;
