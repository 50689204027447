import React from 'react'
import './headerClient.scss';
function HeaderClient({ title, image }) {
    return (
        <div className="primary">
            <div className="title__content">
                <h1>{title}</h1>
            </div>
        </div>
    );
}

export default HeaderClient;
