import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { getAllStates, updateState, saveState, getAllCountries } from '../../../services/getLocationList';
import useLoader from '../../../utils/useLoader';
import { validateLocationData } from '../../../utils/utils';
import Button from '../../atoms/buttons/button';
import MainSelect from '../../atoms/main-select/MainSelect';
import Select from '../../atoms/select/select';
import Text from '../../atoms/text/text';
import { Alert } from '../../molecules/alert/alert';
import InputSearchSet from '../../molecules/input-search-set/InputSearchSet';
import TablePagination from '../../molecules/table-pagination/TablePagination';
import Table from '../../molecules/table/Table';
import './manageStates.scss';
import { useTranslation } from "react-i18next";

function ManageStates({props}) {
    let keyword, pageNo, pageSize, sortBy, ascSort;
    
    const [loader, showLoader, hideLoader] = useLoader();
    const history = useHistory();

    const [tableData, setTableData] = useState([]);

    const [searchItem, setSearchItem] = useState("");
    const [requestsPerPage, setRequestsPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [edit, setEdit] = useState(false);
    const [paises, setPaises] = useState([]);

    const [orderBy, setOrderBy] = useState({
        nameColumn: "departamento",
        orderAsc: true
    });

    const selectValues = [
        {value: 10, label: "10"},
        {value: 20, label: "20"},
        {value: 50, label: "50"}
    ];

    const initialData = {
        paisId: "",
        estado: ""
    }

    const [t] = useTranslation("global");
    const [selected, setSelected] = useState(initialData);

    const headingColumns = [
        {key: 'departamento', val: t('administrator.manageStates.state'), width: 3},
        {key: 'pais', val: t('administrator.manageStates.country'), width: 3},
    ];

    const validate = async () => {
        const dataSend = {
            id: selected.estadoId,
            name: selected.estado,
            country_id: selected.paisId
        }
        try {
            validateLocationData('estado', selected, () => {
                showLoader();
                if (dataSend.id) {
                    callUpdateState(dataSend);
                } else {
                    callSaveState(dataSend)
                }
            })
            
        } catch (e) {
            Alert(e.type, e.msg, e.details);
            return false;
        }
    }

    const callUpdateState = dataSend => {
        updateState(dataSend).then(response => {
            hideLoader();
            switch (response.data.status) {
                case 200:
                    Alert("success", t('alerts.success'), response.data.userMessage);
                    setSelected(initialData);
                    setEdit(false);
                    reloadTable();
                    break;
                default:
                    Alert("error", t('alerts.oops'), response.data.userMessage);
                    break;
            }
        }).catch(err => {
            hideLoader();
            throw ({ type: "error", msg: t('alerts.oopsWrong'), details: "" });
        })
    }

    const callSaveState = dataSend => {
        saveState(dataSend).then(response => {
            hideLoader();
            switch (response.data.status) {
                case 200:
                    Alert("success", t('alerts.success'), response.data.userMessage);
                    setSelected(initialData);
                    reloadTable();
                    break;
                default:
                    Alert("error", t('alerts.oops'), response.data.userMessage);
                    break;
            }
        }).catch(err => {
            hideLoader();
            throw ({ type: "error", msg: t('alerts.oopsWrong'), details: "" });
        })
    }

    const handleOnchangeSearch = (event) => {
        setSearchItem(event.target.value);
    }

    const handleOnclickDataHeading = (nameTableColumn) => {
        let orderByVal = nameTableColumn === orderBy.nameColumn ? !orderBy.orderAsc : true;
        setOrderBy({
            nameColumn: nameTableColumn,
            orderAsc: orderByVal
        });   
    }

    const handleOnItemClickListener = (row) => {
        setEdit(true);
        setSelected({
            estadoId: row.departamentoId,
            paisId: row.paisId,
            estado: row.departamento
        });
        window.scrollTo(0 , 0);
    }

    const handleOnchange = (requestPerPage) => {
        setRequestsPerPage(requestPerPage.target.value);
        setCurrentPage(1);
    }

    const handleOnclick = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) setCurrentPage(newPage);
    }

    const reloadTable = () => {
        const filterData = `?keyword=${searchItem}&pageNo=${currentPage - 1}&pageSize=${requestsPerPage}&sortBy=${orderBy.nameColumn}&ascSort=${orderBy.orderAsc}`;
        showLoader();
        getAllStates(filterData).then(response => {
            setTotalPages(response.totalPages);
            setTableData(response.resultList.slice());
            hideLoader();
        }).catch(error => {
            hideLoader();
            setTableData([]);
            Alert("error", t('alerts.oops'), error);
        });
    }

    const separatePastParams = (url) => {
        const search = url.split("=");
        keyword = (search[1].split("&"))[0];
        pageNo = (search[2].split("&"))[0];
        pageSize = (search[3].split("&"))[0];
        sortBy = (search[4].split("&"))[0];
        ascSort = search[5];
        setRequestsPerPage(pageSize);
        setSearchItem(keyword);
        setCurrentPage(parseInt(pageNo));
        setOrderBy({
            nameColumn: sortBy.toString(),
            orderAsc: ascSort
        });
    }

    useEffect(() => {
        showLoader();
        const dataSend = `?keyword=${searchItem}&pageNo=${currentPage - 1}&pageSize=${requestsPerPage}&sortBy=${orderBy.nameColumn}&ascSort=${orderBy.orderAsc}`;
        if(window.location.search==""){
            getAllStates(dataSend).then(response => {
                setTotalPages(response.totalPages);
                setTableData(response.resultList.slice());
                hideLoader();
            }).catch(error => {
                hideLoader();
                setTableData([]);
                Alert("error", t('alerts.oops'), error);
            })
        } else {
            separatePastParams(window.location.search);
        }
    }, [])

    useEffect(() => {
        reloadTable();
    }, [currentPage, requestsPerPage, orderBy, searchItem])

    const actualizarData = (fragment, event) => {
        setSelected({...selected, [fragment]: event.target.value})
    }

    const limpiar = () => {
        setEdit(false);
        setSelected(initialData);
    }

    const actualizarLocation = (location, event) => {
        if (location === "pais") {
            if (event.target.value !== 0 || event.target.value !== '0') {
                const aux = paises.filter(row => {
                    return row.id === event.target.value;
                })[0];
                actualizarData('paisId', event); 
            }
        }
    }

    useEffect(() => {
        getAllCountries().then(res => {
            setPaises(res);
        }).catch(error => {
            console.log("Error", error);
        })
    }, [])

    const getCities = () => {
        const filterData = `?keyword=${selected.estado}&pageNo=1&pageSize=20&sortBy=departamento&ascSort=true`;
        history.push(`/ciudades${filterData}`)
    }

    return (
        <Fragment>
            <InputSearchSet 
                type="text"
                placeholder={t('transversal.search')}
                value={searchItem}
                onChange={handleOnchangeSearch}
            />
            <div className="form__content edit__location">
                <div className="information">
                    <div className="manage_title">
                        <h3>{`${edit ? t('transversal.edit') : t('transversal.create') } ${t('administrator.manageStates.state')}`}</h3>
                        {edit && <Button name={t('administrator.manageStates.seeCities')} onClick={getCities} />}
                    </div>
                    <hr/>
                    <div className="form__input">
                        <Select 
                            onChange={event => actualizarLocation('pais', event)}
                            className="input__state"
                            label={t('administrator.manageStates.country')}
                            data={paises}
                            name="pais"
                            value={selected.paisId}
                            placeholder={t('transversal.selectOption')}
                        />
                        <Text 
                            className="input__state"
                            onChange={event => actualizarData('estado', event)}
                            value={selected.estado}
                            placeholder={t('administrator.manageStates.state')}
                            type="text"
                            disabled={selected.paisId ? false : true}
                            name="estado"
                        />
                    </div>
                    <div className="form__button">
                        <Button name={ t('transversal.clean')} onClick={limpiar} />
                        <Button primary name={edit ?  t('transversal.edit') :  t('transversal.create')} onClick={validate} />
                    </div>
                </div>
            </div>
            <Table 
                tableData={tableData}
                headingColums={headingColumns}
                title={null}
                breakOn='medium'
                orderBy={handleOnclickDataHeading}
                orderColumnName={orderBy.nameColumn}
                orderColumnDirection={orderBy.orderAsc}
                onItemClickListener={handleOnItemClickListener}
            />
            <div className="o-footer__table">
                <MainSelect 
                    options={selectValues}
                    name={`invoicetable`}
                    value={requestsPerPage}
                    onChange={handleOnchange}
                />
                <TablePagination 
                    paginate={(event, newPage) => handleOnclick(newPage)}
                    pageAmount={totalPages}
                    currentPage={currentPage}
                />
            </div>
            {loader}
        </Fragment>
    )
}

export default ManageStates;