import React, { useState, useContext, useEffect } from "react";
import {getClientByName, getClientByNitInit} from "../../../services/clientService";
import { ClientContext } from '../../../provider/cliente';
import TextBlockTwo from "../../molecules/text-blocks/textBlockTwo";
import TextSearchBlockNitCliente from "../../molecules/text-search-block-nit-cliente/textSearchBlockNitCliente";
import TextSearchBlockNombreCliente from "../../molecules/text-search-block-nombre-cliente/textSearchBlockNombreCliente";
import listarRegimenTributario from "../../../services/listarRegimenTributario";
import "./dataClient.scss";
import { isInvoiceRequestDisable} from '../../../utils/utils';
import { getCityById } from "../../../services/getLocationList";
import { useTranslation } from "react-i18next";

let timer;

const DataClient = (Props) => {
  const [data, setData] = useContext(Props.context);
  const { dataClient, setDataClient } = useContext(ClientContext);
  const [regimenTributario, setRegimenTributario] = React.useState([]);
  const [ciudad, setCiudad] = useState("");
  const [dataListClientes, setDataListClientes] = useState([]);
  const [changeClient, setChangeClient] = Props.changeClient;
  const [t] = useTranslation('global');

  useEffect(() => {
    obtenerRegimenTributario();
  }, []);
  
  const actualizarDatos = (e) => {
    setDataClient({
      type: 'AGREGAR',
      payload: { ...dataClient, [e.target.name]: e.target.value }
    });
  };

  const updateData = (e) => {
    setData({
      type: 'AGREGAR',
      payload: { ...data, [e.target.name]: e.target.value }
    });
  };
  const ConsultaClienteByNit = (e) => {
    if (dataClient.clienteId && e.type === "change") {
      resetDataClient();
    } else {
      actualizarDatos(e);
      clearTimeout(timer);
      timer = setTimeout(async () => {
        if(dataClient.numeroDocumento.length > 2){
          const responseData = await getClientByNitInit(dataClient);
          setDataListClientes(responseData);
          if (responseData?.length === 1) {
            setDataClient({
              type: 'AGREGAR',
              payload: responseData[0]
            });
            data.clienteId = responseData[0].clienteId;
          }
        }
      }, 500);
    }
  };

  const ConsultaClienteByName = (e) => {
    if (dataClient.clienteId && e.type === "change") {
      resetDataClient();
    } else {
      actualizarDatos(e);
      clearTimeout(timer);
      timer = setTimeout(async () => {
        if(dataClient.razonSocialRut.length > 2){
          const responseData = await getClientByName(dataClient);
          setDataListClientes(responseData);
          if (responseData?.length === 1) {
            setDataClient({
              type: 'AGREGAR',
              payload: responseData[0]
            });
            data.clienteId = responseData[0].clienteId;
          }
        }
      }, 500);
    }
  };

  function obtenerRegimenTributario() {
    listarRegimenTributario().then((result) => {
      setRegimenTributario(result.data);
    });
  }

  function obtenerCiudadPorId(ciudadId) { 
    getCityById(ciudadId).then(result => {
      setCiudad(result.name)
    }).catch(err => {
      setCiudad("");
    })
  }

  const resetDataClient = () => {
    setDataClient({
      type: 'RESET'
    })
    setData({
      type : "CHANGE-CLIENTE-ID",
      payload : {...data, clienteId:-1}
    })
    setChangeClient(true);
  }

  useEffect(() => {
    if (dataClient.ciudadId) {
      obtenerCiudadPorId(dataClient.ciudadId)
    }
  }, [dataClient])

  return (
    <div className="DataClient">
      <div className="HeaderDataClient">
        <h3>{t('invoices.createInvoice.customerData')}</h3>
      </div>
      <div className={`BodyDataClient ${dataClient.razonSocialRut && dataClient.numeroDocumento ? '' : 'reduce'}`}>
        <div className="primaryDate">
          <div className="primary margin margin__bottom responsiveMargin">
            <TextSearchBlockNombreCliente
              onChange={ConsultaClienteByName}
              name="razonSocialRut"
              onKeyUp={(actualizarDatos, ConsultaClienteByName)}
              data={dataListClientes}
              placeholder={t('invoices.createInvoice.name')}
              value={dataClient.razonSocialRut}
              disabled={ isInvoiceRequestDisable(data) ? "disabled" : ""}
              autoComplete="off"
            />
          </div>
          <div className="primary margin margin__bottom responsiveMargin">
            <TextSearchBlockNitCliente
              onChange={ConsultaClienteByNit}
              title={dataClient.numeroDocumento}
              name="numeroDocumento"
              onKeyUp={(actualizarDatos, ConsultaClienteByNit)}
              data={dataListClientes}
              placeholder={t('invoices.createInvoice.nit')}
              value={dataClient.numeroDocumento}
              digitoVerificacion={dataClient.digitoVerificacion}
              disabled={isInvoiceRequestDisable(data) ? "disabled" : ""}
              autoComplete="off"
            />
          </div>
          <div className={`primary marginRight margin__bottom responsiveMargin ${dataClient.razonSocialRut && dataClient.numeroDocumento ? 'showInput' : 'hideInput'}`}>
            <TextBlockTwo 
              disabled="disabled"
              value={dataClient.clasificacionTributariaId}
              placeholder={t('invoices.createInvoice.taxRegime')}
              type="text"
              name="nombreRegimenTributario"
            />
          </div>

        </div>

        <div className={`primaryDate secondaryDate ${dataClient.razonSocialRut && dataClient.numeroDocumento ? '' : 'hideInput'}`} >
          <div className="secondary margin__bottom responsiveMargin">
            <TextBlockTwo
              disabled="disabled"
              value={dataClient.direccionRut}
              placeholder={t('invoices.createInvoice.address')}
              type="text"
              name="direccion"
            />
          </div>
          {dataClient.ciudadId && <div className="secondary responsiveMargin">
            <TextBlockTwo
              disabled="disabled"
              value={ciudad}
              placeholder={t('invoices.createInvoice.city')}
              type="text"
              name="ciudad"
            />
          </div>}
          <div className="secondary responsiveMargin">
            <TextBlockTwo
                disabled="disabled"
                value={dataClient.telefonoRut}
                placeholder={t('invoices.createInvoice.phone')}
                type="text"
                name="telefono"
            />
          </div>
          <div className="secondary responsiveMargin">
            <TextBlockTwo
              placeholder={`*${t('invoices.createInvoice.contact')}`}
              type="text"
              required=""
              name="contacto"
              onChange={updateData}
              value={data.contacto}
              disabled={isInvoiceRequestDisable(data) ? "disabled" : ""}
            />
          </div>
          <div className="secondary responsiveMargin">
            <TextBlockTwo
              placeholder={`*${t('invoices.createInvoice.job')}`}
              type="text"
              name="cargoContacto"
              onChange={updateData}
              value={data.cargoContacto}
              disabled={isInvoiceRequestDisable(data) ? "disabled" : ""}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataClient;
