import React, { useEffect, useContext, useState, useRef } from "react";
import { useParams, useHistory } from 'react-router-dom';

import GeneralInformation from '../../templates/informacion/generalInformation';
import Observaciones from '../../templates/observaciones/observaciones';
import CheckboxContainer from '../../molecules/checkbox/checkbox-container';
import Wrapper from '../../templates/wrapper/Wrapper';
import Aside from '../../templates/aside/aside';
import { Alert, ConfirmationAlert } from '../../molecules/alert/alert';
import RelationsPolicy from "../../organism/policy-relationships/policyRelations";

import { toBase64, validatePolicyFields, formatDate } from '../../../utils/utils';
import { POLICY_ID, STATUS_ID } from '../../../utils/constans';
import { ROLES } from '../../../config/roles'

import { getPolicyTypes, getPolicyById, createPolicyRequest, updatePolicy } from '../../../services/policyService';
import { getClientByID } from '../../../services/clientService';
import useLoader from '../../../utils/useLoader';
import { PolicyContext } from '../../../provider/policy';
import { ClientContext } from '../../../provider/cliente';
import { AppContextUser } from '../../../provider/userInSession';
import { useTranslation } from "react-i18next";

function PolicyRequest(props) {
  const [t] = useTranslation('global');

  const limitFiles = 5;
  const SCROLLIDOBSERVATIONS = 4;
  const text = `${t('policy.requestPolicy.specifyPageGuarantees')},`;
  const textStrong = `${t('policy.requestPolicy.eitherPurchaseOrder')}.`;
  const initialText = t('components.inputFile.dropDocumentsSelectFile');
  let date = new Date();
  const stringDate = JSON.stringify(date);
  let currentYear = stringDate.substring(1, 5);
  let currentMonth = stringDate.substring(6, 8);
  let currentDay = stringDate.substring(9, 11);
  let fullDeadlineDateMin = currentYear + '-' + currentMonth + '-' + currentDay;
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const stringLastDay = JSON.stringify(lastDay)
  let numberOfLastDay = stringLastDay.substring(9, 11)
  let fullDeadlineDateMax = currentYear + '-' + currentMonth + '-' + numberOfLastDay;

  const { setDataClient } = useContext(ClientContext);
  const [dataPolicy, setDataPolicy] = useContext(PolicyContext);
  const { dataUser } = useContext(AppContextUser);
  const { idPolicy } = useParams();
  const [policyTypes, setPolicyTypes] = useState([]);
  const [status, setStatus] = useState(false);
  const changeClientState = useState(false);

  const [message, setMessage] = useState(initialText);
  const history = useHistory();
  const [loader, showLoader, hideLoader] = useLoader();
  const observationRef = useRef(null);
  const executeScroll = () => observationRef.current.scrollIntoView();

  const onChangeText = event => {
    const otherPolicy = dataPolicy.tiposPolizasSolicitud.find(element => element.tipoPolizaId === POLICY_ID.OTRA);
    otherPolicy.descripcionOtra = event.target.value;
    setDataPolicy({
      action: "AGREGAR",
      payload: { ...dataPolicy }
    });
  }

  const getDataClient = async clientId => {
    const data = { clienteId: clientId };
    const client = await getClientByID(data);
    setDataClient({
      type: 'AGREGAR',
      payload: { ...client }
    });
  }

  const getPolicyStatusName = (statusId) => {
    return Object.keys(STATUS_ID).find(key => STATUS_ID[key] === statusId);
  }

  const updateDate = e => {
    if (e.type) {
      dataPolicy.fechaLimiteEntrega = e.target.value;
      setDataPolicy({
        type: 'AGREGAR',
        payload: { fechaLimiteEntrega: dataPolicy.fechaLimiteEntrega }
      });
    } else {
      switch (e.tag) {
        case 'fechaSolicitud':
          dataPolicy.fechaSolicitud = e.value;
          break;
        case 'fechaLimiteEntrega':
          dataPolicy.fechaLimiteEntrega = e.value;
          break;
        default:
          console.error("No se puede manejar este tipo de fecha", e);
          break;
      }
      setDataPolicy({
        type: 'AGREGAR',
        payload: { ...dataPolicy }
      });
    }

    setPolicyInformation([
      {
        tag: `${t('policy.requestPolicy.applicationDate')}: `,
        value: formatDate(dataPolicy.fechaSolicitud, "DD/MM/AAAA"),
        type: 'orange'
      },
      {
        tag: `${t('policy.requestPolicy.deliveryDeadline')}: `,
        value: formatDate(dataPolicy.fechaLimiteEntrega, "DATE"),
        type: "date",
        callback: updateDate,
        min: fullDeadlineDateMin,
        max: fullDeadlineDateMax
      }
    ])
  }

  const INITIAL_INFORMATION = [
    {
      tag: `${t('policy.requestPolicy.applicationDate')}: `,
      value: formatDate(dataPolicy.fechaSolicitud, "DD/MM/AAAA"),
      type: 'orange'
    },
    {
      tag: `${t('policy.requestPolicy.deliveryDeadline')}: `,
      value: formatDate(dataPolicy.fechaLimiteEntrega, "DATE"),
      type: "date", callback: updateDate,
      min: fullDeadlineDateMin,
      max: fullDeadlineDateMax
    }
  ];

  const [policyInformation, setPolicyInformation] = useState(INITIAL_INFORMATION);
  React.useEffect(() => {
    const _policyInformation = [...policyInformation];
    if (_policyInformation[2]) {
      _policyInformation[0].tag = `${t('policy.requestPolicy.requestedBy')}:`;
      _policyInformation[1].tag = `${t('policy.requestPolicy.state')}:`;
      _policyInformation[2].tag = `${t('policy.requestPolicy.noRequest')}:`;
      _policyInformation[3].tag = `${t('policy.requestPolicy.applicationDate')}: `;
      _policyInformation[4].tag = `${t('policy.requestPolicy.deliveryDeadline')}: `;
    } else {
      _policyInformation[0].tag = `${t('policy.requestPolicy.applicationDate')}: `;
      _policyInformation[1].tag = `${t('policy.requestPolicy.deliveryDeadline')}: `;
    }
    setPolicyInformation(_policyInformation);
  }, [t]);

  const getData = async () => {
    if (idPolicy) {
      const data = { polSolicitudId: idPolicy }
      showLoader();
      await getPolicyById(data).then(response => {
        hideLoader();
        if (response.data.status === 200) {
          setDataPolicy({
            type: 'AGREGAR',
            payload: response.data.data
          });
          getDataClient(response.data.data.clienteId);
          const policyDataToShow = [
            { tag: `${t('policy.requestPolicy.requestedBy')}:`, value: response.data.data.usuarioEmail },
            { tag: `${t('policy.requestPolicy.state')}:`, value: getPolicyStatusName(response.data.data.estadoId) },
            { tag: `${t('policy.requestPolicy.noRequest')}:`, value: response.data.data.polSolicitudId },
            { tag: `${t('policy.requestPolicy.applicationDate')}: `, value: formatDate(response.data.data.fechaSolicitud, "DD/MM/AAAA") },
            { tag: `${t('policy.requestPolicy.deliveryDeadline')}: `, value: formatDate(response.data.data.fechaLimiteEntrega, "DD/MM/AAAA"), type: "orange" },
          ];
          setPolicyInformation(policyDataToShow);
          //SCROLLIDOBSERVATIONS references the id state to scroll to observations
          if (response.data.data.estadoId === SCROLLIDOBSERVATIONS) {
            focusObservations();
          }
        } else {
          Alert("error", t('alerts.alert'), response.data.userMessage);
          response.data.status === 403 ? history.push('/') : history.push('/poliza');
        }
      }).catch(err => {
        hideLoader();
        Alert("error", t('alerts.error'), err);
      });
    } else {
      setDataPolicy({
        type: 'INITIAL_STATE',
      });
      setDataClient({
        type: 'RESET',
      });
    }
  };

  const setFileOnProvider = async (files, key, fileCategory) => {
    const filesInBase64 = [];
    if (files.length) {
      files.map(file => {
        if (!file.archivoId)
          toBase64(file, fileCategory, otherFile => { filesInBase64.push(otherFile) })
      });
    }
    if (key !== 0)
      dataPolicy.tiposPolizasSolicitud.map(element => {
        if (element.tipoPolizaId === key) {
          element.files = filesInBase64;
          setDataPolicy({
            type: 'ADD_FILE',
            payload: { ...dataPolicy }
          });
        }
      });
  }

  const requestPolicy = async () => {
    try {
      validatePolicyFields(dataPolicy, () => {
        // delete dataPolicy.reset;
        showLoader();
        createPolicyRequest(dataPolicy)
          .then(response => {
            hideLoader();
            switch (response.data.status) {
              case 200:
                Alert("success", t('alerts.success'), response.data.userMessage)
                setDataPolicy({
                  type: 'RESET'
                });
                setDataClient({
                  type: 'RESET'
                });
                break;
              default:
                Alert("error", t('alerts.oops'), response.data.userMessage);
                break;
            }
          })
          .catch(err => {
            hideLoader();
            throw ({ type: "error", msg: "Ups, algo ha salido mal", details: "" });
          });
      });
    } catch (e) {
      Alert(e.type, e.msg, e.details);
      return false;
    }
  };

  function focusObservations() {
    executeScroll();
  }

  const approvePolicyRequest = async () => {
    showLoader();
    const policyData = await {
      ...dataPolicy,
      estadoId: STATUS_ID.APROBADA
    };
    try {
      validatePolicyFields(dataPolicy, () => {
        updatePolicy(policyData)
          .then(response => {
            switch (response.data.status) {
              case 200:
                Alert("success", t('alerts.success'), response.data.userMessage)
                history.push("/polizas-pendientes");
                break;
              default:
                Alert("error", t('alerts.oops'), response.data.userMessage);
                break;
            }
            hideLoader();
          })
          .catch(err => {
            hideLoader();
            throw ({ type: "error", msg: "Ups, algo ha salido mal", details: "" });
          });
      });
    } catch (e) {
      hideLoader();
      Alert(e.type, e.msg, e.details);
      return false;
    }
  };

  const updateTypePolicies = (event) => {
    if (event.target.checked) {
      if (parseInt(event.target.id) === POLICY_ID.OTRA)
        setStatus(true);
      dataPolicy.tiposPolizasSolicitud.push({
        tipoPolizaId: parseInt(event.target.id),
        descripcionOtra: ""
      });

    } else {
      if (parseInt(event.target.id) === POLICY_ID.OTRA)
        setStatus(false);
      dataPolicy.tiposPolizasSolicitud = dataPolicy.tiposPolizasSolicitud.filter(policy => policy.tipoPolizaId !== parseInt(event.target.id));
    }
    setDataPolicy({
      action: "AGREGAR",
      payload: { ...dataPolicy }
    });
  }

  const clearInfo = () => {
    ConfirmationAlert(
      "warning",
      t('alerts.clearAllData'),
      "",
      t('transversal.yes'),
      t('transversal.no'),
      () => {
        setDataPolicy({
          type: 'INITIAL_STATE',
        });
        setDataClient({
          type: 'RESET',
        });
      })
  }

  const shouldShowButton = () => {
    if (dataPolicy.estadoId === STATUS_ID.APROBADA && (dataUser.rol === ROLES.SOLICITANTE || dataUser.rol === ROLES.SUPERVISOR)) return false;
    if (dataPolicy.estadoId === STATUS_ID.APROBADA) return false;
    if (dataPolicy.polSolicitudId && STATUS_ID.PENDIENTE && (dataUser.rol === ROLES.SOLICITANTE || dataUser.rol === ROLES.SUPERVISOR)) return false;
    return true;
  }

  const shouldDisableFields = () => {
    if (dataPolicy.estadoId === STATUS_ID.APROBADA) return true;
    if (dataPolicy.polSolicitudId && (dataUser.rol === ROLES.SOLICITANTE || dataUser.rol === ROLES.SUPERVISOR)) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    if (status && !dataPolicy.tiposPolizasSolicitud.length)
      setStatus(false);

    if (dataPolicy.polSolicitudId && (dataUser.rol === ROLES.SOLICITANTE || dataUser.rol === ROLES.SUPERVISOR))
      setMessage(t('alerts.actionNotAllowed'));

    if (dataPolicy.polSolicitudId && dataPolicy.estadoId === STATUS_ID.APROBADA)
      setMessage("");
  }, [dataPolicy]);

  const buttons = [
    {
      name: t('transversal.clean'),
      primary: false,
      onClick: clearInfo,
      hide: dataPolicy.polSolicitudId ? true : false
    },
    {
      name: dataPolicy.polSolicitudId ? t('transversal.approve') : t('transversal.request'),
      primary: true,
      onClick: dataPolicy.polSolicitudId ? approvePolicyRequest : requestPolicy
    }
  ];

  useEffect(() => {
    getPolicyTypes().then(response => {
      setPolicyTypes(response);
    });

    getData();
    return () => { };
  }, []);

  const [blur, setBlur] = useState(false);

  return (
    <Wrapper aside={<Aside blur={blur} setBlur={setBlur} />} blur={blur}>
      <form>
        <GeneralInformation
          policy
          limitFiles={limitFiles}
          context={PolicyContext}
          show={dataPolicy.polSolicitudId}
          dataToShow={policyInformation}
          changeClient={changeClientState}
        />

        <CheckboxContainer handleChange={updateTypePolicies} handleChangeText={onChangeText} handleFileChange={setFileOnProvider}
          showOtherPolicyInput={status} message={message}
          data={dataPolicy.polSolicitudId ? dataPolicy.tiposPolizasSolicitud : policyTypes}
          componentType={dataPolicy.polSolicitudId ? "inputFileGeneric" : "checkbox"}
          checked={dataPolicy.reset ? false : undefined}
          disabledFields={shouldDisableFields()} />

        <RelationsPolicy context={PolicyContext} isEditing={dataPolicy.polSolicitudId} changeClientState={changeClientState} />

        <div ref={observationRef}>
          <Observaciones handleEvent={dataPolicy.polSolicitudId ? approvePolicyRequest : requestPolicy}
            context={PolicyContext} policy text={text} textStrong={textStrong}
            buttonText={dataPolicy.polSolicitudId ? t('transversal.approve') : t('transversal.request')}
            showButton={shouldShowButton()}
            buttons={buttons} />
        </div>
        {loader}
      </form>
    </Wrapper>
  );
}

export default PolicyRequest;