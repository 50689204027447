import React, { useState } from 'react';
import Proptypes from 'prop-types';
import Text from '../../atoms/text/text';
import Checkbox from '../../atoms/checkbox/checkbox';
import InputFileGeneric from '../../molecules/input-file-generic/inputFileGeneric';
import { POLICY_ID } from '../../../utils/constans';
import SpanGray from '../../atoms/text/spanGray';

import './checkbox-container.scss';
import { useTranslation } from 'react-i18next';


const CheckboxContainer = ({ handleChange, handleChangeText, handleFileChange, checked, verticalMode, data,
    showOtherPolicyInput, componentType, manageFiles, disabledFields, message }) => {

    const [t] = useTranslation('global');
    const [otherPolicyValue, setOtherPolicyValue] = useState('');
    const onChangeText = (e) => {  
        setOtherPolicyValue(e.target.value)
        handleChangeText(e);
    };

    data.forEach(element => {
        switch (true) {
            case element.descripcion.includes('Calidad del servicio'):
                element.t = 'policy.requestPolicy.qualityService';
                break;
            case element.descripcion.includes('Cumplimiento del contrato'):
                element.t = 'policy.requestPolicy.contractPerformance';
                break;
            case element.descripcion.includes('Manejo del anticipo'):
                element.t = 'policy.requestPolicy.managementAdvance';
                break;
            case element.descripcion.includes('Pago de salarios y prestaciones sociales'):
                element.t = 'policy.requestPolicy.paymentWagesSocialBenefits';
                break;
            case element.descripcion.includes('Responsabilidad civil'):
                element.t = 'policy.requestPolicy.civilLiability';
                break;
            case element.descripcion.includes('Responsabilidad civil extracontractual'):
                element.t = 'policy.requestPolicy.nonContractualCivilLiability';
                break;
            case element.descripcion.includes('Seriedad de la oferta'):
                element.t = 'policy.requestPolicy.seriousnessOffer';
                break;
            case element.descripcion.includes('Otra'):
                element.t = 'policy.requestPolicy.other';
                break;
            default:
                break;
        }
        if (element.t) element.descripcion = t(element.t);
    });

    React.useEffect(() => {
        data.forEach(element => element.descripcion = element.t ? t(element.t) : element.descripcion);
    }, [t]);

    const setComponent = (code, index) => {
        switch (componentType) {
            case "checkbox":
                return (
                    <Checkbox
                        key= {index}
                        name={code.tipoPolizaId}
                        label={code.descripcion}
                        handleChange={handleChange}
                        checked={checked}
                        verticalMode={verticalMode}
                    />
                );
            case "inputFileGeneric":
                return (
                    <div key={index} className="m-div-table-row">
                        <div className="m-div-table-row__col">
                            {code.tipoPolizaId === POLICY_ID.OTRA ?
                                <SpanGray MostrarValor={`${code.descripcion} - ${code.descripcionOtra}`} />
                                : <SpanGray MostrarValor={code.descripcion} />
                            }
                        </div>
                        <div className="m-div-table-row__col--box">
                            <InputFileGeneric
                                status={'upload'}
                                title={code.descripcion}
                                name={code.descripcion}
                                code={code.tipoPolizaId}
                                handleFileChange={handleFileChange}
                                category={code.descripcion}
                                data={code.files}
                                manageFiles={manageFiles}
                                disabled={disabledFields}
                                message={message}
                                multiple={false}
                                limitFiles={1}
                            />
                        </div>
                    </div>
                );
            default:
                console.warn("No hay lógica para este componente", componentType);
        }
    }

    return (
        <div className="form">
            <div className="HeaderBody">
                <h3>{t('policy.title')}</h3>
            </div>
            <hr />
            <div className={componentType === "checkbox" ? "horizontal" : "m-div-table"}>
                {data.map((code, index) => (
                    setComponent(code, index)
                ))}
                {showOtherPolicyInput ? <Text Type="text" id="text-otro" maxLength="40" onChange={onChangeText}
                    placeholder={t('policy.requestPolicy.otherPolicy')} value={otherPolicyValue} className={"horizontal_input_text"}
                /> : <i></i>}
            </div>
        </div>
    )
}

CheckboxContainer.propTypes = {
    handleChange: Proptypes.func.isRequired,
    checked: Proptypes.bool,
    verticalMode: Proptypes.bool,
    data: Proptypes.arrayOf(Proptypes.shape({ code: Proptypes.string, name: Proptypes.string }))
}

export default CheckboxContainer;