import React from 'react';
import HeaderWrapper from '../../molecules/header-wrapper/HeaderWrapper';
import factura from '../../../images/factura.svg';
import { useTranslation } from 'react-i18next';

const HeaderInvoiceState = (props) => {
    const [t] = useTranslation('global');
    let mainTitle = t('invoices.historicInvoice.title');

    return (
        <HeaderWrapper
            mainTitle={mainTitle}
            image={factura}
        />
    )
}

export default HeaderInvoiceState;
