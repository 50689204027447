import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import AsideLink from '../../atoms/nav-link/asideLink';

import './submenu.scss';


const SubMenu = ({ item, activeLink, setActiveLink }) => {
  
  const showSubnav = () => item.title !== activeLink ? setActiveLink(item.title) : setActiveLink('');
  
  useEffect(() => {
    var activeSubmenu = item.subNav.find(nav => nav.path === window.location.pathname);
    if(activeSubmenu !== undefined){
      showSubnav();
    }
  }, []);

  return (
    <>        
        <NavLink to={item.path} onClick={showSubnav}>
            <div className="sidebarLink">
                <span>
                    {item.title}
                </span>
                
                <div className="sidebarLink__iconOpenClose">                  
                  <img src={
                    item.subNav && activeLink === item.title
                    ? item.iconOpened
                    : item.subNav
                    ? item.iconClosed
                    : null
                  }/>
                </div>
            </div>
        </NavLink>
        <div className={`subNav ${activeLink === item.title ? 'subNavActive' : ''}`}>
      {activeLink === item.title &&
        item.subNav.map((itemSubNav, index) => {
          return (
            <NavLink key={index} exact activeClassName="active" to={itemSubNav.path}>
                <AsideLink key={index} texto={itemSubNav.title} urlImage={itemSubNav.icon} />
            </NavLink>
          );
        })}
        </div>
    </>
  );
};

export default SubMenu;
