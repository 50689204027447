import React, { useState, useEffect, useContext } from 'react';

import MainSelect from '../../atoms/main-select/MainSelect';
import Table from '../../molecules/table/Table';
import { orderListrequest } from '../../../utils/utils';
import TablePagination from '../../molecules/table-pagination/TablePagination';
import InputSearchSet from '../../molecules/input-search-set/InputSearchSet';
import { useHistory } from "react-router-dom";
import { getInvoiceData } from '../../../services/invoiceService';
import { AppContextUser } from '../../../provider/userInSession';
import { Alert } from '../../molecules/alert/alert';
import useLoader from '../../../utils/useLoader';
import './InvoiceStateTable.scss';
import Toolkit from "../../atoms/toolkit/Toolkit";
import { useTranslation } from 'react-i18next';

const InvoiceStateTable = (props) => {
    let filtro, page, requests,columnSearched, orderSearched;
    const history = useHistory();
    const [t] = useTranslation('global');
    const [currentPage, setCurrentPage] = useState(1);
    const { dataUser, setDataUser } = useContext(AppContextUser);
    const [dataListInvoice, setDataListInvoice] = useState([]);
    const [loader, showLoader, hideLoader] = useLoader();
    const headingColumns = [
        { key: 'facSolicitudId', val: t('invoices.pendingInvoice.requestNumber'), width: 6 },
        { key: 'usuarioEmail', val: t('invoices.pendingInvoice.user'), width: 11 },
        { key: 'fechaSolicitud', val: t('invoices.pendingInvoice.requestDate'), width: 8 },
        { key: 'fechaLimiteEntrega', val: t('invoices.pendingInvoice.deadline'), width: 8 },
        { key: 'numeroDocumento', val: t('invoices.pendingInvoice.nit'), width: 9, type: "text", renderAs: ["$1%-$2%", "numeroDocumento", "digitoVerificacion"] },
        { key: 'nombreCliente', val: t('invoices.pendingInvoice.customer'), width: 14},
        { key: 'valorTotal', val: t('invoices.pendingInvoice.amount'), width: 10, align: "right", type: "currency", currencyTypeFormatter: "monedaId" },
        { key: 'estado', val: t('invoices.pendingInvoice.state'), width: 8, textTransform: "capitalize" },
        { key: 'consecutivo', val: t('invoices.pendingInvoice.consecutive'), width: 9 },
        { key: 'fechaFacturacion', val: t('invoices.pendingInvoice.billingDate'), width: 8 },
        { key: 'filialName', val: t('invoices.pendingInvoice.subsidiary'), width: 9},
    ];

    const [orderBy, setorderBy] = useState({
        nameColumn: 'fechaSolicitud',
        orderAsc: false
    });
    const [pageAmount, setPageAmount] = useState(1);
    const [requestsPerPage, setRequestsPerPage] = useState(20);
    const [searchItem, setSearchItem] = useState("");
    const selectValues = [
        { value: 10, label: "10" },
        { value: 20, label: "20" },
        { value: 50, label: "50" }
    ];
    const [estados, setEstados] = useState([]);
    const [estadosPropios, setEstadosPropios] = useState([]);

    const handleOnclick = (newPage) => {
        if (newPage >= 1 && newPage <= pageAmount) setCurrentPage(newPage);
    }

    const handleOnclickDataHeading = (nameTableColumn) => {

        let orderByVal = nameTableColumn === orderBy.nameColumn ? !orderBy.orderAsc : true;
        setorderBy({
            nameColumn: nameTableColumn,
            orderAsc: orderByVal
        });
    };

    const handleOnchange = (requestPerPage) => {
        setRequestsPerPage(requestPerPage.target.value)
    };

    const handleOnChangeSearch = (event) => {
        setSearchItem(event.target.value)
    };

    useEffect(() => {
        if (dataUser.rol === "Solicitante" || dataUser.rol === "Supervisor") {
            if (!estados.length)
                setEstados(["ANULADA", "APROBADA", 'CANCELADA']);
        } else {
            if (!estados.length){
                setEstados(["ANULADA", "APROBADA"]);
                setEstadosPropios(['CANCELADA']);
            }
        }

        if (estados.length > 0) {
            const dataSend = {
                usuarioId: dataUser.userId,
                lista: [{}],
                estados: estados,
                estadosPropios: estadosPropios,
                nombreRol: dataUser.rol,
                numeroPagina: (currentPage - 1),
                peticionesPorPagina: requestsPerPage,
                busqueda: searchItem,
                nombreColumna: orderBy.nameColumn,
                orden: orderBy.orderAsc
            }

            if (dataSend.nombreRol) {
                const response = getInvoiceData(dataSend);
                // showLoader();
                response.then(resp => {
                    /* console.log(dataUser);
                    console.log(resp); */
                    hideLoader();
                    if (resp.status && resp.status === 200) {
                        setPageAmount(resp.data.cantidadPaginasTotales);
                        let list = findFilialName(resp.data.lista);
                        setDataListInvoice(list);
                    } else {
                        Alert("error", t('alerts.oops'), resp.userMessage);
                        setDataListInvoice([]);
                    }
                }).catch(err => {
                    // hideLoader();
                    console.log(err)
                });
            }
        }
    },
        [
            currentPage,
            requestsPerPage,
            dataUser,
            orderBy,
            searchItem, estados
        ]
    );

    const findFilialName = (listInvoices) => {
        listInvoices.forEach(item => {
            let filialId = +item.filialId;
            let filial =  props.filiales.find(filial => filial.code === filialId );
            item['filialName'] = filial.name;
        })
      return listInvoices
       
  }

    const handleOnItemClickListener = (row) => {
        const url = `?filtro=${searchItem}&page=${currentPage}&requests=${requestsPerPage}&orderby=${orderBy.nameColumn}&order=${orderBy.orderAsc}`;
        window.history.replaceState(null, null, url);
        separatePastParams(url);
        history.push("/factura/" + row.facSolicitudId);
    };

    useEffect(() => {
        if(!window.location.search==""){
            separatePastParams(window.location.search);
        }
    },[])

    const separatePastParams = (url) => {
        const search = url.split("=");
        filtro = (search[1].split("&"))[0];
        page = (search[2].split("&"))[0];
        requests = (search[3].split("&"))[0];
        columnSearched = (search[4].split("&"))[0];
        orderSearched = search[5];
        setRequestsPerPage(requests);
        setSearchItem(filtro);
        setCurrentPage(parseInt(page));
        setorderBy({
            nameColumn: columnSearched.toString(),
            orderAsc: orderSearched === "true"
        });
    }

    return (
        <>
            <Toolkit>
                <InputSearchSet
                    type="text"
                    placeholder={t('transversal.search')}
                    value={searchItem}
                    onChange={handleOnChangeSearch}
                />
            </Toolkit>
            <Table
                tableData={dataListInvoice}
                headingColums={headingColumns}
                title={null}
                breakOn='medium'
                orderBy={handleOnclickDataHeading}
                orderColumnName={orderBy.nameColumn}
                orderColumnDirection={orderBy.orderAsc}
                onItemClickListener={handleOnItemClickListener}
            />
            <div className="o-footer__table">
                <MainSelect
                    options={selectValues}
                    name={`invoicetable`}
                    value={requestsPerPage}
                    onChange={handleOnchange}
                />
                <TablePagination
                    paginate={(e, newPage) => handleOnclick(newPage)}
                    pageAmount={pageAmount}
                    currentPage={currentPage}
                />
            </div>
            {loader}
        </>
    );
};

export default InvoiceStateTable;
