import './requestData.scss';
import Dates from '../../atoms/text/dates';
import SpanGray from '../../atoms/text/spanGray';
import SpanOrange from '../../atoms/text/spanOrange';

const RequestData = (props) => {

    const setComponent = (code) => {
        switch (code.type) {
            case "orange":
                return (<SpanOrange MostrarValor={code.value} date={props.date}></SpanOrange>)
            case "date":
                return (<Dates Min={code.min} Values={code.value} Max={code.max} onChange={e => { code.callback(e) }} />)
            default:
                return (
                    <span className={`m-requestContainer_span${props.date ? '-date' : ''}--text`}>
                        {code.value}
                    </span>
                )
        }
    }

    return (
        <div className={`m-requestContainer ${props.date ? '' : 'approve'}`}>
            {
                props.data.map((element, index) => {
                    return (
                        <div key={index} className={`m-requestContainer_span${props.date ? '-date' : ''}`}>
                            <SpanGray key={index} MostrarValor={element.tag} />
                            {setComponent(element)}
                        </div>
                    )
                })
            }
        </div>
    )
}

export default RequestData;