import React, { Fragment } from "react";
import "./CheckboxSwitch.scss"

const CheckboxSwitch = ({checked, label, disabledText, enabledText, onChange, name}) => {


    const TEXTO_NEGRITA = () => {
        if(checked){
            return <label> <b>{enabledText}</b><span className="disabled__text">/{disabledText}</span></label>;
        }else return <label> <span className="disabled__text">{enabledText}/</span><b>{disabledText}</b></label>;
    }

    return(
        <Fragment>
            <div>
                {TEXTO_NEGRITA()}
                <label className="switch">
                    <input type="checkbox" checked={checked} name={name} onChange={onChange}/>
                    <span className="slider round"></span>
                </label>

            </div>
        </Fragment>
    );
} 

export default CheckboxSwitch;