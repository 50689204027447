import React from 'react';
import HeaderWrapper from '../../molecules/header-wrapper/HeaderWrapper';

const HeaderInvoiceRequest = (props) => {
    let mainTitle = props.title;

    return (
        <HeaderWrapper
            mainTitle={mainTitle}
        />
    )
}

export default HeaderInvoiceRequest;
