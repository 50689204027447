import { useTranslation } from "react-i18next"
import './translation-language.scss';
import IcoLanguage from '../../../images/languageIcon.svg'
import IcoCheck from "../../../images/selectedLanguage.svg";
import IcoCheckLight from "../../../images/selectedLanguage-light.svg";
import TranslationComponentConstants from "../../../constants/translation-language-constants";
import { useState } from "react";

const Translation = () => {
    const constants = TranslationComponentConstants;

    const [t, i18n] = useTranslation(constants.USE_TRANSLATION_GLOBAL_VALUE);
    const [isHovered, setIsHovered] = useState([false, false]);
    const [languageModal, setLanguageModal] = useState(false);
    const [languageSelected, setLanguageSelected] = useState(localStorage.getItem(constants.LOCAL_STORAGE_TRANSLATION_ITEM));

    const convertTranslate = (langToChange) => {
        setLanguageSelected(langToChange);
        i18n.changeLanguage(langToChange);
        localStorage.setItem(constants.LOCAL_STORAGE_TRANSLATION_ITEM, langToChange);
    }

    return (
        <section className="language">
            <button className='language__button' onClick={() => setLanguageModal(!languageModal)}>
                <img className="language__button-icon" src={IcoLanguage} alt={constants.ALT_LANGUAGE_ICON} />
            </button>
            {languageModal && <div className='language__selector'>
                <button className='language__selector-spanish' onClick={() => convertTranslate(constants.LANGUAGE_SPANISH_MODE)}
                    onMouseEnter={() => setIsHovered([true, false])} onMouseLeave={() => setIsHovered([false, false])}>
                    <span>{t('translation.spanish')}</span> {languageSelected === constants.LANGUAGE_SPANISH_MODE &&
                        <img className="language__selector-icon" src={isHovered[constants.SPANISH_HOVERED_POSITION] ? IcoCheckLight : IcoCheck} alt={constants.ALT_SELECTED_LANGUAGE_ICON} />}
                </button>
                <button className='language__selector-english' onClick={() => convertTranslate(constants.LANGUAGE_ENGLISH_MODE)}
                    onMouseEnter={() => setIsHovered([false, true])} onMouseLeave={() => setIsHovered([false, false])}>
                    <span>{t('translation.english')}</span> {languageSelected === constants.LANGUAGE_ENGLISH_MODE &&
                        <img className="language__selector-icon" src={isHovered[constants.ENGLISH_HOVERED_POSITION] ? IcoCheckLight : IcoCheck} alt={constants.ALT_SELECTED_LANGUAGE_ICON} />}
                </button>
            </div>}
        </section>
    );
}

export default Translation;