import Images from '../../atoms/images/images'
import factura from '../../../images/factura.svg'

import './HeaderWrapper.scss'
//const Table = ({tableData, headingColums , title, breakOn = 'medium'}) => {
const HeaderWrapper = ({image,mainTitle,secondaryTitle,...props}) => {
    let secondaryClass = 'Secondary';
    let hideSecondary = (secondaryTitle)? secondaryClass :'hideElement';

    return ( 
        <div className="Header">
            <div className="Primary">
                <div className="TitleContent">
                    <h2>{mainTitle}</h2>
                </div>
            </div>
            <div className={hideSecondary}>
                <h4>{secondaryTitle}</h4>
            </div>
        </div>
    )
}

export default HeaderWrapper;
