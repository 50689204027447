import React, { Fragment, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../../provider/user";
import { updateUser } from "../../../services/userService";
import { BusinessService } from "../../../services/listarNegociosService";
import { getRoles } from "../../../services/rolesService";
import useLoader from "../../../utils/useLoader";
import { validateUserFields } from "../../../utils/utils";
import Button from "../../atoms/buttons/button";
import Text from "../../atoms/text/text";
import { Alert } from "../../molecules/alert/alert";
import FinderSelector from "../finder-selector/finderSelector";
import "./userForm.scss";
import Select from "../../atoms/select/select";
import { getFiliales } from "../../../services/parametersService";
import { useTranslation } from "react-i18next";

function UserForm() {
  const ROL_ID_SOLICITANTE = 4;
  const { dataUser, setDataUser } = useContext(UserContext);
  const [allBusiness, setAllBusiness] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [allFiliales, setAllFiliales] = useState([]);
  const [loader, showLoader, hideLoader] = useLoader();
  const history = useHistory();
  const [t] = useTranslation('global');

  useEffect(() => {
    getAllBusiness();
    getAllRoles();
    getAllFiliales();
  }, []);

  const getAllBusiness = async () => {
    await BusinessService()
      .then((businessResponse) => {
        setAllBusiness(businessResponse);
      })
      .catch((error) => {
        setAllBusiness([]);
        Alert("error", t('alerts.oops'), error);
      });
  };

  const getAllRoles = async () => {
    await getRoles()
      .then((rolesResponse) => {
        setAllRoles(rolesResponse);
      })
      .catch((error) => {
        setAllRoles([]);
        Alert("error", t('alerts.oops'), error);
      });
  };

  const getAllFiliales = async () => {
    await getFiliales()
      .then((filialesResponse) => {
        setAllFiliales(filialesResponse);
      })
      .catch((error) => {
        setAllFiliales([]);
      });
  }

  const removeBusinessSelected = (itemId) => {
    let removeBusiness = dataUser.negocios.filter((x) => {
      return x.code !== itemId;
    });
    setDataUser({
      type: "AGREGAR",
      payload: { ...dataUser, negocios: removeBusiness },
    });
  };

  const removeFilialSelected = (itemId) => {
    let removeFilial = dataUser.filiales.filter((x) => {
      return x.code !== itemId;
    });
    setDataUser({
      type: "AGREGAR",
      payload: { ...dataUser, filiales: removeFilial },
    });
  }

  const handleOnChangeBusiness = (event) => {
    let codeBusinessSelected = event.target.value;
    let existBusiness = validateBusinessExistInUser(codeBusinessSelected)
    if (!existBusiness) {
      let newBusiness = findBusinessSelected(codeBusinessSelected);
      dataUser.negocios.push(newBusiness);
      setDataUser({
        type: "AGREGAR",
        payload: { ...dataUser, negocios: [...dataUser.negocios] },
      });
    }
  };

  const findBusinessSelected = (code) => {
    return allBusiness.find((b) => b.code === code);
  };

  const validateBusinessExistInUser = (codeBusiness) => {
    return dataUser.negocios.find(
      (item) => item.code === codeBusiness
    );
  }

  const handleOnChangeFilial = (event) => {
    let codeFilialSelected = +event.target.value;
    let existFilial = validateFilialExistInUser(codeFilialSelected)
    if (!existFilial) {
      let newFilial = findFilialSelected(codeFilialSelected);
      dataUser.filiales.push(newFilial);
      setDataUser({
        type: "AGREGAR",
        payload: { ...dataUser, filiales: [...dataUser.filiales] },
      });
    }
  };

  const findFilialSelected = (code) => {
    return allFiliales.find((b) => b.code == code);
  };

  const validateFilialExistInUser = (codeFilial) => {
    return dataUser.filiales.find(
      (item) => item.code === codeFilial
    );
  }

  function handleOnChangeRole(e) {
    let roleId = +e.target.value
    setDataUser({
      type: "AGREGAR",
      payload: { ...dataUser, roleId},
    });
  }

  function handleOnChangeCodeSystem(e) {
    let codigoSistema = e.target.value
    setDataUser({
      type: "AGREGAR",
      payload: { ...dataUser, codigoSistema},
    });
  }

  const getCodigoSistema = () => {
    return dataUser.codigoSistema ? dataUser.codigoSistema : "";
  }

  const handleSubmit = async () => {
    showLoader();
    try {
      validateUserFields(dataUser, () => {
        updateUser(dataUser)
          .then((response) => {
            switch (response.data.status) {
              case 200:
                history.push("/usuarios");
                Alert("success", t('alerts.success'), response.data.userMessage);
                break;
              default:
                Alert("error", t('alerts.oops'), response.data.userMessage);
                break;
            }
          })
          .catch((err) => {
            Alert("error", t('alerts.oops'), err.message);
          });
      });
    } catch (e) {
      Alert(e.type, e.msg, e.details);
    }
    hideLoader();
  };
  return (
    <Fragment>
      <div className="form__content">

        <div className="information">
          <div><h3>{t('administrator.users.editing')}</h3></div>
          <hr />
          <div className="form__content__formBody">
            <Text
              type="text"
              name="nombre"
              placeholder={t('administrator.users.name')}
              required
              disabled={true}
              readOnly=""
              value={dataUser.nombre}
            />
            <Text
              type="text"
              name="apellido"
              placeholder={t('administrator.users.lastname')}
              required
              readOnly=""
              disabled={true}
              value={dataUser.apellido}
            />

            <Text
              type="email"
              name="email"
              placeholder={t('administrator.users.email')}
              required
              readOnly=""
              disabled={true}
              value={dataUser.email}
            />

            <Text
              type="text"
              name="codigoSistema"
              placeholder={t('administrator.users.systemCode')}
              required={dataUser.roleId === ROL_ID_SOLICITANTE}
              readOnly=""
              maxLength={3}
              value={dataUser.codigoSistema}
              onChange={handleOnChangeCodeSystem}
            />

          </div>
          <div className="form__content__formBody">
            <Select
              onChange={handleOnChangeRole}
              className="input__20 margin__bottom"
              label={t('administrator.users.rol')}
              data={allRoles}
              name="roleId"
              value={dataUser.roleId}
              placeholder={t('transversal.selectOption')}
            />
          </div>
          <FinderSelector
            title={t('administrator.users.business')}
            placeholder={t('transversal.selectOption')}
            dataSelect={allBusiness}
            searchName={"negocios"}
            onChange={(e) => handleOnChangeBusiness(e)}
            dataButtons={dataUser.negocios}
            removeItemPill={removeBusinessSelected}
            labelSelect={t('administrator.users.business')}
            isSelect
          />

          <FinderSelector
            title={t('administrator.users.subsidiary')}
            placeholder={t('transversal.selectOption')}
            dataSelect={allFiliales}
            searchName={"filiales"}
            onChange={(e) => handleOnChangeFilial(e)}
            dataButtons={dataUser.filiales}
            removeItemPill={removeFilialSelected}
            labelSelect={t('administrator.users.subsidiary')}
            isSelect
          />
        </div>
      </div>
      <div className="form__content__formButton">
        <Button onClick={handleSubmit} primary>
        {t('administrator.users.saveUser')}
        </Button>
      </div>
      {loader}
    </Fragment>
  );
}
export default UserForm;
