import Checkbox from "../checkbox/checkbox";
import { formatValToCurrency } from "../../../utils/utils";
import { Fragment } from "react";
import "../../molecules/table/Table.scss"

const TrTable = ({
    indexRow,
    row,
    breakOn,
    handleOnClickRow,
    checkeableRows,
    headingColums,
}) => {

    const setFormatCell = (header, row) => {
        let formattedCell;
        let cell = row[header.key]
        switch (header.type) {
            case "currency":
                formattedCell = formatCurrency(header.currencyTypeFormatter, cell);
                break;
            case "text":
                formattedCell = formatText(header.renderAs, row);
                break;
            case "bool":
                formattedCell = formatCheckbox(row[header.key]);
                break;
            default:
                formattedCell = cell;
                break;
        }
        return formattedCell;
    }

    const formatCheckbox = (value) => {
        return <Checkbox onChange={() => { }} readOnly={true} checked={value} shortMargin={true} />
    }

    const formatCurrency = (currencyTypeFormatter, cell) => {
        if (currencyTypeFormatter) {
            return cell ? `$ ${formatValToCurrency(1, cell, true)}` : '$ 0';
        }
        return null;
    }

    const formatText = (renderAs, row) => {
        let formattedCell;
        if (renderAs) {
            if (renderAs.length > 1) {
                formattedCell = renderAs[0];
                const variableCount = renderAs[0].match(/\$(.*?)\%/g).length;
                for (let i = 1; i <= variableCount; i++)
                    formattedCell = formattedCell.replace(`$${i}%`, row[renderAs[i]]);
            }
        }
        return formattedCell;
    }

    const getStyleCell = (header) => {
        const style = {};
        if (header.hasOwnProperty("textTransform")) { style.textTransform = header.textTransform; }
        if (header.hasOwnProperty("align")) { style.textAlign = header.align; }
        return style;
    }

    const converterData = () => {
        let newData = [];
        headingColums.forEach(header => {
            let formattedValue = header.type ? setFormatCell(header, row) : null;
            newData.push({
                key: header.val,
                val: formattedValue ? formattedValue : row[header.key],
                style: getStyleCell(header),
            });
        });
        return newData;
    }

    const getClassRow = () => {
        let classRow = indexRow % 2 == 0 ? "" : "m-tablerow__dark ";
        switch (breakOn) {
            case 'small':
                classRow += 'm-tablerow--break-sm'
                break;
            case 'medium':
                classRow += 'm-tablerow--break-md'
                break;
            case 'large':
                classRow += 'm-tablerow--break-lg'
                break;
        }
        return classRow;
    }


    return (
        <tr onClick={(event) => handleOnClickRow(event, row)} key={indexRow} className={getClassRow()}>
            {
                converterData().map((data, indexCell) => {
                    return (
                        <td style={data.style} key={indexCell} data-heading={data.key}>
                            {indexCell === 0 && checkeableRows ?
                                <Fragment><input type="checkbox" name={`solicitud-` + data.val} />&nbsp;&nbsp;
                                </Fragment> : ""}
                            {data.val}
                        </td>
                    );
                })
            }
        </tr>
    )
}

export default TrTable;