import React from "react";
import "./textSearchNitCliente.scss";

const TextSearchNitCliente = ({
  ref,
  title,
  name,
  value,
  onKeyUp,
  onChange,
  placeholder,
  data,
  disabled,
  digitoVerificacion,
  autoComplete
}) => {
  return (
    <div className="input-text input-text--text">
      <label className="input-text__body clientnit-input">
        <input
          className="input-text__input input-text__input--image"
          type="search"
          ref={ref}
          title={title}
          id={name}
          list="lista"
          value={value}
          onKeyUp={onKeyUp}
          onChange={onChange}
          placeholder=" "
          name={name}
          required
          disabled={disabled}
          autoComplete={autoComplete}
        />
        <span className="input-text__placeholder">{placeholder}</span>
        {digitoVerificacion && <div className={disabled ? "verification-digit--disabled":"verification-digit"}>
          <span className={"verification-digit-label"}>CV</span>
          <span disabled="disabled">{digitoVerificacion}</span>
        </div>}
        <datalist id="lista">
          {!!data &&
            data?.map((item) => (
              <option
                value={item.numeroDocumento}
                key={item.numeroDocumento}
              >
                {item.numeroDocumento}
              </option>
            ))}
        </datalist>
      </label>
    </div>
  );
};
export default TextSearchNitCliente;
