import React from 'react';
import SpanGray from '../../atoms/text/spanGray';

// Atomos
import TextArea from '../../atoms/text-area/textArea'

const TextAreaBlock = ({text, textStrong, onChange, placeholder, value, maxLength, disabled}) =>
    <div className="TextAreaBlock">
        <SpanGray color={true} MostrarValor={text}/>
        <br/>
        <strong><SpanGray color={true} MostrarValor={textStrong}/></strong>
        <br/>
        <TextArea maxLength={maxLength} onChange={onChange} placeholder={placeholder} value={value} disabled={disabled} />
    </div>

export default TextAreaBlock
