import PropTypes from 'prop-types';
import TrTable from '../../atoms/tr-table/tr-table';
import ThTable from '../../atoms/th-table/th-table';
import './Table.scss';

const Table = ({
    tableData,
    headingColums,
    breakOn = 'medium',
    orderBy,
    checkeableRows,
    orderColumnName,
    orderColumnDirection,
    onItemClickListener,
}) => {

    const getClassTable = () => {
        let classTable = 'table-container__table ';
        switch (breakOn) {
            case 'small':
                classTable += 'table-container__table--break-sm'
                break;
            case 'medium':
                classTable += 'table-container__table--break-md'
                break;
            case 'large':
                classTable += 'table-container__table--break-lg'
                break;
        }
        return classTable;
    }

    const handleOnClickRow = (event, row) => {
        if (event.target.tagName !== 'INPUT' && !event.target.type) {
            onItemClickListener(row);
        } else {
            alert("check");
        }
    }
    
    const getRowsTable = () => {
        return tableData.map((row, index) => {
            row['checked'] = false;
            return (
                <TrTable
                    key={index}
                    handleOnClickRow={handleOnClickRow}
                    indexRow={index}
                    breakOn={breakOn}
                    row={row}
                    headingColums={headingColums}
                    checkeableRows={checkeableRows}
                />
            )
        })
    }

    return (
       
            <div className="table-container">
                <div className="m-tablewrapper">
                    <table className={getClassTable()}>
                        <thead>
                            <ThTable
                                headingColums={headingColums}
                                orderColumnName={orderColumnName}
                                orderColumnDirection={orderColumnDirection}
                                orderBy={orderBy}
                            />
                        </thead>
                        <tbody>
                            {getRowsTable()}
                            {tableData.length <= 0 &&
                                <tr className={"m-emptyTable"}>
                                    <td colSpan={headingColums.length}>No hay registros...</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
    )
}
Table.propTypes = {
    tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
    headingColums: PropTypes.arrayOf(PropTypes.object).isRequired,
    breakOn: PropTypes.oneOf(['small', 'medium', 'large']),
    checkeableRows: PropTypes.bool,
    orderColumnName: PropTypes.string,
    orderColumnDirection: PropTypes.bool,
    onItemClickListener: PropTypes.func,
}

export default Table;