import { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import Table from "../../molecules/table/Table";
import { listPolicyRequest } from "../../../services/policyService";
import MainSelect from "../../atoms/main-select/MainSelect";
import TablePagination from "../../molecules/table-pagination/TablePagination";
import InputSearchSet from "../../molecules/input-search-set/InputSearchSet";
import Toolkit from "../../atoms/toolkit/Toolkit";
import { Alert } from '../../molecules/alert/alert';
import useLoader from '../../../utils/useLoader';
import { AppContextUser } from '../../../provider/userInSession';
import { useTranslation } from "react-i18next";

const PolicyTable = ({ estados, estadosPropios }) => {
    let filtro, page, requests,columnSearched,orderSearched;
    const history = useHistory();

    const [t] = useTranslation('global');

    //Variable de data
    const [data, setData] = useState([]);
    const [loader, showLoader, hideLoader] = useLoader();
    //Variables para manejo de la tabla
    const headingColumns = [
        { key: 'polSolicitud', val: t('policy.pendingPolicy.noRequest'), width: 6 },
        { key: 'usuarioEmail', val: t('policy.pendingPolicy.user'), width: 12 },
        { key: 'numeroDocumento', val: t('policy.pendingPolicy.document'), width: 9, type: "text", renderAs: ["$1%-$2%", "numeroDocumento", "digitoVerificacion"] },
        { key: 'nombreCliente', val: t('policy.pendingPolicy.customer'), width: 11 },
        { key: 'fechaSolicitud', val: t('policy.pendingPolicy.applicationDate'), width: 8 },
        { key: 'fechaLimite', val: t('policy.pendingPolicy.deadline'), width: 8 },
        { key: 'estado', val: t('policy.pendingPolicy.state'), width: 8 }
    ];

    if (window.location.pathname === '/historico-de-polizas') {
        headingColumns.push({ key: 'fechaCreacion', val: t('policy.pendingPolicy.creationDate'), width: 8});
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [orderBy, setorderBy] = useState({
        nameColumn: 'polSolicitud',
        orderAsc: false
    });
    const [pageAmount, setPageAmount] = useState(1);
    const [requestsPerPage, setRequestsPerPage] = useState(20);
    const selectValues = [
        { value: 10, label: "10" },
        { value: 20, label: "20" },
        { value: 50, label: "50" }
    ];
    const [searchItem, setSearchItem] = useState("");
    const { dataUser, setDataUser } = useContext(AppContextUser);    

    //Funciones para el manejo de la paginación en la tabla
    const handleOnclick = (newPage) => {
        if (newPage >= 1 && newPage <= pageAmount) setCurrentPage(newPage);
    }
    const handleOnclickDataHeading = (nameTableColumn) => {
        let orderByVal = nameTableColumn === orderBy.nameColumn ? !orderBy.orderAsc : true;
        setorderBy({
            nameColumn: nameTableColumn,
            orderAsc: orderByVal
        });
    };
    const handleOnchange = (requestPerPage) => {
        setRequestsPerPage(requestPerPage.target.value)
    };
    const handleOnChangeSearch = (event) => {
        setSearchItem(event.target.value)
    };
    const handleOnItemClickListener = (row) => {
        const url = `?filtro=${searchItem}&page=${currentPage}&requests=${requestsPerPage}&orderby=${orderBy.nameColumn}&order=${orderBy.orderAsc}`;
        window.history.replaceState(null, null, url);
        history.push("/poliza/" + row.polSolicitud);
    };

    useEffect(() => {
        if(!window.location.search==""){
            separatePastParams(window.location.search);
        }
    },[])

    const separatePastParams = (url) => {
        const search = url.split("=");
        filtro = (search[1].split("&"))[0];
        page = (search[2].split("&"))[0];
        requests = (search[3].split("&"))[0];
        columnSearched = (search[4].split("&"))[0];
        orderSearched = search[5];
        setRequestsPerPage(requests);
        setSearchItem(filtro);
        setCurrentPage(parseInt(page));
        setorderBy({
            nameColumn: columnSearched.toString(),
            orderAsc: orderSearched === "true"
        });
    }

    useEffect(() => {
        if(estados.length){
            const dataSend = {
                usuarioId: dataUser.userId,
                lista: [{}],
                estados: estados,
                estadosPropios: estadosPropios,
                numeroPagina: (currentPage - 1),
                nombreRol: dataUser.rol,
                peticionesPorPagina: requestsPerPage,
                nombreColumna: orderBy.nameColumn,
                orden: orderBy.orderAsc,
                busqueda: searchItem,
            }

            const response = listPolicyRequest(dataSend);
            // showLoader();
            response.then(resp => {
                hideLoader();
                if (resp.status && resp.status === 200) {
                    setPageAmount(resp.data.cantidadPaginasTotales);
                    setData(resp.data.lista);
                } else {
                    setData([]);    
                    Alert("error", t('alerts.oops'), resp.userMessage);
                }
            }).catch(err => {
                // hideLoader();
                setData([]);
                Alert("error", t('alerts.oops'), err);
            });
        }
    },
        [
            currentPage,
            requestsPerPage,
            orderBy,
            searchItem,
            estados,
        ]
    );


    return (
        <>
            <Toolkit>
                <InputSearchSet
                    type="text"
                    placeholder={t('transversal.search')}
                    value={searchItem}
                    onChange={handleOnChangeSearch}
                />
            </Toolkit>
            <Table
                tableData={data}
                headingColums={headingColumns}
                title={null}
                breakOn='medium'
                orderBy={handleOnclickDataHeading}
                orderColumnName={orderBy.nameColumn}
                orderColumnDirection={orderBy.orderAsc}
                onItemClickListener={handleOnItemClickListener}
            />
            <div className="o-footer__table">
                <MainSelect
                    options={selectValues}
                    name={`invoicetable`}
                    value={requestsPerPage}
                    onChange={handleOnchange}
                />
                <TablePagination
                    paginate={(e, newPage) => handleOnclick(newPage)}
                    pageAmount={pageAmount}
                    currentPage={currentPage}
                />
            </div>
            {loader}
        </>
    );
}

export default PolicyTable;