import React from 'react';
import TextSearchEconomicActivity from '../../atoms/text/text-search-economic-activity/textSearchEconomicActivity';
import './textSearchBlockEconomicActivity.scss'

const TextSearchBlockEconomicActivity = (Props) => {
    return (
        <div className="TextSearchBlock">
            <TextSearchEconomicActivity 
                value={Props.value}
                onKeyUp={Props.onKeyUp}
                onChange={Props.onChange}
                data={Props.data}
                name={Props.name}
                placeholder={Props.placeholder}
                value={Props.value}
                disabled={Props.disabled}
                autocomplete={Props.autocomplete}
            />
        </div>
    )
}

export default TextSearchBlockEconomicActivity;