import React from "react";
import Text from "../../atoms/text/text";
import "./addClient.scss";
import { ClientContext } from "../../../provider/cliente";
import { AppContextUser } from "../../../provider/userInSession";
import { isNumberKey, isPhoneKey, validatePermissionsFilial } from "../../../utils/utils";
import {
  getStatesByCountryId,
  getCitiesByStateId,
  getAllCountries,
} from "../../../services/getLocationList";
import Select from "../../atoms/select/select";
import { useTranslation } from "react-i18next";

function AddClient() {
  const { dataClient, setDataClient } = React.useContext(ClientContext);
  const { dataUser } = React.useContext(AppContextUser);

  // States
  const [paises, setPaises] = React.useState([]);
  const [estados, setEstados] = React.useState([]);
  const [ciudades, setCiudades] = React.useState([]);

  //Traduccion
  const [t] = useTranslation("global");

  // Funciones
  function actualizarData(e) {
    setDataClient({
      type: "AGREGAR",
      payload: { ...dataClient, [e.target.name]: e.target.value },
    });
  }

  function actualizarLocation(location, e) {
    if (location == "pais") {
      if (e.target.value != "0" || e.target.value != 0) {
        const aux = paises.filter((row) => {
          return row.id == e.target.value;
        })[0];
        dataClient.telefonoRut = "+" + aux.phonecode;
        dataClient.celularRut = "+" + aux.phonecode;
        actualizarData(e);
        obtenerEstados(aux.id);
      }
    } else if (location == "estado") {
      if (e.target.value != "0" || e.target.value != 0) {
        let auxEstado = {};
        actualizarData(e);
        auxEstado = estados.filter(
          (row) => row.id === parseInt(e.target.value)
        )[0];
        obtenerCiudades(auxEstado.id);
      }
    }
  }

  function obtenerEstados(countryCode) {
    getStatesByCountryId(countryCode).then((res) => {
      setEstados(res);
    });
  }

  function obtenerCiudades(stateCode) {
    getCitiesByStateId(stateCode).then((res) => {
      setCiudades(res);
    });
  }

  React.useEffect(() => {
    const fetchData = async () => {
      getAllCountries().then(
        async (res) => {
          await setPaises(res);
          if (dataClient.paisId !== "") {
            await obtenerEstados(dataClient.paisId);
            await obtenerCiudades(dataClient.departamentoId);
          }
        },
        (error) => {
          console.log("Este es el error: ", error);
        }
      );
    };

    fetchData();
  }, [dataClient]);

  React.useEffect(() => {
    dataClient.usuarioId = dataUser.userId;
    setDataClient({
      type: "AGREGAR",
      payload: { ...dataClient },
    });
  }, [dataUser]);

  return (
    <div className="form__content">
      <div className="information">
        <div>
          <h3>{t('customers.createCustomer.dataCustomer.title')}</h3>
          <p className="information__description">{t('customers.createCustomer.dataCustomer.description')}</p>
        </div>
        <hr />
        <div className="input__text">
          <Text
            className="input__50 margin__bottom"
            onChange={actualizarData}
            value={dataClient.razonSocialRut}
            readonly=""
            placeholder={t('customers.createCustomer.dataCustomer.name')}
            type="text"
            name="razonSocialRut"
          />

          <Text
            className="input__20 margin__bottom"
            onChange={actualizarData}
            value={dataClient.numeroDocumento}
            readonly=""
            placeholder={t('customers.createCustomer.dataCustomer.fiscalIdNumber')}
            Type="number"
            name="numeroDocumento"
            onKeyDown={(e) => isNumberKey(e)}
          />
        </div>
        <div className="input__text-territory">
          <Select
            onChange={(e) => actualizarLocation("pais", e)}
            className="input__33 margin__bottom"
            label={t('customers.createCustomer.dataCustomer.country')}
            data={paises}
            name="paisId"
            value={dataClient.paisId}
            placeholder={t('transversal.selectOption')}
          />

          <Select
            onChange={(e) => actualizarLocation("estado", e)}
            className="input__33 margin__bottom"
            label={t('customers.createCustomer.dataCustomer.state')}
            data={estados}
            disabled={!dataClient.paisId}
            name="departamentoId"
            value={dataClient.departamentoId}
            placeholder={t('transversal.selectOption')}
          />
          <Select
            onChange={actualizarData}
            className="input__33 margin__bottom"
            label={t('customers.createCustomer.dataCustomer.city')}
            data={ciudades}
            disabled={!dataClient.departamentoId}
            name="ciudadId"
            value={dataClient.ciudadId}
            placeholder={t('transversal.selectOption')}
          />
        </div>
        <div className="input__text">
          <Text
            className="input__40 margin__bottom"
            onChange={actualizarData}
            value={dataClient.direccionRut}
            readonly=""
            placeholder={t('customers.createCustomer.dataCustomer.address')}
            type="text"
            name="direccionRut"
          />

          {!validatePermissionsFilial(dataUser) &&
            <Text
              className="input__20 margin__bottom"
              onChange={actualizarData}
              value={dataClient.codigoPostal}
              readonly=""
              placeholder={t('customers.createCustomer.dataCustomer.zipCode')}
              Type="number"
              name="codigoPostal"
              onKeyDown={(e) => isNumberKey(e)}
            />
          }

          <Text
            className="input__20 margin__bottom"
            onChange={actualizarData}
            value={dataClient.telefonoRut}
            readonly=""
            placeholder={t('customers.createCustomer.dataCustomer.rutPhone')}
            type="number"
            name="telefonoRut"
            onKeyDown={(e) => isPhoneKey(e)}
            maxLength={14}
          />
        </div>
        <div className="input__text">
          <Text
            className="input__30 margin__bottom"
            onChange={actualizarData}
            value={dataClient.celularRut}
            readonly=""
            placeholder={t('customers.createCustomer.dataCustomer.rutCellphone')}
            type="number"
            name="celularRut"
            onKeyDown={(e) => isPhoneKey(e)}
            maxLength={14}
          />
          <Text
            className="input__40 margin__bottom"
            onChange={actualizarData}
            value={dataClient.emailFacturacion}
            readonly=""
            placeholder={t('customers.createCustomer.dataCustomer.email')}
            Type="email"
            name="emailFacturacion"
          />
          <Text
            className="input__30 margin__bottom"
            onChange={actualizarData}
            value={dataClient.plazoPago}
            readonly=""
            placeholder={t('customers.createCustomer.dataCustomer.maxPaymentTerm')}
            Type="number"
            name="plazoPago"
            onKeyDown={(e) => isNumberKey(e)}
          />
        </div>
      </div>
    </div>
  );
}

export default AddClient;
