import {useContext, useEffect, useState} from 'react';
import MainSelect from '../../atoms/main-select/MainSelect';
import Table from '../../molecules/table/Table';
import TablePagination from '../../molecules/table-pagination/TablePagination';
import InputSearchSet from '../../molecules/input-search-set/InputSearchSet';
import {generateCsv, getCurrentSequences, getInvoiceData} from '../../../services/invoiceService';
import {AppContextUser} from '../../../provider/userInSession';
import Button from "../../atoms/buttons/button";
import Toolkit from "../../atoms/toolkit/Toolkit";
import ExportIcon from '../../../images/ico-export.svg'
import Dialog from "../../molecules/dialog/dialog";
import Text from "../../atoms/text/text";
import Checkbox from "../../atoms/checkbox/checkbox";
import TextBlockTwo from "../../molecules/text-blocks/textBlockTwo";
import Loader from "../../atoms/loader/loader";
import Message from "../../atoms/message/message";
import {avoidNonNumericValues, formatValToCurrency, orderListrequest, parseCurrencyToVal} from '../../../utils/utils';
import {INPUT_MAX_LENGTH_WITHOUT_DECIMAL_SEPARATOR} from "../../../utils/constans";
import { Alert } from '../../molecules/alert/alert';
import { useHistory } from "react-router-dom";
import useLoader from '../../../utils/useLoader';
import { useTranslation } from 'react-i18next';
import './InvoiceTable.scss';

const InvoiceTable = (props) => {
    let filtro, page, requests,columnSearched,orderSearched;
    const history = useHistory();
    const [t] = useTranslation('global');
    const [currentPage, setCurrentPage] = useState(1);
    const {dataUser, setDataUser} = useContext(AppContextUser);
    const [dataListInvoice, setDataListInvoice] = useState([]);
    const [automaticRefreshCounter, setAutomaticRefresh] = useState(0); //Control para refresco automático de la tabla
    const [loader, showLoader, hideLoader] = useLoader();
    const headingColumns = [
        {key: 'facSolicitudId', val: t('invoices.pendingInvoice.requestNumber'), width: 6},
        {key: 'usuarioEmail', val: t('invoices.pendingInvoice.user'), width: 11},
        {key: 'fechaSolicitud', val: t('invoices.pendingInvoice.requestDate'), width: 8},
        {key: 'fechaLimiteEntrega', val: t('invoices.pendingInvoice.deadline'), width: 8},
        {key: 'numeroDocumento', val: t('invoices.pendingInvoice.nit'), width: 9, type: "text", renderAs: ["$1%-$2%", "numeroDocumento", "digitoVerificacion"]},
        {key: 'nombreCliente', val: t('invoices.pendingInvoice.customer'), width: 14},
        {key: 'valorTotal', val: t('invoices.pendingInvoice.amount'), width: 10, align: "right", type: "currency", currencyTypeFormatter: "monedaId"},
        {key: 'estado', val: t('invoices.pendingInvoice.state'), width: 8, textTransform: "capitalize" },
        {key: 'consecutivo', val: t('invoices.pendingInvoice.consecutive'), width: 9},
        {key: 'filialName', val: t('invoices.pendingInvoice.subsidiary'), width: 9},
        {key: 'fechaFacturacion', val: t('invoices.pendingInvoice.billingDate'), width: 8},
    ];

    const [orderBy, setorderBy] = useState({
        nameColumn: 'fechaSolicitud',
        orderAsc: false
    });

    const [pageAmount, setPageAmount] = useState(1);
    const [requestsPerPage, setRequestsPerPage] = useState(20);
    const [searchItem, setSearchItem] = useState("");
    const [isShowingDialog, showDialog] = useState(false) // 'Export params' dialog deploy's control
    const [sequences, setSequence] = useState([]); // Sequences array for future sending to api
    const [trmValue, setTrm] = useState("0"); // Trm value for future sending to api
    // Button's text value from dialog used as visibility switcher
    const [exportButtonText, setExportButtonText] = useState(t('transversal.export'));
    const [sequenceErrors, setSequenceError] = useState([]); // Sequence errors array
    const [isGeneratingCsv, setGeneratingCsv] = useState(false); // 'Generating Csv' loading screen control
    // CSV Export message object
    const [message, setMessage] = useState({visible: false, type: "success", message: ""});

    const selectValues = [
        {value: 10, label: "10"},
        {value: 20, label: "20"},
        {value: 50, label: "50"}
    ];
    const [estados, setEstados] = useState([]);
    const [estadosPropios, setEstadosPropios] = useState([]);
    const handleOnclick = (newPage) => {
        if (newPage >= 1 && newPage <= pageAmount) setCurrentPage(newPage);
    }

    const handleOnclickDataHeading = (nameTableColumn) => {
        let orderByVal = nameTableColumn === orderBy.nameColumn ? !orderBy.orderAsc : true;
        setorderBy({
            nameColumn: nameTableColumn,
            orderAsc: orderByVal
        });
    };

    const handleOnchange = (requestPerPage) => {
        setRequestsPerPage(requestPerPage.target.value);
        setCurrentPage(1);
    };

    const handleOnChangeSearch = (event) => {
        setSearchItem(event.target.value)
    };

    /** On 'Exportar listado' pressed listener */
    const handleOnExportCsv = async () => {
        const currentSequences = await getCurrentSequences();
        setSequence(currentSequences);
        setSequenceError([]);
        setTrm("0");
        setExportButtonText(t('transversal.export'));
        setMessage({visible: false, type: "success", message: ""});

        showDialog(true);
    }

    /** On 'Cerrar' or overlay pressed listener */
    const dismissDialog = () => showDialog(false);

    /** On 'Exportar' pressed listener */
    const handleExportConfirm = () => {
        setGeneratingCsv(true);

        generateCsv({
            sedes: sequences.map(sequence => ({
                sedeId: sequence.sede_id,
                nombre: sequence.name,
                codigoZona: sequence.prefix,
                consecutivo: parseInt(sequence.sequence || 0),
                esConsecutivo: sequence.isSequence,
            })),
            trm: parseFloat(trmValue),
            usuarioId: dataUser.userId,
        }).then(response => {
            setExportButtonText(null);
            setGeneratingCsv(false);
            setMessage({
                visible: true,
                type: "success",
                message: `${t('invoices.pendingInvoice.successExportList', { amount: response.invoicesCount })} <a target='_blank' href='${response.url}'>${t('transversal.here')}</a>`,
            });

            setAutomaticRefresh(automaticRefreshCounter + 1); //Reinicia la tabla
        }).catch(e => {
            setExportButtonText(t('transversal.export'));
            setGeneratingCsv(false);
            setMessage({
                visible: true,
                type: "error",
                message: `${e.message}`,
            });
        });
    }

    /** On sequence suggestion checked listener */
    const handleSuggestedChange = (event, index) => {
        if (event.target.checked) {
            const newSequences = [...sequences];
            newSequences[index].sequence = newSequences[index].suggested_sequence;
            setSequence(newSequences);
        } else {
            const newSequences = [...sequences];
            newSequences[index].sequence = "0";
            setSequence(newSequences);
        }

        validateAllSequences();
    }

    /** On sequence changed listener */
    const handleSequenceChange = (event, index) => {
        const newSequences = [...sequences];
        newSequences[index].sequence = parseInt(event.target.value || "0");
        setSequence(newSequences);

        validateAllSequences();
    }

    /** Allowed sequences validator */
    const validateAllSequences = () => {
        let invalidSequence = false;
        const newSequenceErrors = [...sequenceErrors];

        sequences.forEach((sequence, index) => {
            if (parseInt(sequence.sequence) < parseInt(sequence.suggested_sequence)) {
                invalidSequence = true;
                newSequenceErrors[index] = t('invoices.pendingInvoice.alertAmountSuggested');
            } else {
                newSequenceErrors[index] = null;
            }
        });

        setSequenceError(newSequenceErrors)
        if (invalidSequence) setExportButtonText(null);
        else setExportButtonText(t('transversal.export'));
    }

    useEffect(() => {
            if (dataUser.rol === "Administrador") {
                if (!estados.length)
                    setEstados(['PENDIENTE', 'REVISADA', 'EXPORTADA']);
                    setEstadosPropios(['DEVUELTA','GUARDADA']);
            } else if (dataUser.rol === "Aprobador") {
                if (!estados.length){
                    setEstados(['PENDIENTE', 'REVISADA', 'EXPORTADA']);
                    setEstadosPropios(['DEVUELTA','GUARDADA']);
                }
            } else if (dataUser.rol === "Solicitante" || dataUser.rol === "Supervisor") {
                if (!estados.length)
                    setEstados(['DEVUELTA','PENDIENTE','GUARDADA']); //Validar nombre con los muchachos del back
            }

            if (estados.length > 0) {
                const dataSend = {
                    usuarioId: dataUser.userId,
                    lista: [{}],
                    estados: estados,
                    estadosPropios: estadosPropios,
                    nombreRol: dataUser.rol,
                    numeroPagina: (currentPage - 1),
                    peticionesPorPagina: requestsPerPage,
                    busqueda: searchItem,
                    nombreColumna: orderBy.nameColumn,
                    orden: orderBy.orderAsc

                }

                if (dataSend.nombreRol && estados.length > 0) {
                    const response = getInvoiceData(dataSend);
                    // showLoader();
                    response.then( (resp) => {
                        hideLoader();
                        if (resp.status && resp.status === 200) {
                            setPageAmount(resp.data.cantidadPaginasTotales);
                            let list = findFilialName(resp.data.lista);
                             setDataListInvoice(list);

                        } else {
                            Alert("error", t('alerts.oops'), resp.userMessage);
                            setDataListInvoice([]);
                        }
                    }).catch(err => {
                        // hideLoader();
                    });
                }
            }
        },
        [
            currentPage,
            requestsPerPage,
            dataUser,
            orderBy,
            searchItem,
            estados,
            automaticRefreshCounter
        ]
    );

      const findFilialName = (listInvoices) => {
            listInvoices.forEach(item => {
                let filialId = +item.filialId;
                let filial =  props.filiales.find(filial => filial.code === filialId );
                item['filialName'] = filial.name;
            })
          return listInvoices
           
      }

    const handleOnItemClickListener = (row) => {
        const url = `?filtro=${searchItem}&page=${currentPage}&requests=${requestsPerPage}&orderby=${orderBy.nameColumn}&order=${orderBy.orderAsc}`;
        window.history.replaceState(null, null, url);
        separatePastParams(url);
        history.push("/factura/" + row.facSolicitudId);
    };

    useEffect(() => {
        if(!window.location.search==""){
            separatePastParams(window.location.search);
        }
    },[])

    const separatePastParams = (url) => {
        const search = url.split("=");
        filtro = (search[1].split("&"))[0];
        page = (search[2].split("&"))[0];
        requests = (search[3].split("&"))[0];
        columnSearched = (search[4].split("&"))[0];
        orderSearched = search[5];
        setRequestsPerPage(requests);
        setSearchItem(filtro);
        setCurrentPage(parseInt(page));
        setorderBy({
            nameColumn: columnSearched.toString(),
            orderAsc: orderSearched === "true"
        });
    }

    useEffect(() => {
        if (exportButtonText) setExportButtonText(t('transversal.export'));
    }, [t])

    return (
        <>
            <Dialog visible={isShowingDialog}
                    title={t('invoices.pendingInvoice.exportList')}
                    positiveText={exportButtonText}
                    positiveListener={handleExportConfirm}
                    negativeText={t('transversal.close')}
                    negativeListener={dismissDialog}
                    overlayListener={dismissDialog}>
                <div className={"exportdialog-content"}>
                    {sequences.map((sequence, index) => (
                        <div className={"sequence-item"}>
                            <span className={`sequence-prefix`}>{`${t('invoices.pendingInvoice.consecutive')} ${sequences[index].prefix}`}</span>
                            <Text className={"sequence-value"}
                                  onChange={(e) => handleSequenceChange(e, index)}
                                  Type="number"
                                  errorMessage={sequenceErrors[index]}
                                  value={sequences[index].sequence}
                                  readOnly={false}/>
                            <Checkbox label={t('transversal.suggested')}
                                      verticalMode={true}
                                      handleChange={(e) => handleSuggestedChange(e, index)}
                                      checked={sequence.sequence.toString() === sequence.suggested_sequence.toString()}
                                      name={`cb-suggested-${sequences[index].prefix}`}/>
                        </div>
                    ))}
                    <div className={"trm-item"}>
                        <span className={"sequence-prefix"}>{t('invoices.pendingInvoice.trmPesos')}</span>
                        <TextBlockTwo
                            onChange={(e) => parseCurrencyToVal(1, e, (event, value) => setTrm(value))}
                            type='text'
                            // value={formatValToCurrency(1, trmValue)}
                            name="valor"
                            onKeyDown={(e) => avoidNonNumericValues(e)}
                            readOnly={false}
                            maxLength={INPUT_MAX_LENGTH_WITHOUT_DECIMAL_SEPARATOR}
                        />
                    </div>
                    <Loader isLoading={isGeneratingCsv}/>
                    <Message visible={message.visible} type={message.type} message={message.message}/>
                </div>
            </Dialog>

            <Toolkit>
                {!(dataUser.rol === "Solicitante" || dataUser.rol === "Supervisor") &&
                <Button borderless={true} name={t('invoices.pendingInvoice.exportList')} onClick={handleOnExportCsv} icon={ExportIcon}/>
                }
                <InputSearchSet
                    type="text"
                    placeholder={t('transversal.search')}
                    value={searchItem}
                    onChange={handleOnChangeSearch}
                />
            </Toolkit>
            <Table
                tableData={dataListInvoice}
                headingColums={headingColumns}
                title={null}
                breakOn='medium'
                orderBy={handleOnclickDataHeading}
                orderColumnName={orderBy.nameColumn}
                orderColumnDirection={orderBy.orderAsc}
                onItemClickListener={handleOnItemClickListener}
            />
            <div className="o-footer__table">
                <MainSelect
                    options={selectValues}
                    name={`invoicetable`}
                    value={requestsPerPage}
                    onChange={handleOnchange}
                />
                <TablePagination
                    paginate={(event, newPage) => handleOnclick(newPage)}
                    pageAmount={pageAmount}
                    currentPage={currentPage}
                />
            </div>
            {loader}
        </>
    );
};

export default InvoiceTable;
