import Proptypes from 'prop-types';
import AttachIcon from '../../atoms/attach-icon/attach-icon';
import { useTranslation } from "react-i18next";
import "./attach-file.scss";

function AttachFile({message, disableBlockFile}) {
    const [t] = useTranslation("global");
    return (
        <div className="attach-file">
            <span className="attach-file__message-bold">{message}</span>
            <div className="attach-file__text-attach" >
                <div className="file-drop__attach">
                   < AttachIcon classColor={disableBlockFile() ? 'attach-icon-disable' : 'attach-icon-enable'} />
                </div>

                <label  htmlFor="file-uploader" className={disableBlockFile() ? 'disable-attach-block' : undefined}>
                {t('components.inputFile.selectFile')}
                </label>
            </div>
        </div>
    );
}

AttachFile.propTypes = {
    disableBlockFile: Proptypes.func.isRequired,
    message: Proptypes.string,
}

export default AttachFile;