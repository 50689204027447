import React from 'react';
import "./pillButton.scss";

const PillButton = ({title, onClick, itemId}) => {
    return (
        <div className="main__pill__container">
            <button className="main__pill__button" disabled={true}>
                {title}
                {onClick ? <a className="boxclose" onClick={() => {onClick(itemId)}}></a> : null}    
            </button>
        </div>
    )
}

export default PillButton;
