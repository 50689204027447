import React from 'react';
import { useHistory } from 'react-router';
import './quickAccessLink.scss';

function QuickAccessLink({link, props}) {
    const history = useHistory();

    const handleClick = () => {
        history.push(link.link);
    }

    return (
        <div onClick={handleClick} className="quick-access">
            <img src={link.image} alt={`${link.text}`} />
            <span>{link.text}</span>
        </div>
    )
}

export default QuickAccessLink;