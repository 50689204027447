
import InfiniteScroll from "react-infinite-scroll-component";
import Table from "../../molecules/table/Table";
import PropTypes from 'prop-types';


const TableInfinite = ({
    tableData,
    headingColums,
    breakOn = 'medium',
    onItemClickListener,
    reloadTable,
}) => {
    return (
        <div id="scrollableDiv" style={{ height: "60vh", overflow: "auto" }}>
            <InfiniteScroll
                dataLength={tableData.length}
                next={reloadTable}
                hasMore={true}
                scrollableTarget="scrollableDiv"
            >
                <Table
                    tableData={tableData}
                    headingColums={headingColums}
                    breakOn={breakOn}
                    onItemClickListener={onItemClickListener}
                />
            </InfiniteScroll>
        </div>
    )
}

TableInfinite.propTypes = {
    tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
    headingColums: PropTypes.arrayOf(PropTypes.object).isRequired,
    breakOn: PropTypes.oneOf(['small', 'medium', 'large']),
    onItemClickListener: PropTypes.func,
    reloadTable: PropTypes.func,
}

export default TableInfinite;