import React from "react";
import Swal from "sweetalert2";

const Alert = (icon, title, text) => {
  return Swal.fire({
    icon: icon,
    title: title,
    iconColor: '#6F2FD3',
    text: text,
  });
};

const ConfirmationAlert = (
  icon,
  title,
  text,
  confirmButtonText,
  denyButtonText,
  onConfirm
) => {
  return Swal.fire({
    icon,
    title,
    iconColor: '#6F2FD3',
    text,
    showDenyButton: true,
    confirmButtonText,
    denyButtonText,
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirm();
    } else if (result.isDenied) {
      
    }
  });
};

export { Alert, ConfirmationAlert };
