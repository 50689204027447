import './textArea.scss';

const TextArea = ({ onChange, placeholder, value, maxLength = 500000,disabled, ...otherTextAreaProps }) =>{
  const handleTextAreaHeight = function (e) {
    var element = e.target;

    element.style.overflow = 'hidden';
    element.style.height = 0;
    element.style.height = element.scrollHeight + 'px';
  };

  return(
    <div className="TextArea">
        <textarea 
          onKeyUp={handleTextAreaHeight}
          onChange={onChange}
          className="objeto"
          maxLength={maxLength}
          placeholder={placeholder} 
          value={value}
          disabled={disabled}
          {...otherTextAreaProps}
        />
    </div>
  );
};

export default TextArea
