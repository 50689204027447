import Proptypes from "prop-types";
import "./button.scss";

function Button({
  type,
  onClick,
  name,
  primary,
  googleSignIn,
  children,
  icon,
  borderless,
  reverseIcon,
  noHover,
  disabled,
  ...otherButtonProps
}) {
  return (
    <button
      className={`
          button
          button-secondary
          ${primary ? "button--primary" : ""}
          ${googleSignIn ? "button--google" : ""}
          ${borderless ? "button-borderless" : ""}
          ${reverseIcon ? "button-reverseIcon" : ""}
          ${noHover ? "button-noHover" : ""}
        `}
      type={type}
      onClick={onClick}
      disabled={disabled}
      {...otherButtonProps}
    >
      {children}
      {icon != null && (
        <div className={"iconButton"}>
          <div className={"iconContainer"}>
            <img alt={name} src={icon} />
          </div>
          {name}
        </div>
      )}
      {icon == null && name}
    </button>
  );
}

Button.defaultProps = {
  type: "button",
};
Button.propTypes = {
  type: Proptypes.string,
  onClick: Proptypes.func.isRequired,
  name: Proptypes.string,
  primary: Proptypes.bool,
  googleSignIn: Proptypes.bool,
  icon: Proptypes.string,
  borderless: Proptypes.bool,
};

export default Button;
