import { useState } from "react";
import useLoader from "../../../utils/useLoader";
import HeaderInvoiceRequest from "../../organism/header-invoice-request/HeaderInvoiceRequest";
import Aside from "../../templates/aside/aside";
import ClientsTable from "../../templates/clientsTable/clientsTable";
import Wrapper from "../../templates/wrapper/Wrapper";
import { useTranslation } from "react-i18next";

function Clients() {
  const [loader] = useLoader();
  const [t] = useTranslation("global");
  const title = t('customers.customers.title');
  const [blur, setBlur] = useState(false);

  return (
    <Wrapper aside={<Aside blur={blur} setBlur={setBlur} />} blur={blur}>
      <HeaderInvoiceRequest title={title} />
      <ClientsTable />
      {loader}
    </Wrapper>
  );
}

export default Clients;
