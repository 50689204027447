import React, {Fragment, useContext, useEffect, useState} from 'react';
import MainSelect from '../../atoms/main-select/MainSelect';
import Table from '../../molecules/table/Table';
import TablePagination from '../../molecules/table-pagination/TablePagination';
import InputSearchSet from '../../molecules/input-search-set/InputSearchSet';
import {AppContextUser} from '../../../provider/userInSession';
import Toolkit from "../../atoms/toolkit/Toolkit";
import { Alert } from '../../molecules/alert/alert';
import { useHistory } from "react-router-dom";
import useLoader from '../../../utils/useLoader';
import './InvoiceTable.scss';
import {paginatedBrandService} from '../../../services/listarMarcasService';
import Button from '../../atoms/buttons/button';
import { useTranslation } from 'react-i18next';

const BrandsTable = (props) => {
    const [t] = useTranslation("global");

    const [dataBrands, setDataBrands] = useState([]); // Lista de las marcas del cliente
    let filtro, page, requests,columnSearched,orderSearched;
    const headingBrandsColumns = [
        {key: 'centroCosto', val: t('administrator.brands.costCenter'), width: 2},
        {key: 'nombre', val: t('administrator.brands.brandName'), width: 10},
        {key: 'estado', val: t('administrator.brands.active'), width: 2}
    ];
    const initialDataAuxiliar = {
        sortBy: "centroCosto",
        isSort: true,
        peticionesPorPagina: 20,
        numeroPagina: 0,
        searchItem: "",
        orden: true
    }
    const [initialData,setInitialData] = useState(initialDataAuxiliar);
    //const brandModifyButton = (data) => <Button onClick={alertModify(data)}>Modificar</Button>    

    const history = useHistory();
    const [currentPage, setCurrentPage] = useState(1);
    const {dataUser} = useContext(AppContextUser);
    const [loader, showLoader, hideLoader] = useLoader();
    const [orderBy, setorderBy] = useState({
        nameColumn: "centroCosto",
        orderAsc: true
    });
    const [totalPages, setTotalPages] = useState(1);
    const [requestsPerPage, setRequestsPerPage] = useState(20);
    const [searchItem, setSearchItem] = useState("");

    const selectValues = [
        {value: 10, label: "10"},
        {value: 20, label: "20"},
        {value: 50, label: "50"}
    ];
    const handleOnclick = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) setCurrentPage(newPage);
    }

    useEffect(() => {
        if(dataUser.rol === "Administrador"){
            showLoader();
            async function queryBrands() {
                await paginatedBrandService(initialData).then(response => {
                    setTotalPages(response.totalPages);
                    setDataBrands(response.resultList.slice());
                    hideLoader();
                }).catch(error => {
                    hideLoader();
                    setDataBrands([]);
                    Alert("error", t('alerts.oops'), error);
                })
            }
            queryBrands();
        }
        if(!window.location.search==""){
            setInitialData(separatePastParams(window.location.search));
        }
    }, []);      

    const handleOnclickDataHeading = (nameTableColumn) => {

        let orderByVal = nameTableColumn === orderBy.nameColumn ? !orderBy.orderAsc : true;

        setorderBy({
            nameColumn: nameTableColumn,
            orderAsc: orderByVal
        });
    };

    const handleOnchange = (requestPerPage) => {
        setRequestsPerPage(requestPerPage.target.value);
        setCurrentPage(1);
    };

    const handleOnChangeSearch = (event) => {
        setSearchItem(event.target.value)
    };

    const reloadTable = () => {
        const filterData = {
            usuarioId: dataUser.userId,
            lista: [{}],
            nombreRol: dataUser.rol,
            numeroPagina: (currentPage - 1),
            peticionesPorPagina: requestsPerPage,
            searchItem: searchItem,
            sortBy: orderBy.nameColumn,
            orden: orderBy.orderAsc
        };
        if (filterData.nombreRol === "Administrador") {
            const response = paginatedBrandService(filterData);
            showLoader();
            response.then(resp => {
                setTotalPages(resp.totalPages);
                setDataBrands(resp.resultList.slice());
                hideLoader();                
            }).catch(err => {
                hideLoader();
                setDataBrands([]);
                Alert("error", t('alerts.oops'), err);
            });
        }
    }

    useEffect(() => { 
        reloadTable();
    },[currentPage,requestsPerPage,dataUser,orderBy,searchItem]);
    
    const handleOnItemClickListener = (row) => {
/*         const url = `?filtro=${searchItem}&page=${currentPage}&requests=${requestsPerPage}&orderby=${orderBy.nameColumn}&order=${orderBy.orderAsc}`;
        window.history.replaceState(null, null, url); */
        history.push("/marcas/editar/" + row.brandId);
    };

    const separatePastParams = (url) => {
        const search = url.split("=");
        filtro = (search[1].split("&"))[0];
        page = (search[2].split("&"))[0];
        requests = (search[3].split("&"))[0];
        columnSearched = (search[4].split("&"))[0];
        orderSearched = search[5];
        setRequestsPerPage(requests);
        setSearchItem(filtro);
        setCurrentPage(parseInt(page));
        setorderBy({
            nameColumn: columnSearched.toString(),
            orderAsc: orderSearched
        });
        return ({
            sortBy: columnSearched.toString(),
            isSort: orderSearched,
            peticionesPorPagina: requests,
            numeroPagina: page,
            searchItem: filtro,
            orden: true
        });
    }

    useEffect(() => {
        if(!window.location.search==""){
            setInitialData(separatePastParams(window.location.search));
        }
    },[])


    const handleCreateBrand = () => {
        history.push("/marcas/crear/");
    }

    return (
        <Fragment>
            <Toolkit>
                {<Button onClick={handleCreateBrand} primary>{ t('administrator.brands.addBrand') }</Button>}
                <InputSearchSet
                    type="text"
                    placeholder={t('administrator.brands.searchBrand')}
                    value={searchItem}
                    onChange={handleOnChangeSearch}
                />
            </Toolkit>

            <Table
                tableData={dataBrands}
                headingColums={headingBrandsColumns}
                title={null}
                breakOn='medium'
                orderBy={handleOnclickDataHeading}
                orderColumnName={orderBy.nameColumn}
                orderColumnDirection={orderBy.orderAsc}
                onItemClickListener={handleOnItemClickListener}
            />
            <div className="o-footer__table">
                <MainSelect
                    options={selectValues}
                    name={`invoicetable`}
                    value={requestsPerPage}
                    onChange={handleOnchange}
                />
                <TablePagination
                    paginate={(event, newPage) => handleOnclick(newPage)}
                    pageAmount={totalPages}
                    currentPage={currentPage}
                />
            </div>
            {loader}
        </Fragment>
    );
};

export default BrandsTable;
