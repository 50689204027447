import React, { useContext } from 'react';
import './concepto.scss';
import icoDelete from '../../../images/ico-delete.svg';
import icoMinus from '../../../images/ico-minus.svg';

import Select from '../../atoms/select/select';
import TextBlockTwo from '../../molecules/text-blocks/textBlockTwo';
import Button from '../../atoms/buttons/button';
import Images from '../../atoms/images/images'
import { FacturaContext } from '../../../provider/factura';
import {
    avoidNonNumericValues,
    formatValToCurrency,
    parseCurrencyToVal,
    validateInputDecimals,
    isInvoiceRequestDisable
} from '../../../utils/utils';
import {
    INPUT_MAX_LENGTH_WITH_DECIMAL_SEPARATOR,
    INPUT_MAX_LENGTH_WITHOUT_DECIMAL_SEPARATOR,
    MAXLENGTH_BUYORDER
} from "../../../utils/constans";
import TextArea from '../../atoms/text-area/textArea';
import { useTranslation } from 'react-i18next';


const Concepto = (props) => {
    const [t] = useTranslation('global');
    const numberConcept = `${t('invoices.createInvoice.concept')} # ${props.number + 1}`;
    const [dataFactura] = useContext(FacturaContext)
    const [changeClient, setChangeClient] = props.changeClient;

    let [dataConcepto, setDataConcepto] = React.useState({
        descripcion: "",
        ordenCompra: "",
        centroCostoDTO: [{ marcaId: 0, negocioId: 0, tipoIngresoId: 0, valor: 0 }],
        costCenterTotal: 0
    });

    React.useEffect(() => {
        if (dataFactura.facSolicitudId) {
            setDataConcepto(
                state => ({
                    ...props.dataConcepto,
                    costCenterTotal: calculateTotal(props.dataConcepto.centroCostoDTO)
                })
            )
        }
    }, [props.dataConcepto])

    React.useEffect(() => {
        if (props.dataConcepto.costCenterTotal) {
            setDataConcepto(
                state => ({
                    ...props.dataConcepto,
                })
            )
        }
    }, [props.dataConcepto])

    React.useEffect(() => {
        if (changeClient) {
            dataConcepto.centroCostoDTO.map((centroCosto) => {
                centroCosto.marcaId = 0;
                centroCosto.negocioId = 0;
                centroCosto.tipoIngresoId = 0;
            })
            setChangeClient(false)
        }
    })

    const addCostCenter = () => {
        if(dataFactura.facSolicitudId && dataConcepto.facConceptoId){
           setDataConcepto(
                state => ({
                    ...state,
                    facConceptoId: state.facConceptoId,
                    facSolicitudId: state.facSolicitudId,
                    centroCostoDTO: [...state.centroCostoDTO, { marcaId: 0, negocioId: 0, tipoIngresoId: 0, valor: 0,  facConceptoId: dataConcepto.facConceptoId,}],
                })
            );
        }else{
           setDataConcepto(
                state => ({
                    ...state,
                    centroCostoDTO: [...state.centroCostoDTO, { marcaId: 0, negocioId: 0, tipoIngresoId: 0, valor: 0 }],
                })
            );
        }
    }

    const removeCostCenter = (index) => {
        let valueToDelete = dataConcepto.centroCostoDTO[index].valor;
        dataConcepto.centroCostoDTO = dataConcepto.centroCostoDTO.filter((item, i) => i !== index);
        dataConcepto.costCenterTotal = calculateTotal(dataConcepto.centroCostoDTO)
        setDataConcepto(
            state => ({
                ...state,
            })
        )
        props.updateTotalInvoiceValue(index, props.number, valueToDelete);
        props.onChangeConcept(props.number, dataConcepto);
    }

    const handleChangeCostCenter = (i, value) => {
        dataConcepto.centroCostoDTO[i].valor = validateInputDecimals(value);
        dataConcepto.costCenterTotal = calculateTotal(dataConcepto.centroCostoDTO);
        setDataConcepto(dataConcepto);
        props.onChangeConcept(props.number, dataConcepto);
    }

    const handleChange = (event, index) => {
        switch (event.target.name) {
            case 'buyOrder':
                dataConcepto.ordenCompra = event.target.value;
                break;
            case 'marca':
                dataConcepto.centroCostoDTO[index].marcaId = event.target.value;
                break;
            case 'description':
                dataConcepto.descripcion = event.target.value;
                break;
            case 'negocio':
                dataConcepto.centroCostoDTO[index].negocioId = event.target.value;
                break;
            case 'oportunidad':
                dataConcepto.centroCostoDTO[index].tipoIngresoId = parseInt(event.target.value);
                break;
            default:
                console.log("input not found");
                break;
        }
        setDataConcepto(dataConcepto);
        props.onChangeConcept(props.number, dataConcepto);
    }

    const calculateTotal = (costCenters) => {
        let total = 0;
        for (let costCenter of costCenters) {
            total += parseFloat(costCenter.valor) || 0;
        }
        return total === 0 ? total : total.toFixed(2); // Se pone esto por un bug de JS, intentar sumas 100.23 + 30 para comprobar que sucede
    }

    const isBrandDisabled = () => {
        if(dataFactura.facSolicitudId){
            return isInvoiceRequestDisable(dataFactura);
        }else{
            return props.brandDisabled;
        }
    }

    const isSelectDisabled = () => {
        if(dataFactura.facSolicitudId){
            return isInvoiceRequestDisable(dataFactura);
        }else{
            return (dataFactura.filialId == 0)
        }
    }

    const textArea = document.querySelector('textarea')
    const textRowCount = textArea ? textArea.value.split("\n").length : 0
    const rows = textRowCount + 1


    return (
        <div className="concept">
            <div hidden>SI SEÑOR, ESTAS EN AWS</div>
            <div className="concept-header">
                <h3>{numberConcept} </h3>
                {props.number > 0 && !isInvoiceRequestDisable(dataFactura) ?
                    <button className="concept__delete " type='button' onClick={props.removeConcept}>
                        <Images className="" Imagen={icoMinus} /> {t('invoices.createInvoice.deleteConcept')}
                    </button>
                    : " "
                }
            </div>
            <div className="head">
                <TextArea rows={isInvoiceRequestDisable(dataFactura) ? rows : ""}
                          onChange={(e) => handleChange(e)}
                          placeholder={t('invoices.createInvoice.description')}
                          type='text'
                          name="description"
                          className="head__descripcion"
                          value={dataFactura.facSolicitudId && props.dataConcepto.descripcion}
                          disabled={isInvoiceRequestDisable(dataFactura) ? "disabled" : ""}
                          maxLength={600}>
                </TextArea>
                <TextBlockTwo onChange={(e) => handleChange(e)}
                              placeholder={t('invoices.createInvoice.purchaseOrder')}
                              type='text'
                              name="buyOrder"
                              className="head__orde_compra"
                              value={dataFactura.facSolicitudId ? props.dataConcepto.ordenCompra : dataConcepto.ordenCompra}
                              disabled={isInvoiceRequestDisable(dataFactura) ? "disabled" : ""}
                              maxLength={MAXLENGTH_BUYORDER}/>
            </div>
            {dataConcepto.centroCostoDTO.map((centroCosto, i) =>
                <div key={i} className="cost-center">
                    <Select onChange={(e) => handleChange(e, i)}
                        name="negocio"
                        label={t('invoices.createInvoice.business')}
                        value={isInvoiceRequestDisable(dataFactura) ? props.dataConcepto.centroCostoDTO[i].negocioId : dataConcepto.centroCostoDTO[i].negocioId}
                        data={props.dataBusiness}
                        disabled={isSelectDisabled()}
                        placeholder={t('transversal.selectOption')}
                        required={isInvoiceRequestDisable(dataFactura) ? true : false}
                        messageDisabled={t('invoices.createInvoice.enterSubsidiary')}/>
                    <Select onChange={(e) => handleChange(e, i)}
                        name="marca"
                        label={t('invoices.createInvoice.brand')}
                        isBrand
                        value={isInvoiceRequestDisable(dataFactura) ? props.dataConcepto.centroCostoDTO[i].marcaId : dataConcepto.centroCostoDTO[i].marcaId}
                        data={props.dataBrands}
                        disabled={isBrandDisabled()}
                        placeholder={t('transversal.selectOption')}
                        required={isInvoiceRequestDisable(dataFactura) ? true : false}
                        messageDisabled={t('invoices.createInvoice.enterSubsidiaryCustomer')}/>
                    <Select onChange={(e) => handleChange(e, i)}
                        name="oportunidad"
                        label={t('invoices.createInvoice.typeIncome')}
                        value={isInvoiceRequestDisable(dataFactura) ? props.dataConcepto.centroCostoDTO[i].tipoIngresoId : dataConcepto.centroCostoDTO[i].tipoIngresoId}
                        data={props.dataOpportunities}
                        disabled={isSelectDisabled()}
                        placeholder={t('transversal.selectOption')}
                        required={isInvoiceRequestDisable(dataFactura) ? true : false}
                        messageDisabled={t('invoices.createInvoice.enterSubsidiary')}/>
                    <TextBlockTwo onChange={(e) => parseCurrencyToVal(1, e, (event, value) => handleChangeCostCenter(i, value))}
                        className="said"
                        placeholder={t('invoices.createInvoice.amount')}
                        type='text'
                        value={isInvoiceRequestDisable(dataFactura) ? formatValToCurrency(1, props.dataConcepto.centroCostoDTO[i].valor) : dataConcepto.centroCostoDTO[i].valor !== 0 ? formatValToCurrency(1, dataConcepto.centroCostoDTO[i].valor) : ''.parseInt}
                        name="valor"
                        onKeyDown={(e) => avoidNonNumericValues(e)}
                        disabled={isInvoiceRequestDisable(dataFactura) ? "disabled" : ""}
                        maxLength={centroCosto.valor.toString().includes('.') ? INPUT_MAX_LENGTH_WITH_DECIMAL_SEPARATOR : INPUT_MAX_LENGTH_WITHOUT_DECIMAL_SEPARATOR}
                    />
                    {dataConcepto.centroCostoDTO.length > 1 && !isInvoiceRequestDisable(dataFactura) ?
                        <button type="button" className="cost-center__delete" onClick={removeCostCenter.bind(this, i)} >
                            <Images Imagen={icoDelete} />
                        </button>
                        : " "
                    }
                </div>
            )}
            <div className="total">
                <h3>{t('invoices.createInvoice.totalConcept')}:</h3>
                <h3>{'$' + formatValToCurrency(1, dataConcepto.costCenterTotal)}</h3>
            </div>
            {isInvoiceRequestDisable(dataFactura) ? " " : <Button type="button" name={t('invoices.createInvoice.addCostCenter')} onClick={addCostCenter.bind(this)} />}
        </div>
    )
}

export default Concepto;
