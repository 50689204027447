import { useEffect, useState, useContext } from 'react';
import FinderSelector from "../../molecules/finder-selector/finderSelector";
import { getFiliales } from '../../../services/parametersService';
import Select from "../../atoms/select/select";
import { BusinessFilialService } from "../../../services/listarNegociosService"
import {getBrandsByClientSubsidiary} from "../../../services/listarMarcasService";
import "./policyRelations.scss";
import { AppContextUser } from '../../../provider/userInSession';
import { useTranslation } from 'react-i18next';

const RelationsPolicy = ({context, isEditing, changeClientState}) => {
    const [t] = useTranslation('global');
    const { dataUser } = useContext(AppContextUser);
    const [dataBusiness, setDataBusiness] = useState([]);
    const [dataPolicy, setDataPolicy] = useContext(context);
    const [dataBrand, setDataBrand] = useState([]);
    const [dataSubsidiary, setDataSubsidiary] = useState([]);
    const [dataButtonsBusiness, setDataButtonsBusiness] = useState([])
    const [dataButtonsBrand, setDataButtonsBrand] = useState([])
    const [dataButtonSubsidiary, setDataButtonSubsidiary] = useState();
    const [isDisabled, setIsDisabled] = useState(true);
    const [changeClient, setChangeClient] = changeClientState;

    useEffect(() => {
        async function queryFiliales() {
            await getFiliales().then(response => {
                setDataSubsidiary(response);
            }).catch(error => {
                setDataSubsidiary([]);
            })
        }
        queryFiliales();
    }, [])

    useEffect(() => {
        if (dataPolicy.clienteId !== "-1" && dataPolicy.filialId) {
            setIsDisabled(false);
            async function querySubsidiaryByParams() {
                await getBrandsByClientSubsidiary(dataPolicy.clienteId, dataPolicy.filialId)
                .then(response => {
                    setDataBrand(response);
                }).catch(error => {
                    setDataBrand([]);
                })
            }
            querySubsidiaryByParams();
        }
        else setIsDisabled(true);
    }, [dataPolicy.clienteId, dataPolicy.filialId]);


    useEffect( () => {
        if(!dataPolicy.polSolicitudId){
            setDataButtonsBrand([]);
            setDataButtonsBusiness([]);
            setDataPolicy({
                type: 'AGREGAR',
                payload: {
                    negocios: [],
                    marcas: []
                }
            });
            setDataButtonSubsidiary();
        }
        if(dataPolicy.filialId){
            const data = { filialId: dataPolicy.filialId };
            async function queryBusinessBySubsidiar() {
                await BusinessFilialService(data)
                .then(response => {
                    if (response.status === 200) {
                        setDataBusiness(response.datas);
                    }
                }).catch(error => {
                    setDataBusiness([]);
                })
            }
            queryBusinessBySubsidiar();
        }
    }, [dataPolicy.filialId])

    useEffect(() => {
        if (dataPolicy.filialId) {
            setDataButtonsBusiness(dataPolicy.negocios);
            setDataButtonsBrand(dataPolicy.marcas);
            setDataButtonSubsidiary(dataPolicy.filialId);
        }
    },[dataPolicy]);

    useEffect(() => {
        if (changeClient) {
            setDataButtonsBrand([]);
            actualizarArreglos("marcas",[]);
            setChangeClient(false);
        }
    })

    const isBrandsEditable = () => {
        if(dataUser.rol === "Administrador" || dataUser.rol === "Aprobador"){
            return false;
        }
        else return true;
    }

    const isPolicyEditable = () => {
        if(isEditing){
            if(dataUser.rol === "Aprobador" || dataUser.rol === "Administrador") return false;
            return true;
        }return false;
    }

    const aux = () => {
        if(isEditing){
            if(dataUser.rol === "Aprobador" || dataUser.rol === "Administrador") return false;
            return true;
        }else{
            return (dataPolicy.filialId === 0);
        }
    }

    const activeBrands = () => {
        let activeB = [];
        dataBrand.forEach(element => {
            if(element.active) activeB.push(element);
        });
        return activeB;
    }
    
    const changeSubsidiary = (event) => {
        setDataButtonSubsidiary(parseInt(event.target.value));
        actualizarArreglos("filialId",parseInt(event.target.value));
    }

    const getBusinessNameByCode = (code) => {
        for(let i =0 ; i<= dataBusiness.length;i++){
            if(dataBusiness[i].code==code){
                return dataBusiness[i].name;
            }
        }
    }

    const getBrandNameByCode = (code) => {
        for(let i =0 ; i<= dataBrand.length;i++){
            if(dataBrand[i].code==code){
                return dataBrand[i].name;
            }
        }
    }

    const handleOnChangeBusiness = (event) => {
        const businessCode = event.target.value;
        const businessName = getBusinessNameByCode(event.target.value);
        let businessDataAux = [...dataButtonsBusiness];
        if(!dataButtonsBusiness.find(item => item.itemId === event.target.value)){
            businessDataAux.push({itemId: businessCode, itemName: businessName});
            setDataButtonsBusiness(businessDataAux);
        }
        actualizarArreglos("negocios",businessDataAux);
    }

    const removeItemPillBusiness  = (itemId) => {
        var newDataButtons = dataButtonsBusiness.filter(x => {
            return x.itemId !== itemId;
        });
        setDataButtonsBusiness(newDataButtons);
        actualizarArreglos("negocios",newDataButtons);
    }

    const actualizarArreglos = (name, array) => {
        setDataPolicy({
            type: 'AGREGAR',
            payload: { ...dataPolicy, [name]: array }
        });
    }

    const handleOnChangeBrand = (event) => {
        const brandCode = event.target.value;
        const brandName = getBrandNameByCode(brandCode);
        let brandsDataAux = [...dataButtonsBrand];
        if(!dataButtonsBrand.find(item => item.itemId === event.target.value)){
            brandsDataAux.push({itemId: brandCode, itemName: brandName});
            setDataButtonsBrand(brandsDataAux);
        }
        actualizarArreglos("marcas",brandsDataAux);
    }

    const removeItemPillBrand = (itemId) => {
        var newDataButtons = dataButtonsBrand.filter(x => {
            return x.itemId !== itemId;
        });
        setDataButtonsBrand(newDataButtons);
        actualizarArreglos("marcas",newDataButtons);
    }

    const canEdit = () => {
        if(isEditing){
            if(dataUser.rol === "Administrador" || dataUser.rol === "Aprobador"){
                return true
            }else return false
        }else{
            return true
        }
    }

    return(
        <div className="form">
            <div className="HeaderBody relations">
                <div className="RelationsTitle">
                    <h3>{t('policy.requestPolicy.relations')}</h3>
                </div>
                <div className="header-fields">
                    <Select onChange={(e) => changeSubsidiary(e)}
                        name="filial"
                        label={t('policy.requestPolicy.subsidiary')}
                        value={dataButtonSubsidiary}
                        data={dataSubsidiary}
                        disabled={isPolicyEditable()}
                        placeholder={t('transversal.selectOption')}
                        required={ true }/>
                </div>
            </div>
            <hr/>
            <div className="ContentBody">
                <div className="PolicyData">
                    <FinderSelector 
                        title={t('policy.requestPolicy.business')}
                        placeholder={t('transversal.selectOption')}
                        dataSelect={dataBusiness}
                        searchName={"name"}
                        onChange={(e) => handleOnChangeBusiness(e)}
                        dataButtons={dataButtonsBusiness}
                        removeItemPill={removeItemPillBusiness}
                        labelSelect={t('policy.requestPolicy.business')}
                        isDisabled = {aux()}
                        disabledMessage={isEditing ? `${t('policy.requestPolicy.selectedBusinesses')}:` : t('policy.requestPolicy.selectSubsidiary')}
                        isSelect
                    />
                </div>
                <div className="BrandsData PolicyData">
                    <FinderSelector 
                        title={t('policy.requestPolicy.brands')} 
                        placeholder={t('transversal.selectOption')}
                        dataSelect={canEdit() ? activeBrands() : dataBrand}
                        searchName={"brand"}
                        onChange={(e) => handleOnChangeBrand(e)}
                        dataButtons={dataButtonsBrand}
                        removeItemPill={removeItemPillBrand}
                        labelSelect={t('policy.requestPolicy.brands')}
                        isDisabled={isEditing ? isBrandsEditable() : isDisabled}
                        disabledMessage={isEditing ? `${t('policy.requestPolicy.selectedBrands')}:` : t('policy.requestPolicy.selectCustomerSubsidiary')}
                        isSelect
                    />
                </div>
            </div>
        </div>
    );
}

export default RelationsPolicy;