import React from 'react';
import moment from 'moment';
import './text.scss';
import { Alert } from '../../molecules/alert/alert';

const Dates = (Props) => {
  const [selectedDate, setSelectedDate] = React.useState(Props.Values);
  React.useEffect(() => { setSelectedDate(Props.Values) }, [Props.Values]);

  const handleChange = (e) => {
  
    const date = moment(new Date()).format('YYYY-MM-DD');

    if (moment(selectedDate).isBefore(date)) {
      Alert('error', 'Fecha incorrecta', 'La fecha del límite de entrega no puede ser menor a la fecha actual');
      e.target.value = date;
    }
    Props.onChange(e)
  };

  return (
    <input className="Date" placeholder="dd/mm/aaaa" onChange={(e) => handleChange(e)} onBlur={(e) => handleChange(e)} value={selectedDate} type="Date" min={Props.Min} max={Props.Max} disabled={Props.disabled} />
  )
}
export default Dates;
