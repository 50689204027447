import moment from 'moment';
import { Auth } from "@aws-amplify/auth";
import {
    INPUT_MAX_LENGTH_WITH_DECIMAL_SEPARATOR,
    INPUT_MAX_LENGTH_WITHOUT_DECIMAL_SEPARATOR, STATUS_ID,
    FILES_CATEGORIES,
    REGEX,
    POLICY_ID,
    MONTHS_LIST
} from "./constans";

const orderListrequest = (listRequest, listOrder) => {

    let objectKeys = Object.keys(listRequest[0]);
    let listObject = [];

    if (listOrder.length === objectKeys.length) {

        for (let j = 0; j < listRequest.length; j++) {

            let itemList = {}

            for (let i = 0; i < objectKeys.length; i++) {
                itemList[listOrder[i]] = listRequest[j][listOrder[i]];
            }

            listObject.push(itemList);
        }
        return listObject;
    }
    return 0;
}

const removePropertyByKey = (object, deleteKey) => {
    return Object.keys(object)
        .filter(key => key !== deleteKey)
        .reduce((result, current) => {
            result[current] = object[current];
            return result;
        }, {});
}

const parseCurrencyToVal = (currencyId, event, callbackFunction) => {
    let value = event.target.value.toString();
    const DECIMAL_SEPARATOR = currencyId === 1 ? ',' : '.';
    const DECIMAL_REGEX = currencyId === 1 ? /[^\d.]/g : /[^\d,]/g;
    const THOUSAND_REGEX = currencyId === 1 ? /[^\d,]/g : /[^\d.]/g;

    if ((value.match(DECIMAL_REGEX) || []).length > 1) return;

    const MAX_VALUE_LENGTH = value.includes(DECIMAL_SEPARATOR) ?
        INPUT_MAX_LENGTH_WITH_DECIMAL_SEPARATOR - 1 : INPUT_MAX_LENGTH_WITHOUT_DECIMAL_SEPARATOR - 1;

    if (value.length < MAX_VALUE_LENGTH) {
        value = value.replace(THOUSAND_REGEX, '');
        if (DECIMAL_SEPARATOR === ',') value = value.replace(DECIMAL_REGEX, '.');
        if ((value.split('.')[1] || "").length <= 2) callbackFunction(event, value);
    } else {
        alert(MAX_VALUE_LENGTH === 21 ? "No puedes ingresar más dígitos!" : "No puedes ingresar más dígitos! \nIntenta borrar un número entero para agregar los decimales.")
    }
}

const formatValToCurrency = (currencyId, currentValue, provideDefaultDecimals = false) => {
    const LOCAL_CODE = currencyId === 1 ? "de-DE" : "en-US"
    const DECIMAL_SEPARATOR = currencyId === 1 ? "," : "."

    let result;

    if (currentValue !== "") {
        if (currentValue.toString().includes('.')) {
            let resultArray = currentValue.toString().split('.')
            if (provideDefaultDecimals) resultArray[1] = (resultArray[1]).padEnd(2, "0");
            result = Intl.NumberFormat(LOCAL_CODE).format(resultArray[0]) + DECIMAL_SEPARATOR + resultArray[1];
        } else {
            result = Intl.NumberFormat(LOCAL_CODE).format(currentValue);
            if (provideDefaultDecimals) result = Intl.NumberFormat(LOCAL_CODE).format(currentValue) + DECIMAL_SEPARATOR + "00";
        }
    }

    return result;
}

const validateInputDecimals = (valueToCheck) => {
    let finalValue;
    //Se debe poner estas decisiones porque estos tres casos el parseFloat los borra
    if (valueToCheck) {
        if (valueToCheck.slice(-1) === '.' || valueToCheck.slice(-2) === '.0' || valueToCheck.slice(-3) === '.00') {
            finalValue = valueToCheck;
        } else {
            finalValue = parseFloat(valueToCheck);
        }
    } else {
        finalValue = 0;
    }
    return finalValue;
}

const getAccessJwtToken = async () => {
    // Auth.currentSession() checks if token is expired and refreshes with Cognito if needed automatically
    const session = await Auth.currentSession();
    const jwt = session.getIdToken().getJwtToken();

    return jwt;
};

const getAxiosHeaders = async () => ({
    Authorization: await getAccessJwtToken(),
})

const currencyAllowedKeyCodes = [
    48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96,
    97, 98, 99, 100, 101, 102, 103, 104, 105,
    188, 190, 110, 16, 9, 36, 35, 8, 13, 46, 37,
    38, 39, 40];

const numericAllowedKeyCodes = [
    48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96,
    97, 98, 99, 100, 101, 102, 103, 104, 105,
    110, 16, 9, 36, 35, 8, 13, 46, 37,
    38, 39, 40];

const phoneAllowedKeyCodes = [
    48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96,
    97, 98, 99, 100, 101, 102, 103, 104, 105,
    110, 16, 9, 36, 35, 8, 13, 46, 37,
    38, 39, 40, 187];

const avoidNonNumericValues = (event) => {
    if (!currencyAllowedKeyCodes.includes(event.which)) event.preventDefault();
}

const isNumberKey = (event) => {
    if (!numericAllowedKeyCodes.includes(event.which)) event.preventDefault();
};

const isPhoneKey = (event) => {
    if (!phoneAllowedKeyCodes.includes(event.which)) event.preventDefault();
};

const isInvoiceRequestDisable = (data) => {
    if (data.facSolicitudId && (data.estadoId !== STATUS_ID.DEVUELTA && data.estadoId !== STATUS_ID.GUARDADA)) return true;
    if (data.polSolicitudId) return true;
    return false;
}

const validateBrandField = (brandData, callBack) => {
    if (brandData.name === "")
        throw ({ type: "error", msg: "Campo requerido", details: "Debes nombrar a la marca" });

    if (brandData.costCenter === "")
        throw ({ type: "error", msg: "Campo requerido", details: "Debes darle un centro de costo a la marca" });

    if (brandData.clients.length === 0)
        throw ({ type: "error", msg: "Campo requerido", details: "Debes asignar clientes a la marca" });

    if (brandData.filiales.length === 0)
        throw ({ type: "error", msg: "Campo requerido", details: "Debes asignar filiales a la marca" });

    callBack();
}

const validateInvoiceField = (dataFactura, callBack) => {
    const date = moment.utc(new Date()).format('YYYY-MM-DD');
    let dateLimit = moment.utc(dataFactura.fechaLimiteEntrega);

    // if (dateLimit.isBefore(date, 'day') || !dataFactura.fechaLimiteEntrega.trim())
    //     throw ({ type: "error", msg: "Fecha incorrecta", details: "La fecha del límite de entrega no puede ser menor a la fecha actual", trim: "fechaLimiteEntrega" });

    if (dataFactura.fechaLimiteEntrega === "" || !dataFactura.fechaLimiteEntrega.trim())
        throw ({ type: "error", msg: "Campo requerido", details: "El campo Fecha limite de entrega es requerido", trim: "fechaLimiteEntrega" });

    if (dataFactura.clienteId === -1)
        throw ({ type: "error", msg: "Campo requerido", details: "Debes seleccionar un cliente" });

    if (dataFactura.filialId === 0)
        throw ({ type: "error", msg: "Campo requerido", details: "Debes seleccionar una filial" });

    if (dataFactura.contacto === "" || !dataFactura.contacto.trim())
        throw ({ type: "error", msg: "Campo requerido", details: "El campo Contacto es requerido", trim: "contacto" });

    if (dataFactura.cargoContacto === "" || !dataFactura.cargoContacto.trim())
        throw ({ type: "error", msg: "Campo requerido", details: "El campo Cargo es requerido", trim: "cargoContacto" });

    if (dataFactura.plazoPago === -1)
        throw ({ type: "error", msg: "Campo requerido", details: "Las condiciones de pago son requeridas" });

    if (dataFactura.plazoPago === " ")
        throw ({ type: "error", msg: "Campo requerido", details: "Indica el valor en días del plazo de pago" });

    if (dataFactura.conceptos === [] || dataFactura.conceptos.length <= 0)
        throw ({ type: "error", msg: "Información requerida", details: "Los Conceptos son requeridos" });


    dataFactura.conceptos.forEach((concepto, i) => {

        if (concepto.descripcion === undefined || concepto.descripcion === "" || !concepto.descripcion.trim())
            throw ({ type: "error", msg: "Campo requerido", details: "La descripcion del concepto #" + (i + 1) + " Es requerida" });
        // if (concepto.ordenCompra === undefined || concepto.ordenCompra === "" || !concepto.ordenCompra.trim())
        //     throw ({ type: "error", msg: "Campo requerido", details: "La Orden de compra del concepto #" + (i + 1) + " Es requerida", trim: "ordenCompra" });

        concepto.centroCostoDTO.forEach((centroCosto, j) => {
            if (centroCosto.negocioId === 0 || !centroCosto.negocioId)
                throw ({ type: "error", msg: "Campo requerido", details: "El Negocio del Centro de costo #" + (j + 1) + " del concepto #" + (i + 1) + " Es requerida" });

            if (centroCosto.marcaId === 0 || !centroCosto.marcaId)
                throw ({ type: "error", msg: "Campo requerido", details: "La Marca del Centro de costo #" + (j + 1) + " del concepto #" + (i + 1) + " Es requerida" });

            if (centroCosto.valor === 0)
                throw ({ type: "error", msg: "Campo requerido", details: "El Valor del Centro de costo #" + (j + 1) + " del concepto #" + (i + 1) + " Es requerida" });

            if (centroCosto.tipoIngresoId === 0 || !centroCosto.tipoIngresoId)
                throw ({ type: "error", msg: "Campo requerido", details: "El Tipo de ingreso del Centro de costo #" + (j + 1) + " del concepto #" + (i + 1) + " Es requerida" });
        });
    });
    callBack();
}

const validateClientFields = (dataClient, permissionsFilial, callBack) => {
    if (dataClient.razonSocialRut === "" || !dataClient.razonSocialRut.trim())
        throw ({ type: "error", msg: "Campo requerido", details: "El campo nombre es requerido" });

    if (dataClient.numeroDocumento === "" || !dataClient.numeroDocumento.toString().trim())
        throw ({ type: "error", msg: "Campo requerido", details: "El campo nit es requerido" });

    if (!REGEX.NUMBER_REGEX.test(dataClient.numeroDocumento.toString()))
        throw ({ type: "error", msg: "Formato incorrecto", details: "El campo NIT solo debe contener números" });

    if (dataClient.paisId === "" || !dataClient.paisId.toString().trim())
        throw ({ type: "error", msg: "Campo requerido", details: "El campo pais es requerido" });

    if (dataClient.departamentoId === "" || !dataClient.departamentoId.toString().trim())
        throw ({ type: "error", msg: "Campo requerido", details: "El campo estado es requerido" });

    if (dataClient.direccionRut === "" || !dataClient.direccionRut.trim())
        throw ({ type: "error", msg: "Campo requerido", details: "El campo direccion es requerido" });

    if (dataClient.ciudadId === "" || !dataClient.ciudadId.toString().trim())
        throw ({ type: "error", msg: "Campo requerido", details: "El campo ciudad es requerido" });

    if (!permissionsFilial && (dataClient.codigoPostal === "" || !dataClient.codigoPostal.toString().trim()))
        throw ({ type: "error", msg: "Campo requerido", details: "El campo codigo postal es requerido" });

    if (!permissionsFilial && !REGEX.NUMBER_REGEX.test(dataClient.codigoPostal))
        throw ({ type: "error", msg: "Formato incorrecto", details: "El campo Código postal solo debe contener números" });

    if (dataClient.telefonoRut === "" || !dataClient.telefonoRut.toString().trim())
        throw ({ type: "error", msg: "Campo requerido", details: "El campo teléfono es requerido" });

    if (!REGEX.PHONE_REGEX.test(dataClient.telefonoRut))
        throw ({ type: "error", msg: "Formato incorrecto", details: "El campo Teléfono no tiene el formato de télefono correcto" });

    if (dataClient.celularRut === "" || !dataClient.celularRut.toString().trim())
        throw ({ type: "error", msg: "Campo requerido", details: "El campo Celular postal es requerido" });

    if (!REGEX.PHONE_REGEX.test(dataClient.celularRut))
        throw ({ type: "error", msg: "Formato incorrecto", details: "El campo Celular no tiene el formato de celular correcto" });

    if (dataClient.emailFacturacion === "" || !dataClient.emailFacturacion.trim())
        throw ({ type: "error", msg: "Campo requerido", details: "El campo email es requerido" });

    if (!REGEX.MAIL_REGEX.test(dataClient.emailFacturacion))
        throw ({ type: "error", msg: "Formato incorrecto", details: "El campo email no tiene el formato de correo" });

    if (dataClient.plazoPago === "" || !dataClient.plazoPago.toString().trim())
        throw ({ type: "error", msg: "Campo requerido", details: "El campo plazo de pago es requerido" });

    if (!REGEX.NUMBER_REGEX.test(dataClient.plazoPago.toString()))
        throw ({ type: "error", msg: "Formato incorrecto", details: "El campo Plazo pago solo debe contener números" });

    if (!dataClient.files.find((file) => file.categoria === FILES_CATEGORIES.CAMARA_COMERCIO))
        throw ({ type: "error", msg: "¡Archivo requerido!", details: "Ingrese el documento de comercio correspondiente" });

    if (!dataClient.files.find((file) => file.categoria === FILES_CATEGORIES.RUT))
        throw ({ type: "error", msg: "¡Archivo requerido!", details: "Ingrese el documento de identificación fiscal correspondiente" });

    if (dataClient.iva !== "" && (parseInt(dataClient.iva) < 0 || parseInt(dataClient.iva) > 50))
        throw ({ type: "error", msg: "Valor incorrecto", details: "El iva debe ser mayor a 0 y menor a 50" });

    if (dataClient.reteIva !== "" && (parseInt(dataClient.reteIva) < 0 || parseInt(dataClient.reteIva) > 50))
        throw ({ type: "error", msg: "Valor incorrecto", details: "El valor de rte IVA debe ser mayor a 0 y menor a 50" });

    if (dataClient.reteFuente !== "" && (parseInt(dataClient.reteFuente) < 0 || parseInt(dataClient.reteFuente) > 50))
        throw ({ type: "error", msg: "Valor incorrecto", details: "El valor de  Rte en la fuente debe ser mayor a 0 y menor a 50" });

    if (dataClient.reteIca !== "" && (parseInt(dataClient.reteIca) < 0 || parseInt(dataClient.reteIca) > 50))
        throw ({ type: "error", msg: "Valor incorrecto", details: "El valor del ICA debe ser mayor a 0 y menor a 50" });

    callBack();
};

const validatePolicyFields = (policyData, callBack) => {
    if (policyData.fechaLimiteEntrega === "" || !policyData.fechaLimiteEntrega.trim())
        throw ({ type: "error", msg: "Campo requerido", details: "El campo Fecha limite de entrega es requerido", trim: "fechaLimiteEntrega" });

    if (policyData.clienteId === -1)
        throw ({ type: "error", msg: "Campo requerido", details: "Debes seleccionar un cliente" });

    if (policyData.contacto === "" || !policyData.contacto.trim())
        throw ({ type: "error", msg: "Campo requerido", details: "El campo Contacto es requerido", trim: "contacto" });

    if (policyData.cargoContacto === "" || !policyData.cargoContacto.trim())
        throw ({ type: "error", msg: "Campo requerido", details: "El campo Cargo es requerido", trim: "cargoContacto" });

    if (!policyData.polSolicitudId && policyData.tiposPolizasSolicitud.length === 0)
        throw ({ type: "error", msg: "Campo requerido", details: "Debes seleccionar por lo menos un tipo de poliza", trim: "cargoContacto" });

    if (policyData.filialId == "")
        throw ({ type: "error", msg: "Campo requerido", details: "Debes seleccionar una filial" });

    if (policyData.marcas.length == 0)
        throw ({ type: "error", msg: "Campo requerido", details: "Debes seleccionar una marca" });

    if (policyData.negocios.length == 0)
        throw ({ type: "error", msg: "Campo requerido", details: "Debes seleccionar un negocio" });


    const otherPolicy = policyData.tiposPolizasSolicitud.find(policy => policy.tipoPolizaId === POLICY_ID.OTRA)
    if (otherPolicy && !policyData.polSolicitudId && otherPolicy.descripcionOtra === "") {
        throw ({ type: "error", msg: "Campo requerido", details: "Por favor indica el nombre de la otra opción." });
    }

    if (policyData.polSolicitudId) {
        policyData.tiposPolizasSolicitud.forEach(policyType => {
            if (policyType.files < 1) {
                throw ({ type: "error", msg: "¡Alerta!", details: "Debes agregar un archivo en cada uno de los campos." });
            }
        })
    }

    callBack();
}

const validateLocationData = (type, data, callback) => {
    switch (type) {
        case 'pais':
            if (data.sortname === "" || !data.sortname.trim())
                throw ({ type: "error", msg: "Campo requerido", details: "El campo código del país es requerido" });
            if (data.name === "" || !data.name.trim())
                throw ({ type: "error", msg: "Campo requerido", details: "El campo nombre es requerido" });
            if (data.phonecode === "" || !data.phonecode.toString().trim())
                throw ({ type: "error", msg: "Campo requerido", details: "El campo código de teléfono es requerido" });
            if (!REGEX.PHONE_REGEX.test(data.phonecode))
                throw ({ type: "error", msg: "Formato incorrecto", details: "El campo Teléfono no tiene el formato de teléfono correcto" });
            break;
        case 'estado':
            if (!data.paisId)
                throw ({ type: "error", msg: "Campo requerido", details: "Seleccione un país" });
            if (data.estado === "" || !data.estado.trim())
                throw ({ type: "error", msg: "Campo requerido", details: "El campo estado es requerido" });
            break;
        case 'ciudad':
            if (!data.paisId)
                throw ({ type: "error", msg: "Campo requerido", details: "Seleccione un país" });
            if (!data.estadoId)
                throw ({ type: "error", msg: "Campo requerido", details: "Seleccione un estado" });
            if (data.ciudad === "" || !data.ciudad.trim())
                throw ({ type: "error", msg: "Campo requerido", details: "El campo ciudad es requerido" });
            break;
        default:
            break;
    }

    callback();
}

const validateUserFields = (userData, callBack) => {
    const ROL_ID_SOLICITANTE = 4;
    if (!userData.roleId) {
        throw ({ type: "error", msg: "Campo requerido", details: "El campo rol es requerido" });
    }
    if ((ROL_ID_SOLICITANTE === userData.roleId) && !userData.codigoSistema) {
        throw ({ type: "error", msg: "Campo requerido", details: "El campo código del sistema es requerido" });
    }
    if (userData.negocios.length == 0) {
        throw ({ type: "error", msg: "Campo requerido", details: "El campo negocios es requerido" });
    }
    if (userData.filiales.length == 0) {
        throw ({ type: "error", msg: "Campo requerido", details: "El campo filiales debe contener mínimo una filial" });
    }
    callBack();
}

const toBase64 = async (file, fileCategory, callBack) => {
    if (typeof file === "object") {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            const fileData = {
                categoria: fileCategory,
                nombreArchivo: file.name,
                file: reader.result.split(",")[1],
            };
            callBack(fileData);
        };
    }
}

const getStatusName = (statusId) => {
    let newObj = {}

    for (let [k, v] of Object.entries(STATUS_ID)) {
        if (v === statusId) {
            newObj[v] = k;
        }
    }
    return Object.values(newObj)[0];
}

const formatDate = (stringDate, formatType) => {
    if (!stringDate) {
        return;
    }

    let splitDate = stringDate.split("-");
    let facDay = splitDate[2].substring(0, 2);
    let facMonth = '';
    let facYear = splitDate[0];
    switch (formatType) {
        case 'DATE':
            facMonth = splitDate[1];
            return facYear + '-' + facMonth + '-' + facDay;
        case 'TEXT_DATE':
            facMonth = MONTHS_LIST[splitDate[1].substring(1) - 1];
            return facDay + ' de ' + facMonth + ' de ' + facYear;
        case 'DD/MM/AAAA':
            facMonth = splitDate[1];
            return facDay + '/' + facMonth + '/' + facYear;
        default:
            console.error("No existe este tipo de formato");
            break;
    }
}

/**
 * 
 * @param {string[]} arrayExtention -> Array de extenciones validas
 * @param {string} extention -> Extencion a comparar con el array suministrado de extensiones validas
 * @returns {boolean} -> Retorna verdadero si la extension es valida, es decir se encuentra en el array, falso si la extension no se encuentra en el array
 */
const isExtentionsValid = (arrayExtention, extention) => {
    return arrayExtention.some(ext => ext.trim().toLowerCase() === extention.trim().toLowerCase())
}

const validatePermissionsFilial = (dataUser) => {
    if (dataUser && dataUser.filiales) {
        const CODE_FILIAL_PANAMA = '2';
        return dataUser.filiales.find(filial => filial === CODE_FILIAL_PANAMA);
    }

    return null;
}

export {
    orderListrequest,
    removePropertyByKey,
    parseCurrencyToVal,
    formatValToCurrency,
    validateInputDecimals,
    getAxiosHeaders,
    avoidNonNumericValues,
    isInvoiceRequestDisable,
    validateBrandField,
    validateInvoiceField,
    validateClientFields,
    validatePolicyFields,
    isNumberKey,
    isPhoneKey,
    toBase64,
    getStatusName,
    formatDate,
    isExtentionsValid,
    validateLocationData,
    validateUserFields,
    validatePermissionsFilial,
};
