import Proptypes from 'prop-types';
import './dialog.scss'
import Button from "../../atoms/buttons/button";

const Dialog = ({
                    title,
                    visible,
                    children,
                    positiveText,
                    positiveListener,
                    negativeText,
                    negativeListener,
                    overlayListener
                }) => {

    if (visible) {
        return (
            <div className={"dialog-overlay"} onClick={overlayListener}>
                <div className={"dialog-container"} onClick={(event) => { event.stopPropagation() }}>
                    <div className={"dialog-title"}><h3>{title}</h3></div>
                    <div className={"dialog-content"}>{children}</div>
                    <div className={"dialog-buttons"}>
                        { positiveText != null && <Button onClick={positiveListener} name={positiveText} primary={true}/> }
                        { negativeText != null && <Button borderless={true} onClick={negativeListener} name={negativeText}/> }
                    </div>
                </div>
            </div>
        );
    };
    return (<div/>);
}

Dialog.defaultProps = {}

Dialog.propTypes = {
    positiveText: Proptypes.string,
    negativeText: Proptypes.string,
    positiveListener: Proptypes.func,
    negativeListener: Proptypes.func,
    overlayListener: Proptypes.func,
    visible: Proptypes.bool,
    title: Proptypes.string
}

export default Dialog;
