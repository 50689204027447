import React from "react";
import TextSearchNombreCliente from "../../atoms/text/text-serach-nombre-cliente/textSearchNombreCliente";
import "./textSearchBlockNombreCliente.scss";
const TextSearchBlockNombreCliente = (Props) => {
  return (
    <div className="TextSearchBlock">
      <TextSearchNombreCliente
        value={Props.value}
        onKeyUp={Props.onKeyUp}
        onChange={Props.onChange}
        data={Props.data}
        name={Props.name}
        placeholder={Props.placeholder}
        value={Props.value}
        disabled={Props.disabled}
        autoComplete={Props.autoComplete}
      />
    </div>
  );
};
export default TextSearchBlockNombreCliente;
