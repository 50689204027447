import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { getAllCountriesPaginated, updateCountry } from '../../../services/getLocationList';
import useLoader from '../../../utils/useLoader';
import { validateLocationData } from '../../../utils/utils';
import Button from '../../atoms/buttons/button';
import MainSelect from '../../atoms/main-select/MainSelect';
import Text from '../../atoms/text/text';
import { Alert } from '../../molecules/alert/alert';
import InputSearchSet from '../../molecules/input-search-set/InputSearchSet';
import TablePagination from '../../molecules/table-pagination/TablePagination';
import Table from '../../molecules/table/Table';
import './manageCountries.scss';
import { useTranslation } from "react-i18next";

function ManageCountries({ props }) {
    let keyword, pageNo, pageSize, sortBy, ascSort;

    const [loader, showLoader, hideLoader] = useLoader();
    const history = useHistory();

    const [tableData, setTableData] = useState([]);

    const [searchItem, setSearchItem] = useState("");
    const [requestsPerPage, setRequestsPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [edit, setEdit] = useState(false);

    const [orderBy, setOrderBy] = useState({
        nameColumn: "nombre",
        orderAsc: true
    });

    const selectValues = [
        { value: 10, label: "10" },
        { value: 20, label: "20" },
        { value: 50, label: "50" }
    ];

    const initialData = {
        id: 0,
        name: "",
        sortname: "",
        phonecode: ""
    }

    const [t] = useTranslation("global");
    const [selected, setSelected] = useState(initialData);

    const headingColumns = [
        { key: 'sortname', val: t('administrator.manageCountries.countryCode'), width: 1 },
        { key: 'name', val: t('administrator.manageCountries.countryName'), width: 3 },
        { key: 'phonecode', val: t('administrator.manageCountries.phoneCode'), width: 3 }
    ];

    const validate = async () => {
        const dataSend = {
            id: selected.id,
            name: selected.name,
            sortname: selected.sortname,
            phonecode: selected.phonecode
        }
        try {
            validateLocationData('pais', selected, () => {
                showLoader();
                updateCountry(dataSend).then(response => {
                    hideLoader();
                    switch (response.data.status) {
                        case 200:
                            Alert("success", t('alerts.success'), response.data.userMessage);
                            setSelected(initialData);
                            setEdit(false);
                            reloadTable();
                            break;
                        default:
                            Alert("error", t('alerts.oops'), response.data.userMessage);
                            break;
                    }
                }).catch(err => {
                    hideLoader();
                    throw ({ type: "error", msg: t('alerts.oopsWrong'), details: "" });
                })
            })

        } catch (e) {
            Alert(e.type, e.msg, e.details);
            return false;
        }
    }

    const handleOnchangeSearch = (event) => {
        setSearchItem(event.target.value);
    }

    const handleOnclickDataHeading = (nameTableColumn) => {
        switch (nameTableColumn) {
            case ("sortname"):
                nameTableColumn = "codigo";
                break;
            case ("name"):
                nameTableColumn = "nombre";
                break;
            case ("phonecode"):
                nameTableColumn = "indicativo";
                break;
        }
        let orderByVal = nameTableColumn === orderBy.nameColumn ? !orderBy.orderAsc : true;
        setOrderBy({
            nameColumn: nameTableColumn,
            orderAsc: orderByVal
        });
    }

    const handleOnItemClickListener = (row) => {
        setEdit(true);
        setSelected(row)
        window.scrollTo(0, 0);
    }

    const handleOnchange = (requestPerPage) => {
        setRequestsPerPage(requestPerPage.target.value);
        setCurrentPage(1);
    }

    const handleOnclick = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) setCurrentPage(newPage);
    }

    const reloadTable = () => {
        const filterData = `?keyword=${searchItem}&pageNo=${currentPage - 1}&pageSize=${requestsPerPage}&sortBy=${orderBy.nameColumn}&ascSort=${orderBy.orderAsc}`;
        showLoader();
        getAllCountriesPaginated(filterData).then(response => {
            setTotalPages(response.totalPages);
            setTableData(response.resultList.slice());
            hideLoader();
        }).catch(error => {
            hideLoader();
            setTableData([]);
            Alert("error", t('alerts.oops'), error);
        });
    }

    const separatePastParams = (url) => {
        const search = url.split("=");
        keyword = (search[1].split("&"))[0];
        pageNo = (search[2].split("&"))[0];
        pageSize = (search[3].split("&"))[0];
        sortBy = (search[4].split("&"))[0];
        ascSort = search[5];
        setRequestsPerPage(pageSize);
        setSearchItem(keyword);
        setCurrentPage(parseInt(pageNo));
        setOrderBy({
            nameColumn: sortBy.toString(),
            orderAsc: ascSort
        });
    }

    useEffect(() => {
        showLoader();
        const dataSend = `?keyword=${searchItem}&pageNo=${currentPage - 1}&pageSize=${requestsPerPage}&sortBy=${orderBy.nameColumn}&ascSort=${orderBy.orderAsc}`;
        if (window.location.search == "") {
            getAllCountriesPaginated(dataSend).then(response => {
                setTotalPages(response.totalPages);
                setTableData(response.resultList.slice());
                hideLoader();
            }).catch(error => {
                hideLoader();
                setTableData([]);
                Alert("error", t('alerts.oops'), error);
            })
        } else {
            separatePastParams(window.location.search);
        }
    }, [])

    useEffect(() => {
        reloadTable();
    }, [currentPage, requestsPerPage, orderBy, searchItem])

    const actualizarData = (event) => {
        setSelected(
            {
                ...selected,
                [event.target.name]: event.target.name === 'sortname' ? event.target.value.toUpperCase() : event.target.value
            }
        );
    }

    const limpiar = () => {
        setEdit(false);
        setSelected(initialData);
    }

    const getCountries = () => {
        const filterData = `?keyword=${selected.name}&pageNo=1&pageSize=20&sortBy=pais&ascSort=true`;
        history.push(`/estados${filterData}`)
    }

    return (
        <Fragment>
            <InputSearchSet
                type="text"
                placeholder={t('transversal.search')}
                value={searchItem}
                onChange={handleOnchangeSearch}
            />
            <div className="form__content edit__location">
                <div className="information">
                    <div className="manage_title">
                        <h3>{`${edit ? t('transversal.edit') : t('transversal.create')} ${t('administrator.manageCountries.country')}`}</h3>
                        {edit && <Button name={t('administrator.manageCountries.seeStates')} onClick={getCountries} />}
                    </div>
                    <hr />
                    <div className="form__input">
                        <Text
                            className="input__country"
                            onChange={actualizarData}
                            value={selected.sortname}
                            placeholder={t('administrator.manageCountries.countryCode')}
                            type="text"
                            name="sortname"
                        />
                        <Text
                            className="input__country"
                            onChange={actualizarData}
                            value={selected.name}
                            placeholder={t('administrator.manageCountries.countryName')}
                            type="text"
                            name="name"
                        />
                        <Text
                            className="input__country"
                            onChange={actualizarData}
                            value={selected.phonecode}
                            placeholder={t('administrator.manageCountries.phoneCode')}
                            Type="number"
                            name="phonecode"
                        />
                    </div>
                    <div className="form__button">
                        <Button name={t('transversal.clean')} onClick={limpiar} />
                        <Button primary name={edit ? t('transversal.edit') : t('transversal.create')} onClick={validate} />
                    </div>
                </div>
            </div>
            <Table
                tableData={tableData}
                headingColums={headingColumns}
                title={null}
                breakOn='medium'
                orderBy={handleOnclickDataHeading}
                orderColumnName={orderBy.nameColumn}
                orderColumnDirection={orderBy.orderAsc}
                onItemClickListener={handleOnItemClickListener}
            />
            <div className="o-footer__table">
                <MainSelect
                    options={selectValues}
                    name={`invoicetable`}
                    value={requestsPerPage}
                    onChange={handleOnchange}
                />
                <TablePagination
                    paginate={(event, newPage) => handleOnclick(newPage)}
                    pageAmount={totalPages}
                    currentPage={currentPage}
                />
            </div>
            {loader}
        </Fragment>
    )
}

export default ManageCountries;