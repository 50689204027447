import './conceptos.scss';
import icoPlus from '../../../images/ico-plus.svg'
import React, { useEffect, useState } from 'react';
import Select from '../../atoms/select/select';
import Concepto from '../../organism/concepto/concepto';
import Images from '../../atoms/images/images';
import { ClientContext } from '../../../provider/cliente';
import { removePropertyByKey, isInvoiceRequestDisable } from '../../../utils/utils';
import { BusinessFilialService } from '../../../services/listarNegociosService'
import {brandsService, getBrandsByClientSubsidiary} from '../../../services/listarMarcasService';
import { OpportunitiesFilialService } from '../../../services/listarOportunidadesService'
import { getFiliales } from '../../../services/parametersService';
import { TAX_REGIME_IDS } from '../../../utils/constans';
import { formatValToCurrency } from '../../../utils/utils';
import { FacturaContext } from '../../../provider/factura';
import { useTranslation } from 'react-i18next';

const Conceptos = (props) => {
    const [dataFactura, setDataFactura] = React.useContext(FacturaContext); // Obtenemos la factura que está en el context de la aplicación
    const { dataClient } = React.useContext(ClientContext);
    const [ checkConcept, setCheckConcept ] = useState(false);
    const [dataBusiness, setDataBusiness] = React.useState([]); // Lista de negocios de Pragma
    const [dataBrands, setDataBrands] = React.useState([]); // Lista de las marcas del cliente
    const [dataOpportunities, setDataOpportunities] = React.useState([]); // Lista de los tipos de ingreso
    const [dataFiliales, setDataFiliales] = React.useState([]);
    const [dataConceptos, setDataConceptos] = React.useState({ data: [{ centroCostoDTO: [{ valor: 0 }] }], totalConceptos: 0 }); // Lista de los conceptos que se van a agregar a la factura
    const dataCoinType = [{ code: 1, name: 'COP' }, { code: 2, name: 'USD' }]; // Los tipos de moneda con los que factura Pragma
    const [t] = useTranslation('global');

    //Consumo del servicio de parametros para obtener la lista de negocios, marcas y tipos de ingresos
    React.useEffect(() => {

        async function queryBrands() {
            await brandsService().then(response => {
                let brandsArray = [];
                response.forEach(brand => {
                    brandsArray.push({ code: brand.code, name: brand.name });
                });
                setDataBrands(brandsArray);
            }).catch(error => {
                setDataBrands([]);
            })
        }

        async function queryFiliales() {
            await getFiliales().then(response => {
                setDataFiliales(response);
            }).catch(error => {
                setDataFiliales([]);
            })
        }

        queryFiliales();
        queryBrands();
    }, []);

    const activeBrands = () => {
        const activeB = [];
        dataBrands.forEach(element => {
            if(element.active) activeB.push(element);
        })
        return activeB;
    }

    useEffect(()=> {
        if(dataClient.clienteId && dataFactura.filialId){
            async function querySubsidiaryByParams() {
                await getBrandsByClientSubsidiary(dataClient.clienteId,dataFactura.filialId)
                .then(response => {
                    setDataBrands(response);
                }).catch(error => {
                    setDataBrands([]);
                })
            }
            querySubsidiaryByParams();
        }
    }, [dataClient.clienteId, dataFactura.filialId])

    useEffect( () => {
        if(dataFactura.filialId){
            const data = { filialId: dataFactura.filialId };
            async function queryBusinessBySubsidiar() {
                await BusinessFilialService(data)
                .then(response => {
                    if (response.status === 200) {
                        setDataBusiness(response.datas);
                    }
                }).catch(error => {
                    setDataBusiness([]);
                })
            }
            queryBusinessBySubsidiar();
        }
    }, [dataFactura.filialId])

    useEffect( () => {
        if(dataFactura.filialId){
            const data = { filialId: dataFactura.filialId };
            async function queryOpportunitiessBySubsidiar(){
                await OpportunitiesFilialService(data)
                .then(response => {
                    if (response.status === 200) {
                        setDataOpportunities(response.datas);
                    }
                }).catch(error => {
                    setDataOpportunities([]);
                })
            }
            queryOpportunitiessBySubsidiar();
        }
    }, [dataFactura.filialId])

    // Si "dataFactura.facSolicitudId" es Truthy significa que se hizo una consulta de una factura y se debe
    // visualizar toda la información que se obtuvo de la consulta. En este caso se modifica el estado de 
    // dataConceptos" para visualizar los conceptos de la factura.
    React.useEffect(() => {
        
        if (dataFactura.facSolicitudId && dataFactura.conceptos.length) {
            dataConceptos.data = dataFactura.conceptos;
            dataConceptos.totalConceptos = calculateTotalInvoiceValue(); //Se calcula el valor total de la factura.
            setDataConceptos({ ...dataConceptos });
        }

        if(props.isDuplicate && !checkConcept){
            handlerDataFactura();
            setCheckConcept(true);
        }

        if (dataFactura.clienteId === -1 && dataFactura.monedaId === 2) {
            dataFactura.monedaId = 1;
            setDataFactura({
                type: 'onChangeConcept',
                payload: { ...dataFactura }
            });
        }

    }, [dataFactura]);

    React.useEffect(() => {
        if (dataClient.clienteId) {
            if (dataClient.clasificacionTributariaId === TAX_REGIME_IDS.SIN_REGIMEN) {
                dataFactura.monedaId = 2;
                setDataFactura({
                    type: 'onChangeConcept',
                    payload: { ...dataFactura }
                });
            }
        }
    }, [dataClient]);

    const addConcept = () => {
        if(dataFactura.facSolicitudId){
            dataConceptos.data.push({centroCostoDTO: [{ valor: 0 }], facSolicitudId : dataFactura.facSolicitudId});
        }else{
            dataConceptos.data.push({centroCostoDTO: [{ valor: 0 }]});
        }
        setDataConceptos({ data: [...dataConceptos.data], totalConceptos: dataConceptos.totalConceptos });
        handlerDataFactura();
    };

    const removeConcept = (index) => {
        if (dataConceptos.data.length > 1) {
            updateTotalValueConcept();
            dataConceptos.data = dataConceptos.data.filter((item, i) => { return i !== index }); // Filtra y elimina el concepto del arreglo.
            dataConceptos.totalConceptos = calculateTotalInvoiceValue();
            setDataConceptos({ ...dataConceptos });
            handlerDataFactura();
        }
    };

    const handlerDataFactura = () => {

        let conceptoData = [...dataConceptos.data]; //Se crea una copia del estado de dataConceptos usando el operador "spread"
        // Se elimina la propiedad "costCenterTotal" de los conceptos en dataFactura ya que esta no se guarda en el backend.
        conceptoData.forEach((element, index) => {
            conceptoData[index] = removePropertyByKey(element, "costCenterTotal");
            if(props.isDuplicate){
                delete element.facSolicitudId;
                delete element.facConceptoId;
                element.centroCostoDTO.forEach(function(centroCosto){ delete centroCosto.facConceptoId; delete centroCosto.centroCostoId; });
                conceptoData[index] = element;
            }
        });
        dataFactura.conceptos = conceptoData; //Modifica el arreglo de conceptos en la factura
        setDataFactura({
            type: 'onChangeConcept',
            payload: { ...dataFactura }
        });
    };

    const changeMonedaId = (event) => {
        dataFactura.monedaId = parseInt(event.target.value);
        setDataFactura({
            type: 'onChangeConcept',
            payload: { ...dataFactura }
        });
    };

    const handleOnChangeConcept = (index, concept) => {
        dataConceptos.data[index] = concept;
        dataConceptos.totalConceptos = calculateTotalInvoiceValue();
        setDataConceptos({ ...dataConceptos });
        handlerDataFactura();
    };

    const calculateTotalInvoiceValue = () => {
        let total = 0;
        for (let concepto of dataConceptos.data) {
            if (concepto.costCenterTotal) {
                let conceptoValor = concepto.costCenterTotal;
                total += parseFloat(conceptoValor || 0);
            } else {
                //Sumatoria de los valores de los centos de costo cuando viene desde el back.
                let conceptoValor = concepto.centroCostoDTO.map(getCenterCostValues).reduce((acumulator, current) => acumulator + current);
                total += parseFloat(conceptoValor || 0);
            }
        }
        return total === 0 ? total : total.toFixed(2);
    };

    const getCenterCostValues = (centerCost) => {
        return centerCost.valor;
    };
    const updateTotalInvoiceValue = (index, number, total) => {
        dataConceptos.data[number].centroCostoDTO = dataConceptos.data[number].centroCostoDTO.filter((item, i) => { return i !== index });

        let actual = dataConceptos.totalConceptos;

        let nuevoTotal = actual - total;
        setDataConceptos({ ...dataConceptos, totalConceptos: nuevoTotal });
    };

    const updateTotalValueConcept = () => {
        dataConceptos.data.forEach(element => {
            let valor = 0;
            element.centroCostoDTO.forEach(centroCosto => {
                valor += parseFloat(centroCosto.valor);
            });
            element.costCenterTotal = valor;
        });
    };

    const changeSubsidiary = (event) => {
        dataFactura.filialId = parseInt(event.target.value);
        setDataFactura({
            type: 'onChangeConcept',
            payload: { ...dataFactura }
        });
    };

    let conceptMap = dataConceptos.data.map(function (item, i) {
        return (
            <Concepto
                key={i}
                number={i}
                said={i}
                removeConcept={removeConcept.bind(this, i)}
                onChangeConcept={handleOnChangeConcept}
                dataBusiness={dataBusiness}
                dataBrands={dataFactura.facSolicitudId ? dataBrands : activeBrands()}
                dataOpportunities={dataOpportunities}
                updateTotalInvoiceValue={updateTotalInvoiceValue}
                dataConcepto={item}
                brandDisabled = {props.brandDisable}
                className="individualConcepto"
                changeClient={props.changeClient}
            />);
    }
    );


    return (
        <div className="body">
            <div className="header">
                <div className="titleConceptos">
                    <h3>{t('invoices.createInvoice.concepts')}</h3>
                </div>
                <div className="concept-fields">
                    <Select label={t('invoices.createInvoice.typeCurrency')}
                            onChange={changeMonedaId}
                            value={dataFactura.monedaId}
                            data={dataCoinType}
                            disabled={isInvoiceRequestDisable(dataFactura) || dataClient.clasificacionTributariaId === TAX_REGIME_IDS.SIN_REGIMEN}
                            placeholder={t('transversal.selectOption')}
                            required={isInvoiceRequestDisable(dataFactura) ? true : false}/>
                </div>
                <div className="concept-fields">
                    <Select onChange={(e) => changeSubsidiary(e)}
                        name="filial"
                        label={t('invoices.createInvoice.subsidiary')}
                        value={dataFactura.filialId}
                        data={dataFiliales}
                        disabled={isInvoiceRequestDisable(dataFactura)}
                        placeholder={t('transversal.selectOption')}
                        required={isInvoiceRequestDisable(dataFactura) ? true : false}/>


                    {/* <RadioButtons data={dataSubsidiary} onChange={changeSubsidiary} selected={dataFactura.filial.toString()} name="brand" orientation="horizontal" disabled={isInvoiceRequestDisable(dataFactura)} /> */}
                </div>
            </div>
            {conceptMap}
            {!isInvoiceRequestDisable(dataFactura) ? <button className="concept__add" type='button' onClick={addConcept.bind(this)}>
                <Images className="" Imagen={icoPlus} /> {t('invoices.createInvoice.addConcept')}
            </button>
                : " "}
            <div className="total totalFinal">
                <h3>{t('invoices.createInvoice.totalInvoice')}:</h3>
                <h3>{'$' + formatValToCurrency(1, dataConceptos.totalConceptos)} </h3>
            </div>

        </div>
    );
}

export default Conceptos;
