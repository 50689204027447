import React from "react";

import "./textSearchBlockNitCliente.scss";

import TextSearchNitClient from "../../atoms/text/text-serach-nit-cliente/textSearchNitCliente";
// import buscar from '../../../images/buscar.svg';
// import Images from '../../atoms/images/images';
const TextSearchBlockNitCliente = (Props) =>{
  return(
    <div className="TextSearchBlockNitCliente">
      <TextSearchNitClient
        ref={Props.ref}
        title={Props.title}
        onKeyUp={Props.onKeyUp}
        value={Props.value}
        onChange={Props.onChange}
        data={Props.data}
        name={Props.name}
        placeholder={Props.placeholder}
        disabled={Props.disabled}
        digitoVerificacion={Props.digitoVerificacion}
        autoComplete={Props.autoComplete}
      />
    </div>
  );
};

export default TextSearchBlockNitCliente;
