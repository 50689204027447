import React from 'react';
import './text.scss';
import Help from '../../../images/ico-help.svg';

const SpanOrange = (Props) =>

    <span className='SpanOrange'>
        {Props.MostrarValor}
        
        {Props.date ? <div className="tooltip"><img src={Help}/>
           <span className="tooltiptext">Corresponde a la fecha actual. No puedes modificarla</span>
        </div> : ''}
    </span>

export default SpanOrange;